/*---
title: Presenters
section: Modules
---

List of presenting partners, as found in the site footer.

```example:html
<div class="presenters">
  <div class="presenter">
    <img src="" alt=""/>
  </div>
  <div class="presenter">
    <img src="" alt=""/>
  </div>
  <div class="presenter">
    <img src="" alt=""/>
  </div>
  <div class="presenter">
    <img src="" alt=""/>
  </div>
</div>
```

*/
.presenters {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 2rem auto 0;
  @media #{$phablet-only}{
    margin: 0 auto;
    width:61%;
  }
}

.presenter {
  width: 100%;
  max-width: 250px;

 /* @media #{$tablet-up} {
    max-width: 184px;
  }
  @media #{$laptop-up} {
    max-width: 230px;
  }
  @media #{$desktop-up} {
    max-width: 259px;
  }
*/
  max-height: 184px;
  min-height: 130px;
  margin: 2rem auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $white;
  border: 1px solid $white;

  img {
    max-height: 100%; // because IE sucks
    transition: all .8s ease-in-out;
    /*height: 8rem;*/
    width: 195px;
    margin: 0 auto;
    display: block;
    padding: 4% 6%;
  }

  img.big{
    height: 200px; // because IE sucks
  }

  img.small{
    height: 6rem;
  }

  img:hover {
     transform: scale(1.1);
    }
}

@media #{$tablet-up} {
  .presenter {
    width: calc(20% - .5rem);
  }
}
