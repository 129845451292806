/*---
title: Ribbon
section: Modules
---

A ribbon of content, generally justt a title, that spans the entire width of the page.

```example:html
<a class="ribbon --purple" href="#">
  <img class="icon" src="/assets/icons/icon_001.svg" alt="">
  <h4>Download the Crystal City Proposal</h4>
</a>
```

*/
.ribbon {
  display: block;
  text-align: center;
  padding: 1rem 0;
  background-color: $background__darkgrey;
  color: $white;

  h4 {
    margin: 0;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 4px;
  }
}

@media #{$tablet-up} {
  .ribbon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    img {
      margin: 0 1.5rem;
    }
  }
}
