/*
 * Sites Lightbox
 * The lightbox containing the individual sites (locations)
 */
.sites-lightbox {
  display: none;
  width: 100%;
  max-width: 1440px;
}

@media #{$tablet-up} {
  .sites-lightbox {
    position: absolute;
    top: 5rem;
    left: 50%;
    bottom: 5rem;
    width: 95%;
    z-index: 500;
    transition: opacity 300ms ease;
    box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.2);

    &:not(.is-open) {
      pointer-events: none;
      opacity: 0;
      transform: translateX(-200vw);
    }

    &.is-open {
      pointer-events: all;
      opacity: 1;
      transform: translateX(-50%);
    }
  }
}

@media #{$desktop-up} {
  .sites-lightbox {
    bottom: unset;
  }
}

.sites-lightbox__close-button {
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 1;
  padding: 1rem;
  display: none;

  &-bar {
    height: 3px;
    width: 32px;
    background-color: $black;
    transform-origin: center;

    &:first-of-type {
      transform: rotate(-45deg) translateX(-2px);
    }

    &:last-of-type {
      transform: rotate(45deg) translateX(-2px);
    }
  }
}

@media #{$tablet-up} {
  .sites-lightbox__close-button {
    display: block;
  }
}
