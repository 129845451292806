@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade-in {
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 5s;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
}
