*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  font-size: 16px;
  margin: 0;
  padding: 0;
  font-family: $font__main;
  color: $copy__black;
}

blockquote,
q {
  position: relative;
  font-size: $h5;
  font-family: $font__body;
  letter-spacing: 2px;
  opacity: 0.8;
  color: $copy__black;
}

cite {
  color: $black;
  position: absolute;
  bottom: -2rem;
  left: 2rem;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: -2.5rem;
    height: 2px;
    width: 2rem;
    background-color: $black;
    opacity: 0.6;
  }
}

@media #{$phablet-up} {
  cite {
    bottom: -2.5rem;
  }
}

@media #{$laptop-up} {
  cite {
    bottom: -4rem;
    left: 6.5rem;

    &:before {
      left: -6.5rem;
      width: 6rem;
    }
  }
}

img {
  height: auto; /* Make sure images are scaled correctly. */
  max-width: 100%; /* Adhere to container width. */
}

figure {
  margin: 1em 0; /* Extra wide images within figure tags don't overflow the content area. */
}

@import 'fields';
@import 'lists';
@import 'logo';
@import 'tables';
@import 'buttons';
