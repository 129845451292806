/*
 * Lightbox
 * Lightbox for holding hidden video or other content.
 */
.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgba($black, 0.8);
  z-index: 500;
  box-sizing: border-box;
  transition: opacity 300ms ease;

  &:not(.is-open) {
    opacity: 0;
    pointer-events: none;
    transform: translateX(-200vw);

    video {
      pointer-events: none;
    }
  }

  &.is-open {
    opacity: 1;
    pointer-events: all;
    transform: translateX(0);

    video {
      pointer-events: all;
    }
  }

  &__close-button {
    position: absolute;
    top: 4rem;
    right: 0rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 34px;
    min-width: 34px;
    padding: 1rem;
    z-index: 550;

    &-bar {
      background-color: $white;
      height: 3px;
      width: 34px;
      transform-origin: center;

      &:first-of-type {
        transform: rotate(45deg) translateY(2px);
      }

      &:last-of-type {
        transform: rotate(-45deg) translateY(-2px);
      }
    }
  }

  video {
    width: 90%;
    max-width: 1100px;
    height: auto;
  }
}

@media #{$phablet-up} {
  .lightbox {
    &__close-button {
      right: 1rem;
    }
  }
}

@media #{$tablet-up} {
  .lightbox {
    &__close-button {
      top: 5rem;
      right: 5%;
      /* right: calc(5% - 1rem); */
    }
  }
}
