/*---
title: Credit Section
section: Credits
---

*/

.credit-section {
  .divider {
    border-top: 2px solid $grey;
    padding:  2rem 0;

    h3 {
      margin: 1rem 0;
    }

    .supheader {
      font-weight: 600;
      color: $copy__grey;
      font-family: univia-pro,sans-serif;
      letter-spacing: 2px;
      margin-bottom: 1rem;
    }

    .suptitle{
	    @extend h5;
	  
	    @media #{$phablet-up} {
	    	/*max-width: 13rem;*/
	    }

	    @media #{$tablet-up} {
	      margin-bottom: 2.5%;
	    }
  	}

  	.title{
  		width: 100%;
  		margin-bottom: .5rem;
      margin-top: 1.5rem;

  		@media #{$phablet-up} {
  			font-size: 1.25rem;
  		}
  	}

    .credits {
    	text-align: center;
      
      	@media #{$phablet-up} {
        	display: flex;
      		flex-wrap: wrap;
       	}

      .credits__col {
        width: 100%;
        /*display: flex;*/
        @media #{$phablet-up} {
          padding: 0 1%;
        }

        .credits-wrapper {
        	@media #{$phablet-up} {
        		display: flex;
        	}
          
        	.names,
        	.titles {
         		p{
            		font-family: univia-pro,sans-serif;
            		margin: .5rem 0;
          	} 
        	}
        	
        	.names {
         		font-weight: 300;
          	width: 100%;
        	}

          ul {
            margin: 0 auto;
            padding: 0;
            
            li {
              font-family: univia-pro,sans-serif;
              display: block;
              
              @media #{$phablet-up} {
                display: inline-block;
                &:not(:first-child):not(.not):before {
                  content: '';
                  display: inline-block;
                  height: 5px;
                  width: 5px;
                  margin-bottom: 2px;
                  border-radius: 50%;
                  margin-right: 0.5rem;
                  background: $dkgrey;
                }
              }
            }
          }
        }
      }
    } 

  	.col-2{
  	 	@media #{$phablet-up} {
          width: 50%;
      }
    }

  	.col-3{
  	 	@media #{$phablet-up} {
        width: 33%;
      }
    }    
  }
}

.credit-section .divider .credits .credits__col .credits-wrapper.tier-2 .names {
  font-weight: 300;

}.credit-section .divider .credits .credits__col .credits-wrapper.tier-3 .names {
  /* smaller font size */
}