/*---
title: Talent Future Section
section: Layout
---

*/

.talent-future-section {
  margin-top: 90px;

  .header-secondary-bg {
    background: no-repeat url(./images/meeting-room-1000.jpg);
    background-size: cover;
  }

  .row {
    margin-top: 70px;
    margin-bottom: 70px;
  }
}

@media #{$tablet-up} {
  .talent-future-section {
    .header-secondary-bg {
      background: no-repeat url(./images/meeting-room-1400.jpg);
    }

    .row {
      display: flex;
    }

    .col {
      width: 50%;

      &:first-child {
        margin-right: 10%;
      }
    }
  }
}