@charset "UTF-8";
/**
 *
 * Styles
 *
 * This is the root file for all styles, so every scss partial should be
 * imported here. Import statements should be grouped functionally.
 */
*,
*:before,
*:after {
  box-sizing: border-box; }

body {
  font-size: 16px;
  margin: 0;
  padding: 0;
  font-family: "roboto-mono", monospace;
  color: #4b4b4b; }

blockquote,
q {
  position: relative;
  font-size: 1rem;
  font-family: "utopia-std", serif;
  letter-spacing: 2px;
  opacity: 0.8;
  color: #4b4b4b; }

cite {
  color: #4b4b4b;
  position: absolute;
  bottom: -2rem;
  left: 2rem; }
  cite:before {
    content: '';
    position: absolute;
    top: 50%;
    left: -2.5rem;
    height: 2px;
    width: 2rem;
    background-color: #4b4b4b;
    opacity: 0.6; }

@media (min-width: 37.5em) {
  cite {
    bottom: -2.5rem; } }

@media (min-width: 75em) {
  cite {
    bottom: -4rem;
    left: 6.5rem; }
    cite:before {
      left: -6.5rem;
      width: 6rem; } }

img {
  height: auto;
  /* Make sure images are scaled correctly. */
  max-width: 100%;
  /* Adhere to container width. */ }

figure {
  margin: 1em 0;
  /* Extra wide images within figure tags don't overflow the content area. */ }

input[type='text'],
input[type='email'],
input[type='url'],
input[type='password'],
input[type='search'],
input[type='number'],
input[type='tel'],
input[type='range'],
input[type='date'],
input[type='month'],
input[type='week'],
input[type='time'],
input[type='datetime'],
input[type='datetime-local'],
input[type='color'],
textarea {
  color: #666;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 3px; }
  input[type='text']:focus,
  input[type='email']:focus,
  input[type='url']:focus,
  input[type='password']:focus,
  input[type='search']:focus,
  input[type='number']:focus,
  input[type='tel']:focus,
  input[type='range']:focus,
  input[type='date']:focus,
  input[type='month']:focus,
  input[type='week']:focus,
  input[type='time']:focus,
  input[type='datetime']:focus,
  input[type='datetime-local']:focus,
  input[type='color']:focus,
  textarea:focus {
    color: #111; }

select {
  border: 1px solid #ccc; }

textarea {
  width: 100%; }

ul,
ol {
  margin: 0 0 1.5em 3em; }

ul {
  list-style: disc; }

ol {
  list-style: decimal; }

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1.5em; }

dt {
  font-weight: 700; }

dd {
  margin: 0 1.5em 1.5em; }

table {
  margin: 0 0 1.5em;
  width: 100%; }

.play-button {
  display: block;
  width: 3rem;
  height: 3rem;
  border-width: 2rem;
  border-style: solid;
  border-color: transparent;
  border-left-color:   #ffffff;
  border-left-width: 3rem;
  margin: 1rem 0;
  cursor: pointer; }

.call-to-action {
  display: inline-block;
  padding: 0.5rem 1.5rem;
  text-decoration: none;
  text-transform: uppercase;
  background-color: #21ab92;
  color: #ffffff;
  letter-spacing: 2px;
  font-weight: 200; }

body,
button,
input,
select,
optgroup,
textarea {
  color: #4b4b4b;
  font-family: "roboto-mono", monospace;
  line-height: 1.5; }

h1,
h2,
h3,
.landing .interstitial .caption,
h4 {
  font-weight: 700; }

h1 {
  font-size: 4.5rem; }

h2 {
  font-size: 2.8rem; }

h3, .landing .interstitial .caption {
  font-size: 2.16rem; }

h4 {
  font-size: 1.4rem;
  /* font-size: $h4 * 0.8; */ }

h5, .landing .suptitle, .credit-section .divider .suptitle {
  font-size: 1rem; }

h6 {
  font-size: 0.875rem; }

@media (min-width: 56.25em) {
  h1 {
    font-size: 5rem; }
  h2 {
    font-size: 4rem; }
  h3, .landing .interstitial .caption {
    font-size: 2.7rem; }
  h4 {
    font-size: 1.4rem; }
  h5, .landing .suptitle, .credit-section .divider .suptitle {
    font-size: 1rem; }
  h6 {
    font-size: 0.875rem; } }

/*---
title: Hilight
section: Typography
---

*/
.hilight .suptitle {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  text-transform: none;
  letter-spacing: inherit;
  font-weight: normal; }
  .hilight .suptitle:after {
    left: 0;
    width: 105%; }

p, .accordion__description .description-content .list {
  font-family: "utopia-std", serif;
  font-size: 1rem;
  margin-bottom: 1.5em;
  max-width: 100%; }

small {
  font-size: 0.875rem; }

dfn,
cite,
em,
i {
  font-style: italic; }

blockquote {
  margin: 0 1.5em; }

address {
  margin: 0 0 1.5em; }

big {
  font-size: 125%; }

strong {
  font-weight: 600; }

/*---
title: title
section: Typography
---



```example:html
  <h2 class="title">This is a heading</h1>
```

*/
.title, .landing .interstitial .caption {
  font-family: "univia-pro", sans-serif;
  font-weight: 200;
  margin: 0;
  color: white;
  line-height: 1.2; }
  .title__emphasize {
    font-family: "roboto-mono", monospace;
    font-size: 145%; }

@media (min-width: 56.25em) {
  .title__emphasize {
    font-size: 150%; } }

/*---
title: Subtitle
section: Typographpy
---



```example:html

```

*/
.subtitle {
  text-transform: uppercase;
  font-family: "roboto-mono", monospace;
  letter-spacing: 2px;
  font-weight: 200;
  color: #ffffff;
  transform: scale(1, 0.8);
  margin: 0; }

/*---
title: Suptitle
section: Typography
---



```example:html

```

*/
.suptitle {
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  font-family: "roboto-mono", monospace;
  font-weight: 900;
  z-index: 0;
  letter-spacing: 4px; }
  .suptitle:after {
    content: '';
    position: absolute;
    bottom: -0.3rem;
    left: 0.8rem;
    height: 0.8rem;
    width: 60%;
    background-color: #efefef;
    z-index: -1; }

.js-section .suptitle {
  opacity: 0;
  letter-spacing: 4px;
  transition: all 500ms ease; }
  .js-section .suptitle:after {
    opacity: 1;
    transform-origin: left;
    transition: all 300ms ease;
    transition-delay: 200ms;
    transform: scaleX(0); }

.js-section.in-view .suptitle {
  opacity: 1; }
  .js-section.in-view .suptitle:after {
    transform: scaleX(1); }

/*---
title: Site Title
section: Typography
---



```example:html

```

*/
.site-title {
  color: #21ab92;
  letter-spacing: 4px;
  font-weight: 400;
  transform-origin: center center;
  transform: scale(1, 0.8);
  margin: 0;
  z-index: 200; }

/*---
title: Description
section: Typography
---

Used for paragraphs of text

```example:html

```

*/
.description, .landing__stat-section li {
  font-family: "utopia-std", serif;
  font-size: 1.2rem;
  font-weight: 200;
  line-height: 1.8;
  margin: 1rem 0 2rem; }

.turquoise {
  color: #21ab92; }

.purple {
  color: #ac2299; }

.black {
  color: #4b4b4b; }

.white {
  color: #ffffff; }

.black {
  color: #4b4b4b; }

.grey {
  color: #ebebeb; }

.lightgrey {
  color: #f7f7f7; }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.fade-in {
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 5s;
  animation-delay: 0.2s;
  animation-fill-mode: forwards; }

.container {
  width: 90%;
  /* max-width: 1400px; */
  margin: 0 auto; }
  .container--wide {
    width: 95%;
    max-width: 1350px;
    margin: 0 auto; }

/*
 * .site-header,
 * .hero,
 */
.page-section {
  max-width: 1440px;
  margin: 0 auto; }

/*---
title: Connectivity Section
section: Layout
---



```example:html

```

*/
.connectivity-section {
  position: relative;
  padding: 6rem 0 0; }
  .connectivity-section:after {
    content: '';
    display: none;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 50%;
    height: 100%;
    background-image: url("images/dots-connectivity-top-1000.png");
    background-position: center right;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1; }
  .connectivity-section .title, .connectivity-section .landing .interstitial .caption, .landing .interstitial .connectivity-section .caption {
    margin-bottom: 2rem; }
  .connectivity-section .suptitle {
    margin-bottom: 0.3rem; }
  .connectivity-section .suptitle:after {
    width: 100%;
    left: 25%;
    height: 1rem; }
  .connectivity-section .figure-row {
    border-top: 1px solid #000000; }
  .connectivity-section .figure-row__item {
    border-bottom: 1px solid #000000;
    padding: 1.5rem 0; }
    .connectivity-section .figure-row__item span {
      font-size: 200%;
      display: block; }
  .connectivity-section .col:first-of-type {
    margin-bottom: 4rem; }
  .connectivity-section .image-figure {
    position: absolute;
    left: 0;
    bottom: -4.5rem;
    display: none;
    width: 50%;
    max-width: 700px;
    overflow: hidden; }
    .connectivity-section .image-figure img {
      transform: scale(1.2);
      transition: all 300ms ease;
      opacity: 0;
      width: 100%; }

@media (min-width: 56.25em) and (max-width: 75em) {
  .connectivity-section .figure-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch; }
    .connectivity-section .figure-row__item {
      flex-direction: column;
      align-items: center;
      width: 33%;
      border: none; }
      .connectivity-section .figure-row__item:not(:last-of-type) {
        border-right: 1px solid #4b4b4b; } }

@media (min-width: 75em) {
  .connectivity-section {
    height: 90vh;
    min-height: 800px;
    max-height: 900px; }
    .connectivity-section:after {
      display: block; }
    .connectivity-section .container {
      max-width: 1440px; }
    .connectivity-section .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
    .connectivity-section .col {
      flex: 1; }
      .connectivity-section .col:last-of-type {
        position: absolute;
        right: 0;
        width: 40%;
        margin-top: 8rem; }
    .connectivity-section .image-figure {
      display: block; }
      .connectivity-section .image-figure.in-view img {
        transform: scale(1);
        opacity: 1; } }

@media (min-width: 90em) {
  .connectivity-section:after {
    width: 100%; }
  .connectivity-section .col:first-of-type {
    max-width: 700px; }
  .connectivity-section .image-figure {
    bottom: -2rem; } }

/*---
title: Lifestyle Section
section: Layout
---



```example:html

```

*/
.lifestyle-section {
  position: relative;
  padding-top: 4rem;
  padding-bottom: 4rem; }
  .lifestyle-section:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100% + 8rem);
    width: 35%;
    background-image: url("images/dots-lifestyle-left-1000.png");
    background-position: left center;
    background-size: cover;
    background-repeat: no-repeat;
    display: none;
    z-index: -1; }
  .lifestyle-section > .image-figure {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    padding-left: 10%; }

@media (max-width: 75em) {
  .lifestyle-section .col:last-of-type {
    display: none; } }

@media (min-width: 56.25em) and (max-width: 75em) {
  .lifestyle-section {
    padding-top: 16rem; }
    .lifestyle-section .title, .lifestyle-section .landing .interstitial .caption, .landing .interstitial .lifestyle-section .caption {
      width: 65%; }
    .lifestyle-section p, .lifestyle-section .accordion__description .description-content .list, .accordion__description .description-content .lifestyle-section .list {
      width: 80%; } }

@media (min-width: 75em) {
  .lifestyle-section {
    padding: 12rem 0 8rem; }
    .lifestyle-section .container {
      max-width: 1400px; }
    .lifestyle-section:before {
      display: block; }
    .lifestyle-section .suptitle {
      margin-left: -2rem; }
    .lifestyle-section .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
    .lifestyle-section .col:first-of-type {
      width: 50%; }
    .lifestyle-section .col:last-of-type {
      width: 45%; }
    .lifestyle-section .col .image-figure {
      display: block;
      overflow: hidden; }
    .lifestyle-section > .image-figure {
      padding-left: 20%; } }

@media (min-width: 90em) {
  .lifestyle-section {
    padding: 14rem 0; } }

@media (min-width: 75em) {
  .shift-livability-image {
    position: relative;
    top: 15rem; } }

/*---
title: Lifestyle Support Section
section: Layout
---



```example:html

```

*/
.lifestyle-support-section {
  position: relative; }
  .lifestyle-support-section:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -6rem;
    width: 35%;
    height: 350px;
    background-image: url("images/dots-lifestyle-support-bottom-1000.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left bottom;
    z-index: -1;
    display: none; }
  .lifestyle-support-section .image-figure {
    display: block;
    pointer-events: none; }
    .lifestyle-support-section .image-figure img {
      width: 100%; }
    .lifestyle-support-section .image-figure figcaption {
      top: 35%;
      right: unset;
      left: 0; }
  .lifestyle-support-section .row {
    display: flex;
    flex-direction: column; }
  .lifestyle-support-section .col:not(:first-of-type) {
    padding: 2rem;
    text-align: center; }
  .lifestyle-support-section p, .lifestyle-support-section .accordion__description .description-content .list, .accordion__description .description-content .lifestyle-support-section .list {
    font-family: "univia-pro", sans-serif;
    font-weight: 200; }

@media (min-width: 37.5em) {
  .lifestyle-support-section .row {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 1rem; }
  .lifestyle-support-section .col:not(:first-of-type) {
    padding: 0;
    width: 30%; }
  .lifestyle-support-section .col:first-of-type {
    display: none; } }

@media (min-width: 56.25em) {
  .lifestyle-support-section .row {
    flex-direction: row;
    padding: 0; }
  .lifestyle-support-section .col {
    flex: 1; }
    .lifestyle-support-section .col:first-of-type {
      flex: 1.2;
      margin-top: -12rem; }
    .lifestyle-support-section .col:not(:first-of-type) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1rem 2.5rem; }
    .lifestyle-support-section .col:nth-of-type(2n), .lifestyle-support-section .col:nth-of-type(4n) {
      background-color: #efefef; }
    .lifestyle-support-section .col:nth-of-type(3n) {
      background-color: #f7f7f7; }
    .lifestyle-support-section .col p, .lifestyle-support-section .col .accordion__description .description-content .list, .accordion__description .description-content .lifestyle-support-section .col .list {
      font-size: 0.875rem; } }

@media (min-width: 75em) {
  .lifestyle-support-section {
    margin-top: 8rem; }
    .lifestyle-support-section:before {
      display: block; }
    .lifestyle-support-section .row {
      align-items: flex-end; }
    .lifestyle-support-section .col:first-of-type {
      position: relative;
      display: block;
      height: 430px; }
    .lifestyle-support-section .col:not(:first-of-type) {
      height: 430px; }
    .lifestyle-support-section .image-figure {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%; }
      .lifestyle-support-section .image-figure img {
        height: 588px; }
    .lifestyle-support-section .icon {
      width: 6rem;
      height: 6rem; } }

@media (min-width: 90em) {
  .lifestyle-support-section .col:first-of-type {
    max-width: 380px; } }

/*---
title: Page Section
section: Layout
---



```example:html

```

ToC:


*/
@media (min-width: 75em) {
  body.landing {
    font-size: 20px; } }

body.home {
  overflow-x: hidden; }

/**************
  * HERO
  **************/
.landing {
  /* HERE */
  /******************
  * UTILITIES
  ******************/
  /******************
  * COLUMNS
  ******************/
  /******************
  * FEATURE W/ ANGLES
  ******************/
  /******************
  * STAT LIST W/ TEXT
  ******************/
  /******************
  * INTERSTITIAL
  ******************/
  /******************
  * TEXT AND STAT
  ******************/
  /******************
  * STAT LIST
  ******************/
  /******************
  * QUOTE SQUARED
  ******************/
  /******************
  * CALLOUT SECTION
  ******************/
  /******************
  * FACT SECTION
  ******************/
  /******************
  * SUBSECTION HEADER
  ******************/
  /******************
  * MAGAZINE LAYOUT
  ******************/
  /******************
  * Companies
  ******************/
  /******************
  * CHARTS & GRAPHS
  ******************/
  /******************
  * HEADER INTERSTITIAL
  ******************/ }
  .landing__hero {
    position: relative;
    background-image: url("./images/talent-hero-1000.png");
    background-position: top right;
    background-size: 100%;
    background-repeat: no-repeat;
    /* HERE */ }
    @media (max-width: 56.25em) {
      .landing__hero {
        padding: 9rem 0; } }
    @media (min-width: 56.25em) {
      .landing__hero {
        height: 120vh;
        background-size: cover; } }
    @media (min-width: 75em) {
      .landing__hero {
        height: 135vh;
        background-size: cover; } }
    @media (min-width: 90em) {
      .landing__hero {
        height: 140vh; } }
    .landing__hero__content {
      /* HERE */
      /* HERE */ }
      @media (max-width: 56.25em) {
        .landing__hero__content {
          background-color: #21ab92;
          margin-top: 7rem;
          margin-bottom: -7rem;
          padding: 0 2rem 1rem;
          min-height: 13rem; } }
      @media (min-width: 56.25em) {
        .landing__hero__content {
          height: 95vh;
          padding: 5%;
          padding-bottom: 15vh;
          display: flex;
          flex-direction: column;
          justify-content: center; } }
      .landing__hero__content .title, .landing__hero__content .landing .interstitial .caption, .landing .interstitial .landing__hero__content .caption {
        color: #ffffff;
        font-size: 2.5rem;
        width: 100%; }
        @media (max-width: 56.25em) {
          .landing__hero__content .title, .landing__hero__content .landing .interstitial .caption, .landing .interstitial .landing__hero__content .caption {
            padding-top: 1rem; } }
        @media (min-width: 56.25em) {
          .landing__hero__content .title, .landing__hero__content .landing .interstitial .caption, .landing .interstitial .landing__hero__content .caption {
            width: 60vw;
            font-size: 2.7rem; } }
        @media (min-width: 75em) {
          .landing__hero__content .title, .landing__hero__content .landing .interstitial .caption, .landing .interstitial .landing__hero__content .caption {
            margin-bottom: 2vh;
            width: 40vw;
            color: #21ab92; } }
      .landing__hero__content .subtitle {
        font-family: "utopia-std", serif; }
        @media (min-width: 56.25em) {
          .landing__hero__content .subtitle {
            font-size: 1.4rem;
            text-transform: none;
            width: 55vw;
            letter-spacing: 1px;
            transform: none; } }
        @media (min-width: 75em) {
          .landing__hero__content .subtitle {
            width: 38vw; } }
  .landing .landing__hero__content:before {
    /* HERE */
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 100px 0px 0px 375px;
    border-color: transparent transparent transparent #21ab92;
    position: absolute;
    top: 157px;
    right: 0;
    width: 100%; }
    @media (min-width: 37.5em) and (max-width: 56.25em) {
      .landing .landing__hero__content:before {
        border-width: 100px 0px 0px 775px; } }
    @media (min-width: 56.25em) {
      .landing .landing__hero__content:before {
        display: none; } }
  .landing .landing__hero__content:after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 100px 0 0 375px;
    border-color: transparent transparent transparent #21ab92;
    transform: rotate(180deg);
    position: absolute;
    bottom: -67px;
    right: 0;
    width: 100%; }
    @media (min-width: 37.5em) and (max-width: 56.25em) {
      .landing .landing__hero__content:after {
        border-width: 100px 0px 0px 775px; } }
    @media (min-width: 56.25em) {
      .landing .landing__hero__content:after {
        display: none; } }
  @media (min-width: 37.5em) {
    .landing .after-hero {
      margin-top: -6vh; } }
  @media (min-width: 56.25em) {
    .landing .after-hero {
      margin-top: -25vh;
      position: relative;
      z-index: 5;
      overflow: auto; } }
  @media (min-width: 56.25em) and (min-width: 75em) {
    .landing .after-hero {
      margin-top: -35vh; } }
  @media (min-width: 56.25em) {
      .landing .after-hero h2 {
        margin-top: 10vh; } }
  .landing .interlocking-section {
    z-index: 10; }
  .landing .page-section {
    padding: 0 5%; }
    @media (min-width: 56.25em) {
      .landing .page-section {
        padding: 0; } }
  @media (min-width: 75em) {
    .landing .pull-section {
      margin-top: -6vh; } }
  @media (min-width: 90em) {
    .landing .pull-section {
      margin-top: -4vh; } }
  .landing .center-content, .landing .center-content-top {
    display: flex;
    flex-direction: column;
    justify-content: center; }
    @media (min-width: 56.25em) {
      .landing .center-content, .landing .center-content-top {
        width: 80%;
        margin: 5% auto; } }
  .landing .center-content-top {
    margin-top: 0;
    padding-top: 0; }
  @media (min-width: 56.25em) {
    .landing .centered {
      text-align: center; } }
  .landing .stacked-images figure {
    margin: 0 0 5%; }
  .landing .stacked-images figure:last-child {
    margin-bottom: 0; }
  .landing .wide-content__left {
    margin: 5%; }
    @media (min-width: 56.25em) {
      .landing .wide-content__left {
        width: 60vw; } }
  @media (min-width: 56.25em) {
    .landing .spacer {
      margin-bottom: 5%; } }
  .landing .mobile-spacer {
    padding: 5% 5% 0 5%; }
    @media (min-width: 56.25em) {
      .landing .mobile-spacer {
        padding: 0; } }
  .landing img {
    margin: 0;
    width: 100%;
    height: auto; }
  .landing .suptitle {
    max-width: 70vw; }
    @media (min-width: 56.25em) {
      .landing .suptitle {
        margin-bottom: 5%;
        max-width: 24vw; } }
    @media (min-width: 75em) {
      .landing .suptitle {
        max-width: 22vw; } }
  @media (min-width: 56.25em) {
    .landing .wide-text {
      max-width: 70%; } }
  .landing p, .landing .accordion__description .description-content .list, .accordion__description .description-content .landing .list {
    margin-bottom: 1rem; }
  .landing p, .landing .accordion__description .description-content .list, .accordion__description .description-content .landing .list, .landing li {
    font-family: "utopia-std", serif; }
    @media (min-width: 56.25em) {
      .landing p, .landing .accordion__description .description-content .list, .accordion__description .description-content .landing .list, .landing li {
        font-size: 1.2rem;
        line-height: 1.8; } }
  @media (min-width: 56.25em) {
    .landing li {
      line-height: 1.5; } }
  .landing ul {
    margin: .5rem 0; }
    .landing ul li {
      margin-bottom: .5rem; }
  @media (min-width: 56.25em) {
    .landing .row {
      display: flex; } }
  @media (min-width: 56.25em) {
    .landing .row .col-10 {
      width: 8%;
      margin: 0 auto; } }
  @media (min-width: 56.25em) {
    .landing .row .col-20 {
      width: 18%;
      margin: 0 auto; } }
  @media (min-width: 56.25em) {
    .landing .row .col-30 {
      width: 28%;
      margin: 0 auto; } }
  @media (min-width: 56.25em) {
    .landing .row .col-40 {
      width: 38%;
      margin: 0 auto; } }
  @media (min-width: 56.25em) {
    .landing .row .col-50 {
      width: 48%;
      margin: 0 auto; } }
  @media (min-width: 56.25em) {
    .landing .row .col-60 {
      width: 58%;
      margin: 0 auto; } }
  @media (min-width: 56.25em) {
    .landing .row .col-80 {
      width: 68%;
      margin: 0 auto; } }
  @media (min-width: 56.25em) {
    .landing .row .col-80 {
      width: 78%;
      margin: 0 auto; } }
  @media (min-width: 56.25em) {
    .landing .row .col-90 {
      width: 88%;
      margin: 0 auto; } }
  .landing .row.\--center-col {
    justify-content: center; }
  @media (min-width: 56.25em) {
    .landing .full-width-left > div:last-child {
      margin-right: 0;
      padding-right: 0; } }
  @media (min-width: 56.25em) {
    .landing .full-width-right > div:first-child {
      margin-left: 0;
      padding-left: 0; } }
  .landing .aligner {
    display: flex;
    align-items: center;
    justify-content: center; }
  .landing__feature {
    display: flex;
    flex-direction: column;
    justify-content: center; }
    @media (min-width: 56.25em) {
      .landing__feature {
        min-height: 115vh; } }
    .landing__feature__content {
      padding: 10% 5% 10% 5%; }
      @media (min-width: 56.25em) {
        .landing__feature__content {
          width: 45%; } }
      @media (min-width: 90em) {
        .landing__feature__content {
          padding: 0% 5% 10% 10%; } }
      .landing__feature__content .circle-graph {
        margin: 5% auto;
        background: none; }
        @media (min-width: 56.25em) {
          .landing__feature__content .circle-graph {
            margin-left: 2rem; } }
    @media (min-width: 56.25em) {
      .landing__feature:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;
        height: 100%;
        background-image: url("./images/talent-labor-1000.png");
        background-position: center left;
        background-size: cover;
        background-repeat: no-repeat;
        z-index: -1; } }
  .landing .data-point,
  .landing .data-description {
    font-family: "univia-pro", sans-serif;
    font-weight: 100;
    font-size: .9rem; }
  .landing .data-point {
    font-size: 3rem; }
  .landing .animate-graph {
    stroke-dasharray: 630px;
    stroke-dashoffset: 630px;
    transform-origin: 100px 100px;
    transform: rotate(-90deg); }
  .landing__stat-section {
    list-style-type: none;
    margin: 5% 0;
    padding: 0; }
    @media (min-width: 56.25em) {
      .landing__stat-section {
        margin-bottom: 0; } }
    .landing__stat-section li {
      line-height: 1.3;
      margin: 0 0 1rem; }
      @media (min-width: 56.25em) {
        .landing__stat-section li {
          line-height: 1.3;
          max-width: 76%;
          text-align: left;
          margin: 0 0 2vh 0; } }
    .landing__stat-section__label {
      font-size: 2rem;
      font-family: "univia-pro", sans-serif;
      line-height: 1.4;
      display: block; }
  .landing .interstitial {
    position: relative; }
    @media (min-width: 75em) {
      .landing .interstitial {
        height: 90vh;
        overflow: hidden; } }
    @media (min-width: 75em) {
      .landing .interstitial.special {
        height: auto; } }
    .landing .interstitial img {
      width: 100%;
      height: auto;
      display: block; }
    .landing .interstitial .caption {
      position: absolute;
      width: 50vw;
      bottom: 3vh;
      right: 8vw; }
      @media (min-width: 75em) {
        .landing .interstitial .caption {
          bottom: 30vh; } }
      @media (max-width: 75em) {
        .landing .interstitial .caption {
          font-size: 4vw; } }
      @media (min-width: 75em) {
        .landing .interstitial .caption {
          width: 40vw;
          bottom: 20vh;
          right: 18vw; } }
  .landing__text-stat {
    margin: 5% 0 0 5%; }
    .landing__text-stat h4 {
      margin-bottom: 0;
      line-height: 1; }
    .landing__text-stat p, .landing__text-stat .accordion__description .description-content .list, .accordion__description .description-content .landing__text-stat .list {
      margin: 1vh 0 8vh; }
      @media (min-width: 56.25em) {
        .landing__text-stat p, .landing__text-stat .accordion__description .description-content .list, .accordion__description .description-content .landing__text-stat .list {
          margin: 5% 0 12vh; } }
  .landing__stat-list {
    font-family: "univia-pro", sans-serif; }
    .landing__stat-list__header {
      border-bottom: 2px solid #21ab92;
      display: flex; }
      .landing__stat-list__header h3, .landing__stat-list__header .landing .interstitial .caption, .landing .interstitial .landing__stat-list__header .caption {
        font-size: 1.2rem;
        font-weight: 100;
        align-self: flex-end; }
      .landing__stat-list__header .icon {
        margin-left: 0;
        height: 4rem;
        width: 6rem; }
      .landing__stat-list__header h3, .landing__stat-list__header .landing .interstitial .caption, .landing .interstitial .landing__stat-list__header .caption,
      .landing__stat-list__header .icon {
        margin-bottom: .5rem; }
    .landing__stat-list__list {
      list-style-type: none;
      margin: 1% 5% 5%;
      padding: 0; }
      .landing__stat-list__list li {
        font-family: "univia-pro", sans-serif;
        font-size: 1rem;
        font-weight: 100; }
        @media (min-width: 75em) {
          .landing__stat-list__list li {
            font-size: 1.5rem; } }
      .landing__stat-list__list__stat {
        font-size: 2rem; }
        @media (min-width: 75em) {
          .landing__stat-list__list__stat {
            font-size: 3rem; } }
    .landing__stat-list__left {
      position: relative; }
      @media (min-width: 56.25em) {
        .landing__stat-list__left {
          width: 45vw;
          position: relative;
          top: -5vh; } }
      .landing__stat-list__left h3, .landing__stat-list__left .landing .interstitial .caption, .landing .interstitial .landing__stat-list__left .caption {
        width: 100%;
        text-align: right;
        margin-bottom: 0;
        position: relative;
        bottom: -.3rem;
        font-family: "roboto-mono", monospace; }
      .landing__stat-list__left ul {
        margin-left: 6vw; }
      .landing__stat-list__left li {
        line-height: 1.5; }
  .landing__quote-squared {
    position: relative;
    z-index: 1; }
    .landing__quote-squared p, .landing__quote-squared .accordion__description .description-content .list, .accordion__description .description-content .landing__quote-squared .list {
      margin: 3% auto 5%;
      font-family: "univia-pro", sans-serif;
      font-weight: 100;
      text-align: center;
      font-size: 1.2rem;
      max-width: 95%;
      position: relative;
      background: #ffffff;
      padding: 3% 5%; }
      @media (min-width: 56.25em) {
        .landing__quote-squared p, .landing__quote-squared .accordion__description .description-content .list, .accordion__description .description-content .landing__quote-squared .list {
          font-size: 2rem;
          max-width: 75%; } }
      .landing__quote-squared p:before, .landing__quote-squared .accordion__description .description-content .list:before, .accordion__description .description-content .landing__quote-squared .list:before {
        content: '';
        position: absolute;
        top: -3px;
        left: -3px;
        width: 25px;
        height: 25px;
        z-index: -1;
        background: #fcac11; }
        @media (min-width: 56.25em) {
          .landing__quote-squared p:before, .landing__quote-squared .accordion__description .description-content .list:before, .accordion__description .description-content .landing__quote-squared .list:before {
            width: 50px;
            height: 50px; } }
      .landing__quote-squared p:after, .landing__quote-squared .accordion__description .description-content .list:after, .accordion__description .description-content .landing__quote-squared .list:after {
        content: '';
        position: absolute;
        bottom: -3px;
        right: -3px;
        width: 25px;
        height: 25px;
        background: #fcac11;
        z-index: -1; }
        @media (min-width: 56.25em) {
          .landing__quote-squared p:after, .landing__quote-squared .accordion__description .description-content .list:after, .accordion__description .description-content .landing__quote-squared .list:after {
            width: 50px;
            height: 50px; } }
  @media (min-width: 56.25em) {
    .landing .quote-wide p, .landing .quote-wide .accordion__description .description-content .list, .accordion__description .description-content .landing .quote-wide .list {
      max-width: 90%;
      font-size: 1.5rem; } }
  .landing__callout {
    background: #f6f6f6;
    padding: 5%;
    min-height: 70vh; }
  .landing__fact-section {
    overflow: hidden; }
    @media (max-width: 56.25em) {
      .landing__fact-section {
        padding-bottom: 5%; } }
    @media (min-width: 56.25em) {
      .landing__fact-section {
        margin: 0 0 2rem; } }
    .landing__fact-section p, .landing__fact-section .accordion__description .description-content .list, .accordion__description .description-content .landing__fact-section .list {
      font-family: "univia-pro", sans-serif;
      font-weight: 100;
      position: relative;
      z-index: 10;
      font-size: 1.3rem;
      width: 65%;
      left: 25%;
      line-height: 1.2; }
      @media (min-width: 56.25em) {
        .landing__fact-section p, .landing__fact-section .accordion__description .description-content .list, .accordion__description .description-content .landing__fact-section .list {
          font-size: 2.3rem;
          line-height: 1.35;
          width: 45%;
          left: 35%; } }
      .landing__fact-section p:before, .landing__fact-section .accordion__description .description-content .list:before, .accordion__description .description-content .landing__fact-section .list:before {
        content: '';
        position: absolute;
        top: 18px;
        right: -25%;
        width: 125%;
        height: 3px;
        background: #fcac11;
        z-index: -1; }
        @media (min-width: 56.25em) {
          .landing__fact-section p:before, .landing__fact-section .accordion__description .description-content .list:before, .accordion__description .description-content .landing__fact-section .list:before {
            top: 38px;
            right: -45%;
            width: 145%;
            height: 5px; } }
  .landing__subsection__header {
    margin-bottom: 5%;
    overflow: hidden; }
    @media (min-width: 56.25em) {
      .landing__subsection__header {
        margin-bottom: 16vh; } }
  .landing__magazine-layout {
    overflow: hidden;
    margin: 5%; }
    @media (min-width: 56.25em) {
      .landing__magazine-layout {
        margin: 5% 0; } }
    .landing__magazine-layout__header {
      line-height: 1;
      margin: 0 0 5% 0; }
      .landing__magazine-layout__header__kicker {
        position: relative;
        z-index: 20;
        font-weight: 100;
        font-size: 4rem;
        display: block; }
        .landing__magazine-layout__header__kicker:after {
          content: '';
          position: absolute;
          top: 55px;
          left: -29%;
          width: 130%;
          height: 4px;
          background: #fcac11;
          z-index: -1; }
    .landing__magazine-layout__header,
    .landing__magazine-layout p,
    .landing__magazine-layout .accordion__description .description-content .list, .accordion__description .description-content
    .landing__magazine-layout .list {
      font-family: "roboto-mono", monospace; }
    .landing__magazine-layout p, .landing__magazine-layout .accordion__description .description-content .list, .accordion__description .description-content .landing__magazine-layout .list {
      font-size: 1rem;
      font-weight: 100; }
  .landing__companies__header {
    display: flex;
    border-bottom: 4px solid #21ab92; }
    @media (min-width: 37.5em) {
      .landing__companies__header {
        width: 28rem; } }
    .landing__companies__header__company-num {
      display: block;
      font-size: 6rem;
      line-height: 1;
      margin-right: .25rem;
      position: relative;
      top: 5px; }
    .landing__companies__header__text {
      display: block;
      font-size: 1.5rem;
      align-self: flex-end;
      position: relative;
      top: 3px; }
  .landing__graphs .graph {
    margin: auto;
    display: block; }
    @media (max-width: 37.5em) {
      .landing__graphs .graph {
        margin-top: 5%; } }
    .landing__graphs .graph img {
      padding-top: 5%; }
      @media (max-width: 75em) {
        .landing__graphs .graph img {
          padding-top: 1.5rem; } }
      @media (max-width: 37.438em) {
        .landing__graphs .graph img {
          padding-top: 4.5rem; } }
    .landing__graphs .graph figcaption {
      background: transparent;
      padding: 0 0 0 12px;
      border-left: 6px solid #21ab92;
      width: 65%;
      left: 0;
      font-size: .7rem;
      letter-spacing: normal;
      text-transform: none;
      font-weight: 100; }
      @media (min-width: 37.5em) {
        .landing__graphs .graph figcaption {
          font-size: .9rem; } }
      @media (min-width: 75em) {
        .landing__graphs .graph figcaption {
          left: 0;
          font-size: 1.1rem; } }
  .landing .image-title-callout {
    overflow: hidden; }
  .landing .header-interstitial {
    display: flex;
    margin-bottom: 5%; }
    .landing .header-interstitial .header-primary,
    .landing .header-interstitial .header-secondary {
      height: 10rem; }
      @media (min-width: 56.25em) {
        .landing .header-interstitial .header-primary,
        .landing .header-interstitial .header-secondary {
          height: 15.5rem; } }
    .landing .header-interstitial .header-primary {
      width: 40%;
      fill: #ac2299;
      background: #ac2299;
      position: relative;
      z-index: 20;
      top: 2.5vh;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      @media (min-width: 56.25em) {
        .landing .header-interstitial .header-primary {
          width: 20%; } }
      .landing .header-interstitial .header-primary::after {
        content: '';
        width: 0;
        height: 0;
        border-bottom: 7.1rem solid transparent;
        border-top: 7.2rem solid transparent;
        border-left: 7rem solid #ac2299;
        font-size: 0;
        line-height: 0;
        position: absolute;
        top: -4.6rem;
        right: -5.9rem;
        transform: translateZ(1px) rotate(-135deg);
        z-index: -1; }
        @media (min-width: 56.25em) {
          .landing .header-interstitial .header-primary::after {
            border-bottom: 11.1rem solid transparent;
            border-top: 10.9rem solid transparent;
            border-left: 11rem solid #ac2299;
            top: -7.2rem;
            right: -9.37rem; } }
      .landing .header-interstitial .header-primary .suptitle {
        z-index: 20;
        color: #ffffff;
        max-width: 14rem;
        margin: 0 auto;
        position: relative;
        left: 1rem;
        bottom: 1rem;
        font-size: .8rem; }
        @media (min-width: 56.25em) {
          .landing .header-interstitial .header-primary .suptitle {
            left: 2.5rem;
            bottom: 2rem; } }
        @media (min-width: 75em) {
          .landing .header-interstitial .header-primary .suptitle {
            left: 20%;
            font-size: 1rem; } }
      .landing .header-interstitial .header-primary .suptitle:after {
        background-color: #891b7a; }
    .landing .header-interstitial .header-secondary {
      width: 60%;
      position: relative;
      z-index: 5; }
      @media (min-width: 56.25em) {
        .landing .header-interstitial .header-secondary {
          width: 80%; } }

#talent .landing__hero {
  background-image: url("./images/talent-hero_edited-1400.png"); }

#connectivity .landing__hero {
  background-image: url("./images/connectivity-hero-updated-1400.png"); }

#businessclimate .landing__hero {
  background-image: url("./images/hero-businessclimate-1400.png"); }

#livability .landing__hero {
  background-image: url("./images/hero-livability-1400.png"); }

#livability .landing__feature:after {
  background-image: url("./images/hero-secondary-livability-1400.png"); }

#connectivity .multimodal {
  background: no-repeat url(./images/businessclimate-header-hub-1400.png);
  background-size: cover;
  background-position: bottom center; }

#businessclimate .businesshub {
  background: no-repeat url(./images/connectivity-header-multimodal-1400.png);
  background-size: cover;
  background-position: bottom center; }

#talent .education {
  background: no-repeat url(./images/talent-header-education2-1400.png);
  background-size: cover;
  background-position: bottom center; }

#talent .future-talent {
  background: no-repeat url(./images/talent-header-future-1400.png);
  background-size: cover;
  background-position: bottom center; }

.row.row__graphs {
  display: flex; }

@media (max-width: 56.25em) {
  #connectivity .after-hero,
  #businessclimate .after-hero {
    margin-top: 1rem; } }

.sites .sites-section {
  background-color: #ffffff; }
  .sites .sites-section:after {
    border-bottom-color: #efefef; }

/*---
title: Sites Section
section: Layout
---

*/
.sites-section {
  padding-top: 4rem;
  background: #f7f7f7; }
  .sites-section__map {
    display: none; }
  .sites-section .title, .sites-section .landing .interstitial .caption, .landing .interstitial .sites-section .caption {
    text-align: left; }
  .sites-section .suptitle:after {
    height: 1rem;
    left: 1rem;
    width: 100%;
    /*background-color: $white;*/ }
  .sites-section .description, .sites-section .landing__stat-section li, .landing__stat-section .sites-section li {
    margin-left: auto;
    margin-right: auto; }
  .sites-section__content {
    display: flex;
    flex-direction: column; }
  .sites-section::after {
    display: none; }

@media (max-width: 56.25em) {
  .sites-section {
    /*padding-bottom: 4rem;*/
    padding-bottom: 1rem; } }

@media (min-width: 56.25em) {
  .sites-section__content {
    flex-direction: row;
    /*padding-bottom: 4rem;*/ }
    .sites-section__content .col {
      width: 50%; }
  .sites-section__map {
    display: block;
    width: 100%; } }

@media (min-width: 75em) {
  .sites-section {
    position: relative;
    overflow: hidden; }
    .sites-section:after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 15rem;
      height: 15rem;
      border-color: transparent;
      border-width: 15rem;
      border-bottom-color: #ffffff;
      border-style: solid;
      transform: rotate(45deg) translate(0, -21.5rem); } }

/* Downloads Page */
/* FAQs Page */
.faqs .sites-section {
  background: #ffffff; }

.sites-section.downloads-section .sites-section__content,
.sites-section.faqs-section .sites-section__content,
.sites-section.page-not-found-section .sites-section__content {
  max-width: 850px !important;
  margin: 0 auto;
  display: block; }

.sites-section.downloads-section .description, .sites-section.downloads-section .landing__stat-section li, .landing__stat-section .sites-section.downloads-section li,
.sites-section.faqs-section .description,
.sites-section.faqs-section .landing__stat-section li, .landing__stat-section
.sites-section.faqs-section li,
.sites-section.page-not-found-section .description,
.sites-section.page-not-found-section .landing__stat-section li, .landing__stat-section
.sites-section.page-not-found-section li {
  margin: 0; }
  @media (min-width: 56.25em) {
    .sites-section.downloads-section .description, .sites-section.downloads-section .landing__stat-section li, .landing__stat-section .sites-section.downloads-section li,
    .sites-section.faqs-section .description,
    .sites-section.faqs-section .landing__stat-section li, .landing__stat-section
    .sites-section.faqs-section li,
    .sites-section.page-not-found-section .description,
    .sites-section.page-not-found-section .landing__stat-section li, .landing__stat-section
    .sites-section.page-not-found-section li {
      padding: 2rem 0rem; } }
  @media (min-width: 75em) {
    .sites-section.downloads-section .description, .sites-section.downloads-section .landing__stat-section li, .landing__stat-section .sites-section.downloads-section li,
    .sites-section.faqs-section .description,
    .sites-section.faqs-section .landing__stat-section li, .landing__stat-section
    .sites-section.faqs-section li,
    .sites-section.page-not-found-section .description,
    .sites-section.page-not-found-section .landing__stat-section li, .landing__stat-section
    .sites-section.page-not-found-section li {
      padding: 2rem 2rem; } }

.sites-section.downloads-section::after,
.sites-section.faqs-section::after,
.sites-section.page-not-found-section::after {
  content: none; }

/* Download Content */
@media (min-width: 56.25em) {
  .downloads {
    padding: 2rem 0rem; } }

@media (min-width: 75em) {
  .downloads {
    padding: 2rem 2rem; } }

.downloads .download-item {
  padding: 3rem 0rem;
  display: flex; }
  .downloads .download-item img {
    display: none;
    /* Only if client wants to add images/icon, turn flex on ^above */
    /*@media (min-width: 37.5em) {
        display: block;
        padding-left: 2rem;
      }*/ }
  .downloads .download-item div {
    width: 100%; }
    .downloads .download-item div h4.title.turquoise.download__title, .downloads .download-item div .landing .interstitial h4.turquoise.download__title.caption, .landing .interstitial .downloads .download-item div h4.turquoise.download__title.caption {
      text-align: left; }
  .downloads .download-item .call-to-action.download-link {
    margin-bottom: 1rem; }

/* FAQs Content */
.faqs-section .faqs-nav {
  list-style: none;
  padding: 0; }
  @media (min-width: 56.25em) {
    .faqs-section .faqs-nav {
      padding: 1rem 0 0 2rem; } }
  .faqs-section .faqs-nav li {
    display: block;
    margin-top: .5rem; }
    @media (min-width: 56.25em) {
      .faqs-section .faqs-nav li {
        display: inline-block; } }
    .faqs-section .faqs-nav li a {
      font-family: univia-pro,sans-serif; }
    .faqs-section .faqs-nav li:not(:first-child):not(.not):before {
      content: none; }
      @media (min-width: 56.25em) {
        .faqs-section .faqs-nav li:not(:first-child):not(.not):before {
          content: "|";
          color: #4b4b4b; } }

@media (min-width: 56.25em) {
  .accordion {
    padding: 2rem 0rem; } }

@media (min-width: 75em) {
  .accordion {
    padding: 2rem 2rem; } }

.accordion h3.title.purple, .accordion .landing .interstitial .purple.caption, .landing .interstitial .accordion .purple.caption {
  text-align: left;
  padding-top: 1rem;
  padding-bottom: 1rem; }

.accordion h4.title.turquoise, .accordion .landing .interstitial h4.turquoise.caption, .landing .interstitial .accordion h4.turquoise.caption {
  text-align: left;
  padding-top: 1.5rem;
  /*cursor: pointer;*/ }

.accordion__entry {
  position: relative;
  margin: .5rem 0; }

.accordion__description {
  overflow: hidden;
  /* change to 0 for accordion function, turn on cursor ^above */
  max-height: 100%;
  transition: max-height .5s ease-in-out; }
  .accordion__description.open {
    max-height: 100%; }
  .accordion__description .description-content {
    padding: 0 0rem 2rem 0rem; }
    @media (min-width: 56.25em) {
      .accordion__description .description-content {
        padding: 0 2rem 2rem 2rem; } }
    .accordion__description .description-content .list {
      margin: 0; }
      .accordion__description .description-content .list li {
        margin-bottom: 1rem; }

.accordion::after,
.downloads::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  background-image: url("./images/dots-talent-top-1000.png");
  background-position: center center;
  background-size: cover;
  height: 6rem;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg); }
  @media (min-width: 56.25em) {
    .accordion::after,
    .downloads::after {
      height: 12rem;
      width: 65%;
      left: 0; } }

/* 404 Page */
.page-not-found .description, .page-not-found .landing__stat-section li, .landing__stat-section .page-not-found li {
  padding-bottom: 2rem; }

/*
 * Talent Section
 */
.talent-section, .faqs-section, .downloads-section, .page-not-found-section {
  position: relative;
  padding-top: 5rem; }
  .talent-section:before, .faqs-section:before, .downloads-section:before, .page-not-found-section:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    background-image: url("./images/dots-talent-top-1000.png");
    background-position: center center;
    background-size: cover;
    height: 6rem; }

@media (min-width: 56.25em) {
  .talent-section:before,
  .faqs-section:before,
  .downloads-section:before,
  .page-not-found-section:before {
    height: 12rem;
    width: 65%; } }

/*
 * Talent Section Header
 */
.talent-section__header .title, .talent-section__header .landing .interstitial .caption, .landing .interstitial .talent-section__header .caption {
  max-width: 950px; }

@media (max-width: 75em) {
  .talent-section__header {
    margin-bottom: 4rem; } }

@media (min-width: 75em) {
  .talent-section__header {
    min-height: 500px;
    padding-bottom: 20px; }
    .talent-section__header .description, .talent-section__header .landing__stat-section li, .landing__stat-section .talent-section__header li {
      margin-top: 3rem;
      width: 40%; } }

@media (min-width: 90em) {
  .talent-section__header {
    width: 80%; }
    .talent-section__header .description, .talent-section__header .landing__stat-section li, .landing__stat-section .talent-section__header li {
      width: 50%; } }

/*
 * Talent Section Body
 */
.talent-section__body .col:last-of-type > p, .talent-section__body .accordion__description .description-content .col:last-of-type > .list, .accordion__description .description-content .talent-section__body .col:last-of-type > .list {
  margin-top: 2rem;
  margin-bottom: 3rem; }

@media (max-width: 75em) {
  .talent-section__body {
    padding: 2rem 0 4rem; }
    .talent-section__body .col:first-of-type {
      display: none; } }

@media (min-width: 56.25em) and (max-width: 75em) {
  .talent-section__body .figure-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
  .talent-section__body .figure-row__item {
    display: block;
    text-align: center;
    padding: 0 1rem;
    width: 33%;
    border: none; }
    .talent-section__body .figure-row__item header,
    .talent-section__body .figure-row__item footer {
      width: 100%; }
    .talent-section__body .figure-row__item h3, .talent-section__body .figure-row__item .landing .interstitial .caption, .landing .interstitial .talent-section__body .figure-row__item .caption {
      font-size: 2.16rem;
      margin-bottom: 0.5rem; } }

@media (min-width: 75em) {
  .talent-section__body .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
  .talent-section__body .col {
    flex: 1;
    width: 45%; }
  .talent-section__body .col:first-of-type {
    position: relative;
    min-height: 700px; }
    .talent-section__body .col:first-of-type > img {
      position: absolute;
      bottom: 0;
      max-width: 450px;
      max-height: 100%; }
  .talent-section__body .col:last-of-type {
    margin-top: -220px;
    padding-bottom: 4rem; } }

.top-section {
  background: #f7f7f7;
  padding-bottom: 2rem; }
  @media (min-width: 56.25em) {
    .top-section {
      padding: 2rem; } }
  .top-section__header {
    padding-top: 3rem; }
    @media (min-width: 56.25em) {
      .top-section__header h2 {
        width: 80%; } }
    @media (min-width: 75em) {
      .top-section__header h2 {
        width: 100%; } }
  .top-section__wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2rem; }
    .top-section__wrapper .top-item {
      display: flex;
      flex-direction: column;
      padding: 0; }
      @media (min-width: 56.25em) {
        .top-section__wrapper .top-item {
          padding: 2rem 0rem 0rem; } }
      @media (min-width: 1024px) {
        .top-section__wrapper .top-item {
          width: 50%;
          flex-direction: row; } }
      .top-section__wrapper .top-item > div {
        width: 100%; }
        @media (min-width: 56.25em) {
          .top-section__wrapper .top-item > div {
            width: 65%; } }
        @media (min-width: 75em) {
          .top-section__wrapper .top-item > div {
            width: 70%; } }
        .top-section__wrapper .top-item > div .title, .top-section__wrapper .top-item > div .landing .interstitial .caption, .landing .interstitial .top-section__wrapper .top-item > div .caption {
          color: #4b4b4b;
          font-size: 2rem; }

/*---
title: Business Climate Section
section: Layout
---



```example:html

```

*/
.business-climate-section {
  padding: 0; }
  .business-climate-section .col {
    margin-bottom: 2rem; }
  .business-climate-section .blockquote {
    margin-top: 4rem; }

@media (min-width: 56.25em) {
  .business-climate-section {
    padding: 4rem 0;
    position: relative; }
    .business-climate-section .row {
      display: flex;
      flex-direction: row;
      justify-content: flex-end; }
    .business-climate-section .col {
      width: 50%; }
      .business-climate-section .col.is-fixed {
        position: fixed;
        top: 0; }
      .business-climate-section .col.is-stuck {
        position: absolute;
        bottom: 6rem; }
      .business-climate-section .col:first-of-type {
        margin-bottom: 0;
        padding-right: 2rem; } }

@media (min-width: 75em) {
  .business-climate-section .blockquote {
    font-size: 1.4rem; } }

.delta {
  font-family: univia-pro,sans-serif; }

/*---
title: Presented Section
section: Layout
---



```example:html

```

*/
.presented-section {
  position: relative;
  /*padding: 0rem 0 6rem;*/
  /*background-color: $background__black;*/
  background-color: #ffffff;
  /*&:before {
    left: 0;
    background-image: url('images/dots-footer-left-1000.png');
    background-position: left center;
  }

  &:after {
    right: 0;
    background-image: url('images/dots-footer-right-1000.png');
    background-position: right center;
  }*/ }
  @media (min-width: 56.25em) {
    .presented-section {
      /*padding: 0rem 0 3rem;*/ } }
  .presented-section:before, .presented-section:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50%;
    background-size: cover;
    z-index: -1;
    display: none; }
  .presented-section .disclaimer {
    background-color: #f7f7f7; }
    .presented-section .disclaimer .description, .presented-section .disclaimer .landing__stat-section li, .landing__stat-section .presented-section .disclaimer li {
      padding: 2rem 1rem;
      text-align: left;
      margin: auto; }
      @media (min-width: 56.25em) {
        .presented-section .disclaimer .description, .presented-section .disclaimer .landing__stat-section li, .landing__stat-section .presented-section .disclaimer li {
          width: 75%;
          padding: 2rem 2rem; } }
  .presented-section .disclaimer.bid {
    background: #4b4b4b;
    color: white; }
  .presented-section .ribbon {
    border-bottom: 1px solid #f7f7f7; }

@media (min-width: 56.25em) {
  .presented-section {
    background-color: transparent; }
    .presented-section:before, .presented-section:after {
      display: block; } }

/*---
title: Properties Section
section: Layout
---



```example:html

```

*/
.sites-section {
  padding-bottom: 0; }
  .sites-section .property-lockup {
    padding: 2rem 0 0 0;
    background: #f7f7f7; }
    @media (min-width: 56.25em) {
      .sites-section .property-lockup {
        flex-direction: row-reverse;
        display: flex;
        justify-content: center; } }
  .sites-section .map-properties {
    width: 100%; }
    @media (min-width: 56.25em) {
      .sites-section .map-properties {
        width: 60%;
        margin: auto; } }
    .sites-section .map-properties img {
      display: block;
      margin: 0 auto; }
  .sites-section .property-list {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 1rem 0;
    flex-wrap: wrap;
    /*@media (min-width: 56.25em){
      width: 40%;
    }*/ }
    .sites-section .property-list .property {
      /* width: 50%;*/
      margin: .5rem auto; }
    .sites-section .property-list img,
    .sites-section .property-list span {
      display: block;
      margin: 0 auto; }
    .sites-section .property-list span {
      color: #ac2299;
      text-align: center;
      margin-top: 2.5rem;
      margin-bottom: 2rem; }
    .sites-section .property-list img {
      border-radius: 50%;
      width: 300px;
      height: 300px;
      transition: all .8s ease-in-out; }
    .sites-section .property-list a:hover img {
      transform: scale(1.1); }

/*---
title: Sites Map
section: Layout
---



```example:html

```

*/
.sites-map .container {
  padding: 4rem 0; }

.sites-map .title, .sites-map .landing .interstitial .caption, .landing .interstitial .sites-map .caption {
  margin-bottom: 1rem; }

@media (min-width: 75em) {
  .sites-map {
    position: relative; }
    .sites-map .container {
      position: absolute;
      bottom: 0;
      z-index: 10;
      background-color: #ffffff;
      left: 4rem;
      max-width: 550px;
      padding: 2rem 1.5rem 3rem; }
    .sites-map .title, .sites-map .landing .interstitial .caption, .landing .interstitial .sites-map .caption {
      max-width: 450px; } }

@media (min-width: 90em) {
  .sites-map .container {
    left: 6rem;
    max-width: 525px;
    padding: 2rem 2rem 5rem; } }

/*---
title: Site Detail
section: Layout
---

Layout for Site Detail pages

```example:html

```

ToC:
siteDetail: .site-detail
hero: .site-detail__hero
intro: .site-detail__intro
facts: .site-detail__facts
connectivity: .site-detail__connectivity
developers: .site-detail__developers

*/
/* siteDetail */
/* hero */
.site-detail__hero {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 75vh;
  min-height: 700px;
  overflow: hidden; }
  .site-detail__hero-image {
    min-width: 100%;
    min-height: 100%;
    background-position: center center;
    background-size: cover; }
    .site-detail__hero-image:not(:first-of-type) {
      display: none; }
  .site-detail__hero-header {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5; }
    .site-detail__hero-header .container {
      max-width: 1296px;
      /* 90% of 1440px */ }
    .site-detail__hero-header:after {
      content: '';
      position: absolute;
      left: -5%;
      right: -5%;
      bottom: -5rem;
      width: 110%;
      height: 20rem;
      transform: rotate(5deg);
      z-index: -1; }
  .site-detail__hero .title, .site-detail__hero .landing .interstitial .caption, .landing .interstitial .site-detail__hero .caption {
    margin: 6rem 0 2rem; }

@media (max-width: 37.438em) {
  .site-detail__hero-header {
    bottom: 4rem; } }

@media (min-width: 37.5em) {
  .site-detail__hero-header:after {
    bottom: -8rem; } }

@media (min-width: 56.25em) {
  .site-detail__hero-image {
    min-width: 40%; }
    .site-detail__hero-image:first-of-type {
      min-width: 60%; }
    .site-detail__hero-image:not(:first-of-type) {
      display: block; }
  .site-detail__hero-header:after {
    bottom: -10rem; } }

@media (min-width: 75em) {
  .site-detail__hero-header:after {
    /* bottom: -12rem; */ } }

/* intro */
.site-detail__intro .row {
  display: flex;
  flex-direction: column; }

.site-detail__intro .col {
  overflow: hidden; }
  .site-detail__intro .col:first-of-type {
    position: relative;
    width: 90%;
    margin: 0 auto;
    padding: 4rem 0; }
  .site-detail__intro .col:last-of-type {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 75vh; }

.site-detail__intro-image {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-position: center center;
  background-size: cover; }
  .site-detail__intro-image:first-of-type {
    min-height: 40%; }
  .site-detail__intro-image:last-of-type {
    min-height: 60%; }

@media (min-width: 56.25em) {
  .site-detail__intro .row {
    flex-direction: row; }
  .site-detail__intro .col {
    position: relative;
    width: 55%; }
    .site-detail__intro .col:first-of-type {
      width: 45%;
      max-width: 568px;
      margin: 0;
      margin-left: 5%;
      padding: 6rem 0 10rem; }
    .site-detail__intro .col:last-of-type:before {
      content: '';
      position: absolute;
      top: -4rem;
      left: -12rem;
      height: 125%;
      width: 15rem;
      background-color: #ffffff;
      transform: rotate(5deg); }
  .site-detail__intro .play-button {
    margin-left: 6rem; }
  .site-detail__intro-image:first-of-type, .site-detail__intro-image:last-of-type {
    min-height: 50%; }
  .site-detail__intro .description, .site-detail__intro .landing__stat-section li, .landing__stat-section .site-detail__intro li {
    margin-bottom: 0; } }

@media (min-width: 90em) {
  .site-detail__intro .col:first-of-type {
    margin-left: auto; } }

/* facts */
@media (min-width: 56.25em) {
  .site-detail__facts {
    padding-bottom: 3rem; }
    .site-detail__facts .fact-list {
      width: 90%;
      max-width: 1440px;
      margin: -1rem auto 0; }
      .site-detail__facts .fact-list:first-of-type {
        margin-bottom: 1rem; }
      .site-detail__facts .fact-list__fact:first-of-type {
        margin-left: 0; }
      .site-detail__facts .fact-list__fact:last-of-type {
        margin-right: 0; } }

@media (min-width: 75em) {
  .site-detail__facts {
    /* z-index: 20; */ }
    .site-detail__facts .fact-list {
      margin: -2rem auto 0; }
      .site-detail__facts .fact-list:first-of-type {
        margin-bottom: 2rem; } }

/* connectivity */
.site-detail__connectivity .fact-list {
  width: 100%; }

.site-detail__connectivity-content {
  padding: 4rem 0; }

.site-detail__connectivity .row.reverse {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center; }
  .site-detail__connectivity .row.reverse .col {
    padding: 2rem 0; }
    .site-detail__connectivity .row.reverse .col:last-of-type {
      text-align: center; }

@media (min-width: 37.5em) {
  .site-detail__connectivity .image:not(:first-of-type) {
    max-width: 50%;
    padding-top: 50%; }
  .site-detail__connectivity .fact-list {
    flex-direction: row;
    flex-wrap: wrap; } }

@media (min-width: 56.25em) {
  .site-detail__connectivity {
    padding-bottom: 2rem; }
    .site-detail__connectivity .fact-list {
      max-width: 650px;
      margin-top: -150px; }
      .site-detail__connectivity .fact-list__fact:nth-of-type(2n + 1) {
        margin-left: 0; }
      .site-detail__connectivity .fact-list__fact:nth-of-type(2n) {
        margin-right: 0; } }

@media (min-width: 75em) {
  .site-detail__connectivity-content .col:first-of-type {
    width: 50%; }
  .site-detail__connectivity-content .col:last-of-type {
    width: 40%; }
  .site-detail__connectivity .row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between; }
    .site-detail__connectivity .row.reverse {
      align-items: center;
      flex-direction: row-reverse; }
  .site-detail__connectivity .row.reverse .col {
    max-width: 800px; }
  .site-detail__connectivity .row.reverse .col:first-of-type {
    width: 70%; }
  .site-detail__connectivity .row.reverse .col:last-of-type {
    width: 30%; } }

@media (min-width: 90em) {
  .site-detail__connectivity-content:nth-of-type(2n) {
    flex-direction: row-reverse; } }

/* developers */
.site-detail__developers {
  padding: 3rem 0; }

/* map interstitial*/
.map-interstitial img {
  width: 100%; }

@media (min-width: 56.25em) {
  .map-interstitial {
    position: relative;
    z-index: 1; } }

/* New Property Section*/
.national-landing .property-details {
  padding: 1rem 0; }
  @media (max-width: 56.25em) {
    .national-landing .property-details {
      width: 90%;
      margin: auto;
      padding: 0; } }
  @media (min-width: 56.25em) {
    .national-landing .property-details .row {
      display: flex; } }
  @media (min-width: 56.25em) {
    .national-landing .property-details .row .col-50 {
      width: 48%;
      margin: 0 auto; } }
  .national-landing .property-details .row.\--center-col {
    justify-content: center; }
  @media (min-width: 56.25em) {
    .national-landing .property-details .full-width-left > div:last-child {
      margin-right: 0;
      padding-right: 0; } }
  @media (min-width: 56.25em) {
    .national-landing .property-details .full-width-right > div:first-child {
      margin-left: 0;
      padding-left: 0; } }
  .national-landing .property-details .center-content, .national-landing .property-details .landing .center-content-top, .landing .national-landing .property-details .center-content-top {
    display: flex;
    flex-direction: column;
    justify-content: center; }
    @media (min-width: 56.25em) {
      .national-landing .property-details .center-content, .national-landing .property-details .landing .center-content-top, .landing .national-landing .property-details .center-content-top {
        width: 80%;
        margin: 0 auto; } }

/*
 * National Landing
 *
 * These styles are specific to the National Landing site detail page. Most styling
 * for this page should be in _site-detail.scss, not in here.
 */
.national-landing .site-detail__hero-image {
  background-image: url("images/map-hero-nationallanding-1400.jpg"); }
  .national-landing .site-detail__hero-image:first-of-type {
    background-image: url("images/Mets-and-Pen-1400.jpg"); }

.national-landing .site-detail__hero-header:after {
  background-color: #ffb829; }

.national-landing .site-detail__intro-image:first-of-type {
  background-image: url("images/1550-Crystal-Drive-1400.jpg"); }

.national-landing .site-detail__intro-image:last-of-type {
  background-image: url("images/1770-Crystal-Drive-1400.jpg"); }

.national-landing .site-detail__connectivity .fact-list__fact {
  background-color: #ffb829; }

.national-landing .image:first-of-type {
  background-image: url("images/atthemovies_1400x1058-1400.jpg"); }

.national-landing .image:nth-of-type(2n) {
  background-image: url("images/Pen-Place-Courtyard-Night-1400.jpg"); }

.national-landing .image:nth-of-type(3n) {
  background-image: url("images/NatLanding_Grid3-1400.jpg"); }

/****
* Image caption
****/
.national-landing .image-figure {
  display: block; }

.national-landing .image-with-caption {
  position: relative; }
  .national-landing .image-with-caption .image-figure {
    display: block;
    position: absolute;
    top: 0;
    right: 0; }

/*
 * Alexandria
 *
 * These styles are specific to the Alexandria site detail page. Most styling
 * for this page should be in _site-detail.scss, not in here.
 */
.alexandria .site-detail__hero-image {
  background-image: url("images/map-hero-alexandria-1400.jpg"); }
  .alexandria .site-detail__hero-image:first-of-type {
    background-image: url("images/Alex_Hero-1400.jpg"); }

.alexandria .site-detail__hero-header:after {
  background-color: #21ab92; }

.alexandria .site-detail__intro-image:first-of-type {
  background-image: url("images/Alex_VideoStill-1400.jpg"); }

.alexandria .site-detail__intro-image:last-of-type {
  background-image: url("images/Alex_UnderV-1400.jpg"); }

.alexandria .site-detail__connectivity .fact-list__fact {
  background-color: #21ab92; }

.alexandria .image:first-of-type {
  background-image: url("images/Alex_Grid1-1400.jpg"); }

.alexandria .image:nth-of-type(2n) {
  background-image: url("images/Alex_Grid2-1400.jpg"); }

.alexandria .image:nth-of-type(3n) {
  background-image: url("images/Alex_Grid3-1400.jpg"); }

/*
 * Innovation Station
 *
 * These styles are specific to the Innovation Station site detail page. Most styling
 * for this page should be in _site-detail.scss, not in here.
 */
.innovation-station .site-detail__hero svg {
  fill: #1d9bfc; }

.innovation-station .site-detail__hero-image {
  background-image: url("images/map-hero-innovationstation-1400.jpg"); }
  .innovation-station .site-detail__hero-image:first-of-type {
    background-image: url("images/Innovation_Hero-1400.jpg"); }

.innovation-station .site-detail__hero-header:after {
  background-color: #1d9bfc; }

.innovation-station .site-detail__intro-image:first-of-type {
  background-image: url("images/Innovation_VideoStill-1400.jpg"); }

.innovation-station .site-detail__intro-image:last-of-type {
  background-image: url("images/innovation-station-under-video-1000.jpg"); }

.innovation-station .site-detail__connectivity .fact-list__fact {
  background-color: #1d9bfc; }

.innovation-station .image:first-of-type {
  background-image: url("images/Innovation_Grid1-1400.jpg"); }

.innovation-station .image:nth-of-type(2n) {
  background-image: url("images/innovation-station-connectivity-02-1000.jpg"); }

.innovation-station .image:nth-of-type(3n) {
  background-image: url("images/innovation-station-connectivity-03-1000.png"); }

.innovation-station .map-interstitial {
  position: relative;
  top: -6rem; }

/*
 * Capital View
 *
 * These styles are specific to the Capital View site detail page. Most styling
 * for this page should be in _site-detail.scss, not in here.
 */
.capital-view .site-detail__hero-image {
  background-image: url("images/map-hero-capitalview-1400.jpg"); }
  .capital-view .site-detail__hero-image:first-of-type {
    background-image: url("images/CapView_Hero-1400.jpg"); }

.capital-view .site-detail__hero-header:after {
  background-color: #ac2299; }

.capital-view .site-detail__intro-image:first-of-type {
  background-image: url("images/CapView_VideoStill-1400.jpg"); }

.capital-view .site-detail__intro-image:last-of-type {
  background-image: url("images/CapView_UnderV-1400.jpg"); }

.capital-view .site-detail__connectivity .fact-list__fact {
  background-color: #ac2299; }

.capital-view .image:first-of-type {
  background-image: url("images/CapView_Grid1-1400.jpg"); }

.capital-view .image:nth-of-type(2n) {
  background-image: url("images/CapView_Grid2-1400.jpg"); }

.capital-view .image:nth-of-type(3n) {
  background-image: url("images/CapView_Grid3-1400.jpg"); }

/*---
title: Talent Future Section
section: Layout
---

*/
.talent__body .description, .talent__body .landing__stat-section li, .landing__stat-section .talent__body li {
  font-size: 1.15rem;
  font-weight: 100; }

/*---
title: Talent Education Section
section: Layout
---

*/
.talent-education-section {
  margin-top: 90px; }
  .talent-education-section .header-secondary-bg {
    background: no-repeat url(./images/gmu-students-campus-1000.jpg); }
  .talent-education-section .row {
    margin-top: 70px;
    margin-bottom: 70px; }
  .talent-education-section .quote .quote-image {
    width: 290px;
    height: 365px; }
  .talent-education-section .image-title-callout .title, .talent-education-section .image-title-callout .landing .interstitial .caption, .landing .interstitial .talent-education-section .image-title-callout .caption {
    font-size: 1.4rem; }

@media (min-width: 56.25em) {
  .talent-education-section .header-secondary-bg {
    background: no-repeat url(./images/gmu-students-campus-1400.jpg); }
  .talent-education-section .row {
    display: flex; }
  .talent-education-section .col {
    width: 50%; }
    .talent-education-section .col:first-child {
      margin-right: 10%; }
  .talent-education-section .row:nth-child(even) {
    display: block; }
    .talent-education-section .row:nth-child(even) .col {
      width: 100%;
      margin-right: 0; }
  .talent-education-section .image-title-callout .title, .talent-education-section .image-title-callout .landing .interstitial .caption, .landing .interstitial .talent-education-section .image-title-callout .caption {
    font-size: 1.4rem; }
  .talent-education-section .image-title-callout:before {
    background: no-repeat url(./images/macbook-1000.png);
    background-size: cover;
    width: 469px;
    height: 272px; } }

/*---
title: Talent Future Section
section: Layout
---

*/
.talent-future-section {
  margin-top: 90px; }
  .talent-future-section .header-secondary-bg {
    background: no-repeat url(./images/meeting-room-1000.jpg);
    background-size: cover; }
  .talent-future-section .row {
    margin-top: 70px;
    margin-bottom: 70px; }

@media (min-width: 56.25em) {
  .talent-future-section .header-secondary-bg {
    background: no-repeat url(./images/meeting-room-1400.jpg); }
  .talent-future-section .row {
    display: flex; }
  .talent-future-section .col {
    width: 50%; }
    .talent-future-section .col:first-child {
      margin-right: 10%; } }

/*---
title: Talent Labor Education Section
section: Layout
---

*/
.talent-labor-education-section .row__primary .graph {
  margin: auto;
  display: block; }
  .talent-labor-education-section .row__primary .graph img {
    padding-top: 10%; }
  .talent-labor-education-section .row__primary .graph figcaption {
    background: transparent;
    padding: 0 0 0 12px;
    border-left: 6px solid #21ab92;
    width: 65%;
    left: 0;
    letter-spacing: normal;
    line-height: 2em;
    text-transform: none; }

.talent-labor-education-section .row__secondary {
  margin: 8% 0; }
  @media (min-width: 56.25em) and (max-width: 75em) {
    .talent-labor-education-section .row__secondary .image-title-callout {
      padding-bottom: 165px; } }
  .talent-labor-education-section .row__secondary .image-title-callout h3.title, .talent-labor-education-section .row__secondary .image-title-callout .landing .interstitial .caption, .landing .interstitial .talent-labor-education-section .row__secondary .image-title-callout .caption {
    max-width: 70rem; }
    @media (max-width: 56.25em) {
      .talent-labor-education-section .row__secondary .image-title-callout h3.title, .talent-labor-education-section .row__secondary .image-title-callout .landing .interstitial .caption, .landing .interstitial .talent-labor-education-section .row__secondary .image-title-callout .caption {
        font-size: 1.3rem; } }
    @media (max-width: 75em) {
      .talent-labor-education-section .row__secondary .image-title-callout h3.title, .talent-labor-education-section .row__secondary .image-title-callout .landing .interstitial .caption, .landing .interstitial .talent-labor-education-section .row__secondary .image-title-callout .caption {
        font-size: 1.6rem; } }

@media (min-width: 56.25em) {
  .talent-labor-education-section .row__primary {
    display: flex; }
    .talent-labor-education-section .row__primary .col {
      width: 50%; }
      .talent-labor-education-section .row__primary .col:first-child {
        margin-right: 10%; }
  .talent-labor-education-section .row__secondary {
    margin: 5% 0 0 0; }
    .talent-labor-education-section .row__secondary .image-title-callout:before {
      background: no-repeat url(./images/graduation-cap-1000.png);
      background-size: cover;
      width: 409px;
      height: 320px; } }

/*---
title: Talent Labor Image Grid Section
section: Layout
---

*/
.talent-labor-imagegrid-section .image:first-of-type {
  background-image: url("images/overhead-tables-1400.jpg"); }

.talent-labor-imagegrid-section .image:nth-of-type(2n) {
  background-image: url("images/mason-400.png"); }

.talent-labor-imagegrid-section .image:nth-of-type(3n) {
  background-image: url("images/vt-400.png"); }

/*---
title: Talent Labor Section
section: Layout
---

*/
.talent-labor-section .row__graphs {
  display: flex;
  padding-bottom: 100px; }
  .talent-labor-section .row__graphs .col {
    width: 50%; }
  .talent-labor-section .row__graphs .circle-graph {
    margin: auto; }

.talent-labor-section .row__hilight {
  margin: 100px 0 40px 0; }

.talent-labor-section .hilight {
  margin-top: 0;
  margin-bottom: 0; }

.talent-labor-section__header .suptitle:after {
  left: 1.1rem;
  width: 130%; }

@media (min-width: 56.25em) {
  .talent-labor-section {
    position: relative; }
    .talent-labor-section .row__graphs {
      width: 55%; }
      .talent-labor-section .row__graphs .circle-graph {
        margin: 0; }
    .talent-labor-section .col {
      width: 60%; }
    .talent-labor-section:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 50%;
      height: 100%;
      background-image: url("./images/talent-labor-1000.png");
      background-position: center left;
      background-size: cover;
      background-repeat: no-repeat;
      z-index: -1; } }

/*---
title: Credit Section
section: Credits
---

*/
.credit-section .divider {
  border-top: 2px solid #ebebeb;
  padding: 2rem 0; }
  .credit-section .divider h3, .credit-section .divider .landing .interstitial .caption, .landing .interstitial .credit-section .divider .caption {
    margin: 1rem 0; }
  .credit-section .divider .supheader {
    font-weight: 600;
    color: #9f9f9f;
    font-family: univia-pro,sans-serif;
    letter-spacing: 2px;
    margin-bottom: 1rem; }
  @media (min-width: 37.5em) {
    .credit-section .divider .suptitle {
      /*max-width: 13rem;*/ } }
  @media (min-width: 56.25em) {
    .credit-section .divider .suptitle {
      margin-bottom: 2.5%; } }
  .credit-section .divider .title, .credit-section .divider .landing .interstitial .caption, .landing .interstitial .credit-section .divider .caption {
    width: 100%;
    margin-bottom: .5rem;
    margin-top: 1.5rem; }
    @media (min-width: 37.5em) {
      .credit-section .divider .title, .credit-section .divider .landing .interstitial .caption, .landing .interstitial .credit-section .divider .caption {
        font-size: 1.25rem; } }
  .credit-section .divider .credits {
    text-align: center; }
    @media (min-width: 37.5em) {
      .credit-section .divider .credits {
        display: flex;
        flex-wrap: wrap; } }
    .credit-section .divider .credits .credits__col {
      width: 100%;
      /*display: flex;*/ }
      @media (min-width: 37.5em) {
        .credit-section .divider .credits .credits__col {
          padding: 0 1%; } }
      @media (min-width: 37.5em) {
        .credit-section .divider .credits .credits__col .credits-wrapper {
          display: flex; } }
      .credit-section .divider .credits .credits__col .credits-wrapper .names p, .credit-section .divider .credits .credits__col .credits-wrapper .names .accordion__description .description-content .list, .accordion__description .description-content .credit-section .divider .credits .credits__col .credits-wrapper .names .list,
      .credit-section .divider .credits .credits__col .credits-wrapper .titles p,
      .credit-section .divider .credits .credits__col .credits-wrapper .titles .accordion__description .description-content .list, .accordion__description .description-content
      .credit-section .divider .credits .credits__col .credits-wrapper .titles .list {
        font-family: univia-pro,sans-serif;
        margin: .5rem 0; }
      .credit-section .divider .credits .credits__col .credits-wrapper .names {
        font-weight: 300;
        width: 100%; }
      .credit-section .divider .credits .credits__col .credits-wrapper ul {
        margin: 0 auto;
        padding: 0; }
        .credit-section .divider .credits .credits__col .credits-wrapper ul li {
          font-family: univia-pro,sans-serif;
          display: block; }
          @media (min-width: 37.5em) {
            .credit-section .divider .credits .credits__col .credits-wrapper ul li {
              display: inline-block; }
              .credit-section .divider .credits .credits__col .credits-wrapper ul li:not(:first-child):not(.not):before {
                content: '';
                display: inline-block;
                height: 5px;
                width: 5px;
                margin-bottom: 2px;
                border-radius: 50%;
                margin-right: 0.5rem;
                background: #dedede; } }
  @media (min-width: 37.5em) {
    .credit-section .divider .col-2 {
      width: 50%; } }
  @media (min-width: 37.5em) {
    .credit-section .divider .col-3 {
      width: 33%; } }

.credit-section .divider .credits .credits__col .credits-wrapper.tier-2 .names {
  font-weight: 300; }

.credit-section .divider .credits .credits__col .credits-wrapper.tier-3 .names {
  /* smaller font size */ }

a {
  color: inherit;
  text-decoration: none; }

/*---
title: Hamburger
section: Navigation
---

The hamburger nav, for mobile/tablet viewports.

```example:html
<a class="hamburger js-hamburger" href="#">
  <div class="hamburger__bar"> </div>
  <div class="hamburger__bar"> </div>
  <div class="hamburger__bar"> </div>
</a>
```

*/
.hamburger {
  z-index: 200; }
  .hamburger__bar {
    display: block;
    height: 3px;
    width: 34px;
    transition: all 300ms ease;
    background-color: #21ab92; }
    .hamburger__bar:not(:last-of-type) {
      margin-bottom: 6px; }

.site-header.is-active .hamburger__bar {
  opacity: 0; }

.site-header.is-active .hamburger__bar:first-of-type {
  opacity: 1;
  transform-origin: left center;
  transform: rotate(45deg) translateY(-4px); }

.site-header.is-active .hamburger__bar:last-of-type {
  opacity: 1;
  transform-origin: left center;
  transform: rotate(-45deg) translateY(4px); }

@media (min-width: 56.25em) {
  .hamburger {
    display: none; } }

/*
 *Site Header
 */
.site-header {
  background-color: #ffffff; }
  .site-header .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }

@media (max-width: 56.25em) {
  .site-header {
    padding: 0.5rem 0; }
    .site-header .site-nav {
      opacity: 0;
      pointer-events: none; }
    .site-header.is-active .site-nav {
      opacity: 1;
      pointer-events: all;
      overflow: scroll; } }

@media (min-width: 56.25em) {
  .site-header {
    padding: 0.5rem 0;
    box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.2); } }

/*
 *Site Nav
 */
body.nav-active {
  overflow: hidden;
  position: fixed;
  left: 0;
  right: 0;
  top: 0; }
  @media (min-width: 56.25em) {
    body.nav-active {
      overflow: scroll;
      position: relative; } }

.site-nav {
  background-color: #fff;
  z-index: 100; }
  .site-nav ul {
    padding: 0; }
  .site-nav li {
    display: inline-block; }
  .site-nav a {
    display: block;
    position: relative;
    font-family: "univia-pro", sans-serif;
    color: #21ab92;
    padding: 1rem 1.25rem;
    font-size: 1.4rem; }
    @media (max-width: 37.438em) {
      .site-nav a {
        padding: .75rem 1.25rem; } }
    @media (min-width: 37.5em) and (max-width: 56.25em) {
      .site-nav a {
        padding: .75rem 1.25rem; } }
    .site-nav a:after {
      content: '';
      position: absolute;
      bottom: 0.5rem;
      left: 1.25rem;
      height: 0.5rem;
      width: 80%;
      background-color: #21ab92;
      opacity: 0.5;
      transform-origin: left center;
      transform: translateX(-20px) scaleX(0);
      transition: all 250ms ease; }
    @media (min-width: 56.25em) {
      .site-nav a:hover:after, .site-nav a.active:after {
        transform: translateX(0) scaleX(1); } }
  .site-nav .download {
    color: #ffffff;
    background: #21ab92;
    padding: .6rem 1.5rem;
    position: absolute;
    width: 220px;
    right: 0;
    top: 73px; }
  .site-nav .download:after {
    background-color: transparent; }

@media (min-width: 37.5em) {
  .site-nav a {
    font-size: 1.68rem; } }

@media (max-width: 56.25em) {
  .site-nav {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    transition: all 300ms ease; }
    .site-nav ul {
      display: flex;
      flex-direction: column;
      list-style-type: none;
      margin: auto;
      width: 90%;
      max-width: 300px; }
    .site-nav a {
      margin: 0; } }

@media (min-width: 56.25em) {
  .site-nav ul {
    margin: 0; }
  .site-nav a {
    font-size: 1rem; } }

@media (min-width: 56.25em) and (max-width: 75em) {
  .site-nav a {
    font-size: 0.7rem; } }

/*---
title: Circle Graph
section: Modules
---

*/
.circle-graph {
  width: 150px;
  height: 150px;
  background: no-repeat url("./images/circle-graph-400.png");
  background-size: cover;
  text-align: center; }
  @media (min-width: 37.5em) {
    .circle-graph {
      width: 200px;
      height: 200px; } }
  .circle-graph .title, .circle-graph .landing .interstitial .caption, .landing .interstitial .circle-graph .caption {
    padding-top: 60px;
    font-size: 2.7rem;
    line-height: 1em;
    color: black; }
  .circle-graph .description, .circle-graph .landing__stat-section li, .landing__stat-section .circle-graph li {
    margin: 10px 40px 0 40px;
    font-family: "univia-pro", sans-serif;
    font-size: 0.875rem;
    line-height: 1.3em; }

/*---
title: Home Hero
section: Modules
---

The homepage hero. Includes a main content area and a secondary content area.
Could also hold lightbox content.

```example:html

<section class="hero">
  <div class="hero__content">
    <div class="container">
      <h5 class="subtitle">Welcome to Northern Virginia</h5>
      <h1 class="title">In<span class="turquoise">nova</span>tion lives here</h1>
      <a class="play-button js-play-button" href="#"></a>
    </div>
  </div>
</section>
```

*/
.home__hero {
  display: flex;
  position: relative;
  flex-direction: column;
  overflow: hidden; }
  @media (max-width: 56.25em) {
    .home__hero {
      background: url(images/Mets-and-Pen-1400.jpg) bottom center;
      background-size: cover; } }
  .home__hero-content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: auto;
    width: 100%;
    height: 90vh; }
    .home__hero-content video {
      position: absolute;
      left: 50%;
      top: 50%;
      min-width: 100%;
      min-height: 100%;
      transform: translate(-50%, -50%);
      z-index: -5; }
      @media (max-width: 56.25em) {
        .home__hero-content video {
          display: none; } }
    .home__hero-content svg {
      display: none;
      position: absolute;
      left: -5%;
      bottom: 0;
      height: 5rem;
      width: 110%;
      fill: #21ab92; }
    .home__hero-content:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #4b4b4b;
      opacity: 0.4;
      z-index: -2; }
    .home__hero-content:after {
      content: '';
      position: absolute;
      left: -5%;
      right: -5%;
      bottom: -5rem;
      z-index: -1;
      height: 10rem;
      background-color: #21ab92;
      transform: rotate(-5deg); }
    .home__hero-content .subtitle {
      font-size: 1.5rem; }
  .home__hero-secondary {
    background-color: #21ab92;
    padding: 2rem 0; }
    .home__hero-secondary-bg-shape {
      display: none; }
  .home__hero .container {
    max-width: 1400px; }
  .home__hero .suptitle {
    margin: 0; }
    .home__hero .suptitle:after {
      content: none; }

@media (min-width: 37.5em) {
  .home__hero-content svg {
    height: 10rem; } }

@media (min-width: 56.25em) {
  .home__hero-content {
    min-height: 840px;
    max-height: 1000px; } }

@media (min-width: 75em) {
  .home__hero-content {
    /* z-index: 5; */ }
  .home__hero-secondary {
    padding: 4rem 0; }
    .home__hero-secondary .title, .home__hero-secondary .landing .interstitial .caption, .landing .interstitial .home__hero-secondary .caption {
      font-size: 1.89rem;
      line-height: 1.6; } }

@media (min-width: 90em) {
  .home__hero-content:after {
    bottom: -10rem;
    height: 15rem; } }

@media (min-width: 1800px) {
  .home__hero-content:after {
    bottom: -12.5rem;
    height: 20rem; } }

/*---
title: Figure Row
section: Modules
---

List of figures accompanied with a relavent icon. Found through out the site, include the hompage talent section.

```example:html

<ul class="figure-row">
  <li class="figure-row__item">
    <header>
      <img class="icon" src="assets/icons/icon_001.svg" alt="">
    </header>
    <footer>
      <h3 class="title black">Fertum Sem</h3>
      <p><small>Nulla vitae elit libero, a pharetra augue. Maecenas sed diam eget risus varius blandit sit amet .</small></p>
    </footer>
  </li>
  <li class="figure-row__item">
    <header>
      <img class="icon" src="assets/icons/icon_002.svg" alt="">
    </header>
    <footer>
      <h3 class="title black">Fertum Sem</h3>
      <p><small>Nulla vitae elit libero, a pharetra augue. Maecenas sed diam eget risus varius blandit sit amet .</small></p>
    </footer>
  </li>
  <li class="figure-row__item">
    <header>
      <img class="icon" src="assets/icons/icon_003.svg" alt="">
    </header>
    <footer>
      <h3 class="title black">Fertum Sem</h3>
      <p><small>Nulla vitae elit libero, a pharetra augue. Maecenas sed diam eget risus varius blandit sit amet .</small></p>
    </footer>
  </li>
</ul>
```

*/
.figure-row {
  margin: 0;
  padding: 0;
  list-style-type: none;
  margin-bottom: 4rem; }
  .figure-row__item {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid #efefef; }
    .figure-row__item header,
    .figure-row__item footer {
      display: flex;
      flex-direction: column;
      justify-content: center; }
    .figure-row__item header {
      align-items: center;
      width: 20%;
      min-width: 6rem;
      margin-right: 1rem; }
    .figure-row__item footer {
      width: 80%; }
    .figure-row__item .title, .figure-row__item .landing .interstitial .caption, .landing .interstitial .figure-row__item .caption,
    .figure-row__item p,
    .figure-row__item .accordion__description .description-content .list, .accordion__description .description-content
    .figure-row__item .list {
      margin: 0; }
    .figure-row__item p, .figure-row__item .accordion__description .description-content .list, .accordion__description .description-content .figure-row__item .list {
      font-family: "univia-pro", sans-serif; }
    .figure-row__item small {
      line-height: 1;
      font-size: 0.875rem; }

/*---
title: Presenters
section: Modules
---

List of presenting partners, as found in the site footer.

```example:html
<div class="presenters">
  <div class="presenter">
    <img src="" alt=""/>
  </div>
  <div class="presenter">
    <img src="" alt=""/>
  </div>
  <div class="presenter">
    <img src="" alt=""/>
  </div>
  <div class="presenter">
    <img src="" alt=""/>
  </div>
</div>
```

*/
.presenters {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 2rem auto 0; }
  @media (min-width: 37.5em) and (max-width: 56.25em) {
    .presenters {
      margin: 0 auto;
      width: 61%; } }

.presenter {
  width: 100%;
  max-width: 250px;
  /* @media (min-width: 56.25em) {
    max-width: 184px;
  }
  @media (min-width: 75em) {
    max-width: 230px;
  }
  @media (min-width: 90em) {
    max-width: 259px;
  }
*/
  max-height: 184px;
  min-height: 130px;
  margin: 2rem auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #ffffff; }
  .presenter img {
    max-height: 100%;
    transition: all .8s ease-in-out;
    /*height: 8rem;*/
    width: 195px;
    margin: 0 auto;
    display: block;
    padding: 4% 6%; }
  .presenter img.big {
    height: 200px; }
  .presenter img.small {
    height: 6rem; }
  .presenter img:hover {
    transform: scale(1.1); }

@media (min-width: 56.25em) {
  .presenter {
    width: calc(20% - .5rem); } }

/*---
title: Image Figure
section: Modules
---

Figure image with figcaption text explaining image.

```example:html
<figure class="image-figure">
  <img src="assets/images/oldtown-1000.png" alt="">
  <figcaption>Old Town Alexandria</figcaption>
</figure>
```

*/
.image-figure {
  position: relative;
  display: none;
  margin: 0; }
  .image-figure img {
    display: inherit; }
  .image-figure figcaption {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #ffffff;
    padding: 0.25rem 2rem;
    font-size: 0.875rem;
    text-transform: uppercase;
    letter-spacing: 4px;
    color: #ac2299; }

@media (min-width: 56.25em) {
  .image-figure {
    display: block; } }

/*---
title: Javascript Fade Image Figure
section: Modules
---

*/
.js-image-figure {
  overflow: hidden; }
  .js-image-figure img {
    width: 100%;
    transform: scale(1.2);
    transition: all 300ms ease;
    opacity: 0; }
  .js-image-figure.in-view img {
    transform: scale(1);
    opacity: 1; }

/*---
title: Image Title Callout
section: Modules
---

*/
.image-title-callout .title, .image-title-callout .landing .interstitial .caption, .landing .interstitial .image-title-callout .caption {
  padding: 0 0 20px 0;
  border-bottom: 7px solid #fcac11; }
  @media (min-width: 56.25em) {
    .image-title-callout .title, .image-title-callout .landing .interstitial .caption, .landing .interstitial .image-title-callout .caption {
      font-size: 2.6rem; } }

.image-title-callout .title__emphasize {
  font-size: 1.6em; }

@media (min-width: 56.25em) {
  .image-title-callout {
    padding: 55px 0 100px 250px;
    position: relative; }
    .image-title-callout:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0; }
    .image-title-callout .title, .image-title-callout .landing .interstitial .caption, .landing .interstitial .image-title-callout .caption {
      padding-left: 200px; } }

/*---
title: Logo Group
section: Modules
---

List of logos with a heading figure and caption, as found in the business climate section of the homepage.

```example:html

<div class="logo-group">
  <header data-figure="32" data-caption="Fortune 500 companies call NOVA
    home">
    <h2 class="title purple">32</h2>
    <h3 class="title turquoise">Fortune 500 companies call NOVA home</h3>
  </header>
  <div class="logo-group__logos">
    <div class="logo-group__logo">
      <img src="assets/images/logos/aes-1000.png" alt="">
    </div>
    <div class="logo-group__logo">
      <img src="assets/images/logos/airbusgroup-1000.png" alt="">
    </div>
    <div class="logo-group__logo">
      <img src="assets/images/logos/airbusgroup-1000.png" alt="">
    </div>
    <div class="logo-group__logo">
      <img src="assets/images/logos/airbusgroup-1000.png" alt="">
    </div>
  </div>
</div>
```

*/
.logo-group header {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  position: relative;
  margin-bottom: 2rem; }
  .logo-group header:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.5rem;
    background-color: #21ab92; }

.logo-group h2 {
  font-size: 500%;
  line-height: 1;
  margin-bottom: 0.3rem; }

.logo-group h3, .logo-group .landing .interstitial .caption, .landing .interstitial .logo-group .caption {
  margin-left: 1rem;
  margin-bottom: 0.5rem;
  max-width: 300px;
  font-size: 1.54rem; }

.logo-group__logos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center; }
  @media (max-width: 56.25em) {
    .logo-group__logos {
      justify-content: space-around; } }

.logo-group__logo {
  position: relative;
  /* display: flex; */
  width: 50%;
  max-width: 200px;
  height: 150px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center; }

.logo-group img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(100% - 2rem);
  height: auto;
  transform: translate(-50%, -50%); }

@media (max-width: 56.25em) {
  .logo-group h3, .logo-group .landing .interstitial .caption, .landing .interstitial .logo-group .caption {
    font-size: 1rem; } }

@media (min-width: 56.25em) {
  .logo-group {
    padding-bottom: 4rem;
    /*
 *     header {
 *       display: none;
 *     }
 *
 *     &:first-of-type {
 *       display: block;
 *
 *       header {
 *         display: flex;
 *
 *         &.is-fixed {
 *           position: fixed;
 *           top: 4rem;
 *         }
 *       }
 *     }
 */ }
    .logo-group h2 {
      font-size: 750%; }
    .logo-group__logo {
      width: 33%; } }

/*
 * Lightbox
 * Lightbox for holding hidden video or other content.
 */
.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgba(75, 75, 75, 0.8);
  z-index: 500;
  box-sizing: border-box;
  transition: opacity 300ms ease; }
  .lightbox:not(.is-open) {
    opacity: 0;
    pointer-events: none;
    transform: translateX(-200vw); }
    .lightbox:not(.is-open) video {
      pointer-events: none; }
  .lightbox.is-open {
    opacity: 1;
    pointer-events: all;
    transform: translateX(0); }
    .lightbox.is-open video {
      pointer-events: all; }
  .lightbox__close-button {
    position: absolute;
    top: 4rem;
    right: 0rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 34px;
    min-width: 34px;
    padding: 1rem;
    z-index: 550; }
    .lightbox__close-button-bar {
      background-color: #ffffff;
      height: 3px;
      width: 34px;
      transform-origin: center; }
      .lightbox__close-button-bar:first-of-type {
        transform: rotate(45deg) translateY(2px); }
      .lightbox__close-button-bar:last-of-type {
        transform: rotate(-45deg) translateY(-2px); }
  .lightbox video {
    width: 90%;
    max-width: 1100px;
    height: auto; }

@media (min-width: 37.5em) {
  .lightbox__close-button {
    right: 1rem; } }

@media (min-width: 56.25em) {
  .lightbox__close-button {
    top: 5rem;
    right: 5%;
    /* right: calc(5% - 1rem); */ } }

/*---
title: Icon
section: Modules
---

Icons found throughout the site, primarily in the figure list.

```example:html
<img class="icon" src="assets/icons/icon_001.svg" alt="">
```

*/
.icon {
  width: 4.5rem;
  height: 4rem;
  margin: 1rem; }

/*
 * Site
 * The container for a single site (location).
 */
.site .row {
  display: flex;
  flex-direction: column; }

.site .col {
  position: relative; }
  .site .col img {
    width: 100%; }
  .site .col:last-of-type {
    padding: 2rem 1rem; }

.site .figure-row {
  margin-top: 4rem; }
  .site .figure-row__item {
    flex-direction: column;
    text-align: center;
    border: none; }

@media (min-width: 56.25em) {
  .site {
    height: 100%;
    min-height: 750px;
    background: #ffffff; }
    .site:not(.is-open) {
      display: none; }
    .site .row {
      flex-direction: row;
      height: 100%; }
    .site .col {
      width: 50%;
      background-color: #ffffff; }
      .site .col:first-of-type {
        overflow: hidden; }
        .site .col:first-of-type img {
          min-height: 100%;
          width: auto;
          max-width: unset; }
      .site .col:last-of-type {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start; }
    .site .figure-row {
      display: none; } }

@media (min-width: 90em) {
  .site .col:last-of-type {
    display: block;
    padding: 2rem; }
  .site .figure-row {
    display: block; }
    .site .figure-row__item {
      flex-direction: row;
      text-align: left; }
      .site .figure-row__item footer {
        margin-left: 1rem; } }

/*
 * Sites Lightbox
 * The lightbox containing the individual sites (locations)
 */
.sites-lightbox {
  display: none;
  width: 100%;
  max-width: 1440px; }

@media (min-width: 56.25em) {
  .sites-lightbox {
    position: absolute;
    top: 5rem;
    left: 50%;
    bottom: 5rem;
    width: 95%;
    z-index: 500;
    transition: opacity 300ms ease;
    box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.2); }
    .sites-lightbox:not(.is-open) {
      pointer-events: none;
      opacity: 0;
      transform: translateX(-200vw); }
    .sites-lightbox.is-open {
      pointer-events: all;
      opacity: 1;
      transform: translateX(-50%); } }

@media (min-width: 90em) {
  .sites-lightbox {
    bottom: unset; } }

.sites-lightbox__close-button {
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 1;
  padding: 1rem;
  display: none; }
  .sites-lightbox__close-button-bar {
    height: 3px;
    width: 32px;
    background-color: #4b4b4b;
    transform-origin: center; }
    .sites-lightbox__close-button-bar:first-of-type {
      transform: rotate(-45deg) translateX(-2px); }
    .sites-lightbox__close-button-bar:last-of-type {
      transform: rotate(45deg) translateX(-2px); }

@media (min-width: 56.25em) {
  .sites-lightbox__close-button {
    display: block; } }

/*---
title: Map Nav
section: Modules
---

The interactive map navigation element on the home page and sites page

```example:html

```

TOC:
mapNav - .map-nav
mapNav__Map - .map-nav__map
mapNav__Indicator - .map-nav__indicator

*/
/* mapNav */
.map-nav {
  padding: 2rem 0; }

@media (min-width: 37.5em) and (max-width: 56.25em), (min-width: 56.25em) and (max-width: 75em) {
  .map-nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; } }

@media (min-width: 75em) {
  .map-nav {
    position: relative;
    padding: 0; } }

/* mapNav__Map */
.map-nav__map {
  display: none;
  min-width: 100%; }

@media (min-width: 75em) {
  .map-nav__map {
    display: block; } }

/* mapNav__Indicator */
.map-nav__indicator {
  display: flex;
  padding: 2rem 1rem; }
  .map-nav__indicator img,
  .map-nav__indicator span {
    pointer-events: none; }
  .map-nav__indicator img {
    display: inline-block;
    width: 125px;
    height: 125px;
    z-index: 1; }
  .map-nav__indicator span {
    display: inline-block;
    color: #ffffff;
    padding: 0.5rem 1rem;
    letter-spacing: 2px;
    font-size: 0.875rem;
    white-space: nowrap;
    z-index: 0;
    min-width: 150px;
    text-align: center; }
  .map-nav__indicator.capital-view span {
    background-color: #ac2299; }
  .map-nav__indicator.alexandria span {
    background-color: #21ab92; }
  .map-nav__indicator.national-landing span {
    background-color: #ffb829; }
  .map-nav__indicator.innovation-station span {
    background-color: #1d9bfc; }

@media (max-width: 37.5em) {
  .map-nav__indicator {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center; }
    .map-nav__indicator span {
      margin-left: -0.5rem; }
    .map-nav__indicator:nth-of-type(2n + 2) {
      flex-direction: row-reverse; }
      .map-nav__indicator:nth-of-type(2n + 2) span {
        margin-left: 0;
        margin-right: -0.5rem; } }

@media (min-width: 37.5em) and (max-width: 56.25em), (min-width: 56.25em) and (max-width: 75em) {
  .map-nav__indicator {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 33%; }
    .map-nav__indicator span {
      min-width: 200px;
      text-align: center;
      margin-top: 1rem; } }

@media (min-width: 75em) {
  .map-nav__indicator {
    position: absolute;
    justify-content: flex-start;
    align-items: center;
    transform: scale(0.75); }
    .map-nav__indicator,
    .map-nav__indicator img,
    .map-nav__indicator span {
      transition: all 300ms ease; }
    .map-nav__indicator img {
      border-radius: 50%; }
    .map-nav__indicator span {
      transform-origin: left center;
      transform: scale(0, 1); }
    .map-nav__indicator:hover {
      transform: scale(0.77); }
      .map-nav__indicator:hover img {
        box-shadow: -2px 5px 8px 2px rgba(0, 0, 0, 0.4); }
      .map-nav__indicator:hover span {
        transform: scale(1, 1); }
    .map-nav__indicator span {
      margin-left: -0.5rem; } }

@media (min-width: 75em) {
  .map-nav__indicator {
    transform: scale(0.9); }
    .map-nav__indicator:hover {
      transform: scale(1); }
    .map-nav__indicator.innovation-station {
      top: 5%;
      left: 5%; }
    .map-nav__indicator.capital-view {
      top: 43%;
      left: 68%; }
    .map-nav__indicator.national-landing {
      top: 59%;
      left: 65%; }
    .map-nav__indicator.alexandria {
      top: 75%;
      left: 62.5%; } }

@media (min-width: 90em) {
  .map-nav__indicator.innovation-station {
    top: 10%;
    left: 5%; }
  .map-nav__indicator.capital-view {
    top: 48%;
    left: 70%; }
  .map-nav__indicator.national-landing {
    top: 61%;
    left: 67%; }
  .map-nav__indicator.alexandria {
    top: 75%;
    left: 65%; } }

/*---
title: Fact List
section: Modules
---

Row of facts, as on the site detail page. Can be large (default) or small (as in
the connectivity section of the site detail page).

```example:html
<div class="fact-list --turquoise">
  <div class="fact-list__fact --third">
    <h2 class="title white">16,000</h2>
    <h3 class="title white">Residents</h3>
  </div>
  <div class="fact-list__fact --third">
    <h4 class="title white">Estimated average household income</h4>
    <h2 class="title white">$98,777</h2>
  </div>
  <div class="fact-list__fact --third">
    <h2 class="title white">11,000,000</h2>
    <h4 class="title white">square feed of office space</h4>
  </div>
</div>
```

*/
.fact-list__fact {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
  margin: 1rem 0;
  background-color: #f7f7f7; }
  .fact-list__fact:not(.\--small) {
    height: 200px;
    text-align: center; }
  .fact-list__fact.\--small {
    height: 100px; }
    .fact-list__fact.\--small h4 {
      font-size: 2.24rem;
      margin: 0;
      line-height: 0.8; }
    .fact-list__fact.\--small small {
      font-size: 1.225rem;
      font-weight: 100; }
  .fact-list__fact .title, .fact-list__fact .landing .interstitial .caption, .landing .interstitial .fact-list__fact .caption {
    max-width: 100%; }
  .fact-list__fact h2 {
    font-size: 3.24rem; }
  .fact-list__fact h4 {
    font-size: 1.68rem; }

@media (min-width: 37.5em) {
  .fact-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center; }
    .fact-list__fact {
      padding: 1rem;
      margin: 1rem;
      z-index: 10; }
      .fact-list__fact.\--third {
        flex-basis: 45%; }
        .fact-list__fact.\--third:last-of-type {
          flex-basis: 100%; }
      .fact-list__fact.\--half {
        flex-basis: 100%; }
        .fact-list__fact.\--half.\--small {
          flex-basis: 40%; }
      .fact-list__fact.\--layout-row {
        flex-direction: row;
        text-align: left; }
        .fact-list__fact.\--layout-row h1 {
          font-size: 6rem;
          line-height: 0.8;
          vertical-align: middle;
          margin-right: 1rem;
          margin-bottom: 0.5rem; }
        .fact-list__fact.\--layout-row h4 {
          max-width: 400px; } }

@media (min-width: 56.25em) {
  .fact-list {
    justify-content: flex-start;
    flex-wrap: nowrap; }
    .fact-list__fact {
      margin: 0.25rem; }
      .fact-list__fact.\--third {
        flex-basis: 30%;
        min-width: 30%; }
      .fact-list__fact.\--small {
        align-items: flex-start;
        max-width: 300px; } }

@media (min-width: 75em) {
  .fact-list__fact {
    padding: 1rem 2rem;
    margin: 0.5rem; }
    .fact-list__fact.\--layout-row h1 {
      font-size: 7.5rem; } }

/*---
title: Developer List
section: Modules
---

The list for showcasing developers, as on the site detail page.

```example:html
<ul class="developer-list">
  <li class="developer-list__item">
    <header>
      <img src=
      "/assets/images/clark-construction-800.png" alt="">
    </header>
    <footer>
      <h4 class="title black">Clark Construction Group</h4>
      <p class="description"> Cras mattis consectetur purus sit amet fermentum.
        Maecenas faucibus mollis interdum. Donec sed odio
        dui. Aenean lacinia bibendum nulla sed
        consectetur.</p>
    </footer>
  </li>
  <li class="developer-list__item">
    <header>
      <img src=
      "/assets/images/clark-construction-800.png" alt="">
    </header>
    <footer>
      <h4 class="title black">Clark Construction Group</h4>
      <p class="description"> Cras mattis consectetur purus sit amet fermentum.
        Maecenas faucibus mollis interdum. Donec sed odio
        dui. Aenean lacinia bibendum nulla sed
        consectetur.</p>
    </footer>
  </li>
</ul>
```

*/
.developer-list {
  margin: 0;
  padding: 0;
  margin-top: 2rem; }
  .developer-list__item {
    list-style-type: none; }
    .developer-list__item img {
      margin-bottom: 20px; }
      .developer-list__item img.small {
        width: 75%; }
  .developer-list .title, .developer-list .landing .interstitial .caption, .landing .interstitial .developer-list .caption {
    margin-bottom: 0;
    font-family: "utopia-std", serif; }
  .developer-list .description, .developer-list .landing__stat-section li, .landing__stat-section .developer-list li {
    margin-top: 0; }

@media (min-width: 56.25em) {
  .developer-list {
    margin-top: 4rem; } }

@media (max-width: 90em) {
  .developer-list img {
    margin-bottom: 1rem; }
    .developer-list img.small {
      width: auto; } }

@media (min-width: 90em) {
  .developer-list {
    margin-top: 6rem; }
    .developer-list__item {
      display: flex;
      flex-direction: row; }
      .developer-list__item header {
        flex: 1; }
      .developer-list__item footer {
        flex: 4; } }

/*---
title: Ribbon
section: Modules
---

A ribbon of content, generally justt a title, that spans the entire width of the page.

```example:html
<a class="ribbon --purple" href="#">
  <img class="icon" src="/assets/icons/icon_001.svg" alt="">
  <h4>Download the Crystal City Proposal</h4>
</a>
```

*/
.ribbon {
  display: block;
  text-align: center;
  padding: 1rem 0;
  background-color: #4b4b4b;
  color: #ffffff; }
  .ribbon h4 {
    margin: 0;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 4px; }

@media (min-width: 56.25em) {
  .ribbon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; }
    .ribbon img {
      margin: 0 1.5rem; } }

/*---
title: Image Grid
section: Modules
---

Grid of 3 images, as found on the site detail page.

```example:html
<div class="image-grid">
  <div class="image"></div>
  <div class="image"></div>
  <div class="image"></div>
</div>
```

*/
.image-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .image-grid .image {
    width: 100%;
    padding-top: 100%;
    background-position: center center;
    background-size: cover; }

@media (min-width: 37.5em) {
  .image-grid .image:first-of-type {
    width: 100%; }
  .image-grid .image:nth-of-type(2n), .image-grid .image:nth-of-type(3n) {
    padding-top: 50%;
    width: 50%;
    background-position: bottom; }
  .image-grid .image:nth-of-type(3n) {
    background-position: top; } }

@media (min-width: 56.25em) {
  .image-grid {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100vh;
    max-height: 100vh;
    min-height: 800px; }
    .image-grid .image:first-of-type {
      max-width: 50%;
      padding-top: 50%;
      height: 100%;
      max-height: 100%; }
    .image-grid .image:not(:first-of-type) {
      max-width: 50%;
      padding-top: 25%;
      height: 50%;
      max-height: 50%; } }

@media (min-width: 75em) {
  .image-grid .image:first-of-type {
    max-width: 65%;
    padding-top: 40%;
    max-height: 100%; }
  .image-grid .image:not(:first-of-type) {
    max-width: 35%;
    padding-top: 20%;
    max-height: 50%; } }

/*---
title: Quote
section: Module
---

*/
.quote {
  display: flex; }
  .quote .quote__image {
    margin: 0 50px;
    width: 290px; }
  .quote .quote__body {
    margin: 10px 10px 0 10px; }
  .quote blockquote {
    display: inline;
    width: auto;
    margin: 0;
    color: #ac2299; }
    .quote blockquote:before, .quote blockquote:after {
      position: absolute;
      font-size: 2em;
      color: #dedede; }
    .quote blockquote:before {
      top: -0.35em;
      left: -0.45em; }
    .quote blockquote:after {
      bottom: -0.35em;
      right: -0.45em;
      -ms-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg); }
  .quote .attribution {
    font-family: "roboto-mono", monospace; }
    .quote .attribution:before {
      content: '';
      background-color: #ebebeb;
      margin: .75em 12px 0 0;
      float: left;
      width: 25px;
      height: 1px; }

@media (min-width: 56.25em) {
  .quote .quote__body {
    margin: 3% 36px 0 36px; }
  .quote .attribution:before {
    content: '';
    width: 116px; } }

/*---
title: Subsection Header
section: Modules
---

*/
.subsection-header {
  height: 260px;
  position: relative; }
  .subsection-header .suptitle {
    margin-top: 75px;
    color: white; }
    .subsection-header .suptitle:after {
      background-color: #891b7a;
      width: 75%;
      left: 4em; }
  .subsection-header .header-primary-bg {
    width: 428px;
    height: 240px;
    position: absolute;
    top: 20px;
    left: 0;
    fill: #ac2299;
    z-index: -1; }
  .subsection-header .header-secondary-bg {
    background-size: cover;
    width: 966.4px;
    height: 240px;
    position: absolute;
    top: 0;
    left: 200px;
    fill: white;
    z-index: -2; }

@media (min-width: 56.25em) {
  .subsection-header {
    height: 320px; }
    .subsection-header .header-primary-bg {
      width: 535px;
      height: 300px;
      position: absolute;
      top: 20px;
      left: 0;
      fill: #ac2299;
      z-index: -1; }
    .subsection-header .header-secondary-bg {
      width: 1208px;
      height: 300px;
      position: absolute;
      top: 0;
      left: 230px;
      fill: white;
      z-index: -2; } }

/*---
title: Talent Page Hero
section: Modules
---

```example:html
```

*/
.talent__hero {
  display: flex;
  flex-direction: column; }
  .talent__hero-content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: auto;
    width: 100%;
    min-height: 80vh; }
    .talent__hero-content:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("./images/talent-hero-1000.png");
      background-position: bottom right;
      background-size: cover;
      background-repeat: no-repeat;
      z-index: -1; }
  .talent__hero .title, .talent__hero .landing .interstitial .caption, .landing .interstitial .talent__hero .caption,
  .talent__hero .subtitle {
    width: 50%;
    max-width: 450px; }
  .talent__hero .title, .talent__hero .landing .interstitial .caption, .landing .interstitial .talent__hero .caption {
    font-size: 2rem; }
  .talent__hero .subtitle {
    font-size: 1.1rem;
    font-family: "utopia-std", serif;
    font-weight: 100;
    text-transform: none;
    line-height: 1.8em;
    -webkit-transform: none;
    transform: none; }

@media (min-width: 56.25em) {
  .talent__hero-content {
    z-index: 5; }
    .talent__hero-content:after {
      height: 125%;
      background-color: transparent; } }

/*---
title: Blockquote
section: Modules
---

A blockquote element, like the one found at the bottom of the site detail page. It includes two variants for long and extra-long quotes that reduce the font size and positioning of the citation.

```example:html
<blockquote class="blockquote">
  Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum
  nibh, ut fermentum massa justo sit amet risus.
  <cite>Jeffery Martin</cite>
</blockquote>
```

*/
.blockquote {
  position: relative;
  width: 80%;
  margin: 1rem auto 10rem;
  font-size: 1.4rem; }
  @media (min-width: 56.25em) {
    .blockquote {
      margin: 0 auto 4rem; } }
  .blockquote.\--large {
    font-size: 1.26rem; }
    .blockquote.\--large cite {
      top: 120%;
      font-size: 1rem; }
  .blockquote.\--xl-large {
    font-size: 0.84rem; }
    @media (min-width: 56.25em) {
      .blockquote.\--xl-large {
        font-size: 1.12rem; } }
    .blockquote.\--xl-large cite {
      top: 108%;
      font-size: .8rem;
      max-width: 100%;
      text-align: left; }
      @media (min-width: 56.25em) {
        .blockquote.\--xl-large cite {
          top: 120%;
          font-size: 1rem;
          max-width: 60%; } }
  .blockquote cite {
    top: 110%;
    left: unset;
    bottom: unset;
    right: 0;
    max-width: 60%; }
  .blockquote:before, .blockquote:after {
    content: '';
    position: absolute;
    height: 2rem;
    width: 2rem;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat; }
  .blockquote:before {
    top: -0.5rem;
    left: -3rem;
    background-image: url("icons/quote.png"); }
  .blockquote:after {
    bottom: -0.5rem;
    right: -2.5rem;
    background-image: url("icons/quote-reverse.png"); }
  .blockquote__image {
    display: none; }
    @media (min-width: 56.25em) {
      .blockquote__image {
        margin: 0 1.5rem 0 0;
        display: inline; } }

@media (min-width: 37.5em) {
  .blockquote {
    font-size: 1.4rem;
    max-width: 600px; } }

@media (min-width: 56.25em) {
  .blockquote {
    font-size: 1.4rem; }
    .blockquote.\--wide {
      max-width: 70rem; } }

@media (min-width: 75em) {
  .blockquote {
    font-size: 2.24rem; }
    .blockquote cite {
      font-size: 1.4rem; } }

img.placeholder {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  background-color: #efefef; }
