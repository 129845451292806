.play-button {
  display: block;
  width: 3rem;
  height: 3rem;
  border-width: 2rem;
  border-style: solid;
  border-color: transparent;
  border-left-color:  $copy__white;
  border-left-width: 3rem;
  margin: 1rem 0;
  cursor: pointer;
}

.call-to-action {
  display: inline-block;
  padding: 0.5rem 1.5rem;
  text-decoration: none;
  text-transform: uppercase;
  background-color: $copy__turquoise;
  color: $copy__white;
  letter-spacing: 2px;
  font-weight: 200;
}
