/*---
title: Page Section
section: Layout
---



```example:html

```

ToC:


*/
body.landing{
  @media #{$laptop-up} {
    font-size: 20px;
  }
}

body.home {
  overflow-x: hidden;
}


  /**************
  * HERO
  **************/

.landing {

	&__hero{
  	position: relative;
  	background-image: url('./images/talent-hero-1000.png');
    background-position: top right;
    background-size: 100%;
    background-repeat: no-repeat;
    
    /* HERE */
    @media #{$up-to-tablet} {
      padding: 9rem 0;
    }

    @media #{$tablet-up} {
      height: 120vh; 
      background-size: cover;
      
    }

    @media #{$laptop-up} {
    	height: 135vh;
      background-size: cover;
    }

    @media #{$desktop-up} {
      height: 140vh;
    }

  	&__content{
      
      /* HERE */

      

      @media #{$up-to-tablet} {
        background-color: $turquoise;
        margin-top: 7rem;
        margin-bottom: -7rem;
        padding: 0 2rem 1rem;
        min-height: 13rem; 
      }
  		
      @media #{$tablet-up} {
        height: 95vh;
        padding: 5%;
        padding-bottom: 15vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      
      /* HERE */
    	.title {
        color: $white;
        font-size: 2.5rem;
        width: 100%;

        @media #{$up-to-tablet} {
          padding-top: 1rem;
        }

        @media #{$tablet-up} {
          width: 60vw;
          font-size: 2.7rem;
        }

        @media #{$laptop-up} {
    		  margin-bottom: 2vh;
          width: 40vw;
          color: $turquoise;
    		}
    	}

    	.subtitle{
    		font-family: $font__body;
        
        @media #{$tablet-up} {
          font-size: 1.4rem;
          text-transform: none;
          width: 55vw;
          letter-spacing: 1px;
          transform: none;
  			}
        @media #{$laptop-up} {
          width: 38vw;
        }
      }
    }
	}
  .landing__hero__content:before {
    /* HERE */
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 100px 0px 0px 375px;
    border-color: transparent transparent transparent $turquoise;

    position: absolute;
    top: 157px;
    right: 0;
    width: 100%;
    
    @media #{$phablet-only} {
      border-width: 100px 0px 0px 775px;
    }
    @media #{$tablet-up} {
      display: none;
    }
  }
  /* HERE */
  .landing__hero__content:after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 100px 0 0 375px;
    border-color: transparent transparent transparent $turquoise;
    transform: rotate(180deg);

    position: absolute;
    bottom: -67px;
    right: 0;
    width: 100%;
    
    @media #{$phablet-only} {
      border-width: 100px 0px 0px 775px;
    }
    @media #{$tablet-up} {
      display: none;
    }
  }


  /******************
  * UTILITIES
  ******************/

  .after-hero{
    @media #{$phablet-up} {
      margin-top: -6vh;
    }
    @media #{$tablet-up} {
      margin-top: -25vh;
      position: relative;
      z-index: 5;
      overflow: auto;
      @media #{$laptop-up} {
        margin-top: -35vh;
      }

      h2{
        margin-top: 10vh;
      }
    }
  }
  
  .interlocking-section{
    z-index: 10;
  }

  .page-section{
    padding: 0 5%;

    @media #{$tablet-up} {
      padding: 0;
    }
  }

  .pull-section{
    @media #{$laptop-up} {
      margin-top: -6vh;
    }
    @media #{$desktop-up} {
      margin-top: -4vh;
    }
  }

  .center-content{
      display: flex;
      flex-direction: column;
      justify-content: center;

    @media #{$tablet-up} {
      width: 80%;
      margin: 5% auto;

    }
  }

  .center-content-top{
    @extend .center-content;
    margin-top: 0;
    padding-top: 0;
  }

  .centered{
    @media #{$tablet-up} {
      text-align: center;
    }
  }

  .stacked-images{
    figure{
      margin: 0 0 5%;
    }

    & figure:last-child{
      margin-bottom: 0;
    }
  }

.wide-content__left{
    margin: 5%;
    @media #{$tablet-up} {
      width: 60vw;
    }
}
  .spacer{
    @media #{$tablet-up} {
      margin-bottom: 5%;
    }
  }

  .mobile-spacer{
    padding: 5% 5% 0 5%;

    @media #{$tablet-up} {
      padding: 0;
    }
  }

  img{
    margin: 0;
    width: 100%;
    height: auto;
  }

  .suptitle{
    @extend h5;
    max-width: 70vw;
    @media #{$tablet-up} {
      margin-bottom: 5%;
      max-width: 24vw;
    }
    @media #{$laptop-up} {
      max-width: 22vw;
    }
  }

  .wide-text{
    @media #{$tablet-up} {
      max-width: 70%;
    }
  }

  p{
    margin-bottom: 1rem;
  }

  p, li{
    font-family: $font__body;

    @media #{$tablet-up} {

      font-size: 1.2rem;
      line-height: 1.8;
    }
  }

  li{
    @media #{$tablet-up} {
      line-height: 1.5;
    }
  }

  ul{
        margin: .5rem 0;

        li{

          margin-bottom: .5rem;

        }
      }

  /******************
  * COLUMNS
  ******************/
  .row {
    @media #{$tablet-up} {
      display: flex;
    }
    .col-10{
      @media #{$tablet-up} {
        width: 8%;
        margin: 0 auto;
      }
    }
    .col-20{
      @media #{$tablet-up} {
        width: 18%;
        margin: 0 auto;
      }
    }

    .col-30{
      @media #{$tablet-up} {
        width: 28%;
        margin: 0 auto;
      }
    }

    .col-40{
      @media #{$tablet-up} {
        width: 38%;
        margin: 0 auto;
      }
    }
    .col-50{
      @media #{$tablet-up} {
        width: 48%;
        margin: 0 auto;
      }
    }

    .col-60{
      @media #{$tablet-up} {
        width: 58%;
        margin: 0 auto;
      }
    }
    .col-80{
      @media #{$tablet-up} {
        width: 68%;
        margin: 0 auto;
      }
    }

    .col-80{
      @media #{$tablet-up} {
        width: 78%;
        margin: 0 auto;
      }
    }
    .col-90{
      @media #{$tablet-up} {
        width: 88%;
        margin: 0 auto;
      }
    }
    &.\--center-col{
    justify-content: center;
    }
  }
  .full-width-left{

    & > div:last-child{
      @media #{$tablet-up} {
        margin-right: 0;
        padding-right: 0;
      }
    }
  }
  .full-width-right{

    & > div:first-child{
      @media #{$tablet-up} {
        margin-left: 0;
        padding-left: 0;
      }
    }
  }
  .aligner{
        display: flex;
        align-items: center;
        justify-content: center;
      }

  /******************
  * FEATURE W/ ANGLES
  ******************/
  &__feature{
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media #{$tablet-up}{
      min-height: 115vh;
    }
    &__content{
      padding: 10% 5% 10% 5%;
      @media #{$tablet-up}{
        width: 45%;
      }
      @media #{$desktop-up}{
        padding: 0% 5% 10% 10%;
      }

      .circle-graph{
        margin: 5% auto;
        background: none;
        @media #{$tablet-up}{
          margin-left: 2rem;
        }
      }
    }
    @media #{$tablet-up}{
      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;
        height: 100%;

        background-image: url('./images/talent-labor-1000.png');
        background-position: center left;
        background-size: cover;
        background-repeat: no-repeat;
        z-index: -1;
      }
    }
  }
  .data-point,
  .data-description{
    font-family: $font__heading;
    font-weight: 100;
    font-size: .9rem;
  }

  .data-point{
    font-size: 3rem;
  }

  .animate-graph {
    stroke-dasharray: 630px;
    stroke-dashoffset: 630px;
    transform-origin: 100px 100px;
    transform: rotate(-90deg);
  }


  /******************
  * STAT LIST W/ TEXT
  ******************/

  &__stat-section{
    list-style-type: none;
    margin: 5% 0;
    padding: 0;

    @media #{$tablet-up} {
      margin-bottom: 0;
    }

    li{
      @extend .description;
      line-height: 1.3;
      margin: 0 0 1rem;

      @media #{$tablet-up} {
        line-height: 1.3;
        max-width: 76%;
        text-align: left;
        margin: 0 0 2vh 0;
      }
    }

    &__label{
      font-size: 2rem;
      font-family: $font__heading;
      line-height: 1.4;
      display: block;
    }
  }

  /******************
  * INTERSTITIAL
  ******************/
  .interstitial{
    position: relative;
    @media #{$laptop-up} {
      height: 90vh;
      overflow: hidden;
    }
    &.special {
      @media #{$laptop-up} {
        height: auto;
      }
    }
    img{
      width: 100%;
      height: auto;
      display: block;
    }
    .caption{
      @extend h3;
      @extend .title;
      position: absolute;
      width: 50vw;
      bottom: 3vh;
      right: 8vw;

      @media #{$laptop-up} {
        bottom: 30vh;
      }

      @media #{$up-to-laptop} {
        font-size: 4vw;
      }

      @media #{$laptop-up} {
        width: 40vw;
        bottom: 20vh;
        right: 18vw;
      }
    }
  }

  /******************
  * TEXT AND STAT
  ******************/
  &__text-stat{
    margin: 5% 0 0 5%;

    h4{
      margin-bottom: 0;
      line-height: 1;
    }
    p{
      margin: 1vh 0 8vh;

      @media #{$tablet-up} {
        margin: 5% 0 12vh;
      }
    }
  }

  /******************
  * STAT LIST
  ******************/
  &__stat-list{
    font-family: $font__heading;

    &__header{
      border-bottom: 2px solid $turquoise;
      display: flex;

      h3{
        font-size: 1.2rem;
        font-weight: 100;
        align-self: flex-end;
      }

      .icon{
        margin-left: 0;
        height: 4rem;
        width: 6rem;
      }

      h3,
      .icon{
        margin-bottom: .5rem;
      }
    }

    &__list{
      list-style-type: none;
      margin: 1% 5% 5%;
      padding: 0;

      li{
        font-family: $font__heading;
        font-size: 1rem;
        font-weight: 100;
        @media #{$laptop-up} {
          font-size: 1.5rem;
        }

      }

      &__stat{
        font-size: 2rem;
        @media #{$laptop-up} {
          font-size: 3rem;
        }
      }
    }

    &__left{
      position: relative;
      @media #{$tablet-up} {
        width: 45vw;
        position: relative;
        top: -5vh;
      }

      h3{
        width: 100%;
        text-align: right;
        margin-bottom: 0;
        position: relative;
        bottom: -.3rem;
        font-family: $font__main;
      }
      ul{
        margin-left: 6vw;
      }
      li{
        line-height: 1.5;
      }
    }
  }


  /******************
  * QUOTE SQUARED
  ******************/
  &__quote-squared{
    position: relative;
    z-index: 1;

    p{
      margin: 3% auto 5%;
      font-family: $font__heading;
      font-weight: 100;
      text-align: center;
      font-size: 1.2rem;
      max-width: 95%;
      position: relative;
      background: $white;
      padding: 3% 5%;

      @media #{$tablet-up} {
        font-size: 2rem;
        max-width: 75%;
      }

      &:before{
        content: '';
        position: absolute;
        top: -3px;
        left: -3px;
        width: 25px;
        height: 25px;
        z-index: -1;
        background: $copy__gold;
        @media #{$tablet-up} {
          width: 50px;
          height: 50px;
        }
      }
      &:after{
        content: '';
        position: absolute;
        bottom: -3px;
        right: -3px;
        width: 25px;
        height: 25px;
        background: $copy__gold;
        z-index: -1;
        @media #{$tablet-up} {
          width: 50px;
          height: 50px;
        }
      }
    }
  }

  .quote-wide{
    p{
      @media #{$tablet-up} {
        max-width: 90%;
        font-size: 1.5rem;
      }
    }
  }

  /******************
  * CALLOUT SECTION
  ******************/
  &__callout {
    background: #f6f6f6;
    padding: 5%;
    min-height: 70vh;
  }

  /******************
  * FACT SECTION
  ******************/
  &__fact-section{

    overflow: hidden;
    @media #{$up-to-tablet}{
      padding-bottom: 5%;
    }

    @media #{$tablet-up} {
      margin: 0 0 2rem;
    }

    p{
      font-family: $font__heading;
      font-weight: 100;
      position: relative;
      z-index: 10;
      font-size: 1.3rem;
      width: 65%;
      left: 25%;
      line-height: 1.2;

      @media #{$tablet-up} {
        font-size: 2.3rem;
        line-height:1.35;
        width: 45%;
        left: 35%;
      }

      &:before{
        content: '';
        position: absolute;
        top: 18px;
        right: -25%;
        width: 125%;
        height: 3px;
        background: $copy__gold;
        z-index: -1;

        @media #{$tablet-up} {
          top: 38px;
          right: -45%;
          width: 145%;
          height: 5px;
        }
      }
    }
  }

  /******************
  * SUBSECTION HEADER
  ******************/
  &__subsection__header{
    margin-bottom: 5%;
    overflow: hidden;

    @media #{$tablet-up} {
      margin-bottom: 16vh;

    }


  }

  /******************
  * MAGAZINE LAYOUT
  ******************/
  &__magazine-layout{
    overflow: hidden;
    margin: 5%;
    @media #{$tablet-up} {
      margin: 5% 0;
    }

    &__header{
      line-height: 1;
      margin: 0 0 5% 0;

      &__kicker{
        position: relative;
        z-index: 20;
        font-weight: 100;
        font-size: 4rem;
        display: block;

        &:after{
        content: '';
        position: absolute;
        top: 55px;
        left: -29%;
        width: 130%;
        height: 4px;
        background: $copy__gold;
        z-index: -1;
      }
      }
    }
    &__header,
    p{
      font-family: $font__main;
    }
    p{
      font-size: 1rem;
      font-weight: 100;
    }
  }
  /******************
  * Companies
  ******************/
  &__companies{
    &__header{
      display: flex;
      border-bottom: 4px solid $turquoise;

      @media #{$phablet-up} {
        width: 28rem;
      }


      &__company-num{
        display: block;
        font-size: 6rem;
        line-height: 1;
        margin-right: .25rem;
        position: relative;
        top: 5px;
      }
      &__text{
        display: block;
        font-size: 1.5rem;
        align-self: flex-end;
        position: relative;
        top: 3px;
      }
    }
  }
  /******************
  * CHARTS & GRAPHS
  ******************/
  &__graphs{
    .graph {
      margin: auto;
      display: block;

      @media #{$up-to-phablet}{
        margin-top: 5%;
      }

      img {
        padding-top: 5%;

        @media #{$up-to-laptop}{
          padding-top: 1.5rem;
        }

        @media #{$phone-only}{
          padding-top: 4.5rem;
        }
      }

      figcaption {
        background: transparent;
        padding: 0 0 0 12px;
        border-left: 6px solid $turquoise;
        width: 65%;
        left: 0;
        font-size: .7rem;
        letter-spacing: normal;
        text-transform: none;

        font-weight: 100;
        @media #{$phablet-up}{
          font-size: .9rem;
        }
        @media #{$laptop-up}{
          left: 0;
          font-size: 1.1rem;
        }

      }
    }
  }
  .image-title-callout{
    overflow: hidden;
  }

  /******************
  * HEADER INTERSTITIAL
  ******************/
  .header-interstitial{
    display: flex;
    margin-bottom: 5%;


    .header-primary,
    .header-secondary{

        height: 10rem;

      @media #{$tablet-up}{
        height: 15.5rem;
      }

    }

    .header-primary{
      width: 40%;
      fill: #ac2299;
      background: #ac2299;
      position: relative;
      z-index: 20;
      top: 2.5vh;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media #{$tablet-up}{
        width: 20%;
      }

      &::after{
        content: '';
        width: 0;
        height: 0;
        border-bottom: 7.1rem solid transparent;
        border-top: 7.2rem solid transparent;
        border-left: 7rem solid $purple;
        font-size: 0;
        line-height: 0;
        position: absolute;
        top: -4.6rem;
        right: -5.9rem;
        transform: translateZ(1px) rotate(-135deg);
        z-index: -1;

        @media #{$tablet-up}{
          border-bottom: 11.1rem solid transparent;
          border-top: 10.9rem solid transparent;
          border-left: 11rem solid $purple;
          top: -7.2rem;
          right: -9.37rem;
        }
      }


      .suptitle{
        z-index: 20;
        color: $white;
        max-width: 14rem;
        margin: 0 auto;
        position: relative;
        left: 1rem;
        bottom: 1rem;
        font-size: .8rem;


        @media #{$tablet-up}{
          left: 2.5rem;
          bottom: 2rem;
        }
        @media #{$laptop-up}{
          left: 20%;
          font-size: 1rem;
        }

      }
      .suptitle:after {
        background-color: #891b7a;
      }
    }
    .header-secondary{
      width: 60%;
      position: relative;
      z-index: 5;

      @media #{$tablet-up}{
        width: 80%;;
      }
    }
  }
}



#talent .landing__hero{
  background-image: url('./images/talent-hero_edited-1400.png');
}

#connectivity .landing__hero{
  background-image: url('./images/connectivity-hero-updated-1400.png');
}

#businessclimate .landing__hero{
  background-image: url('./images/hero-businessclimate-1400.png');
}

#livability .landing__hero{
  background-image: url('./images/hero-livability-1400.png');
}

#livability .landing__feature:after{
  background-image: url('./images/hero-secondary-livability-1400.png');
}

#connectivity .multimodal{
  background: no-repeat url(./images/businessclimate-header-hub-1400.png);
  background-size: cover;
  background-position: bottom center;
}

#businessclimate .businesshub{
  background: no-repeat url(./images/connectivity-header-multimodal-1400.png);
  background-size: cover;
  background-position: bottom center;
}

#talent .education{
  background: no-repeat url(./images/talent-header-education2-1400.png);
  background-size: cover;
  background-position: bottom center;
}

#talent .future-talent{
  background: no-repeat url(./images/talent-header-future-1400.png);
  background-size: cover;
  background-position: bottom center;
}

.row.row__graphs {
  display: flex;
}

#connectivity .after-hero,
#businessclimate .after-hero{
    @media #{$up-to-tablet} {
      margin-top: 1rem;
    }
  }

