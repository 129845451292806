/*---
title: Blockquote
section: Modules
---

A blockquote element, like the one found at the bottom of the site detail page. It includes two variants for long and extra-long quotes that reduce the font size and positioning of the citation.

```example:html
<blockquote class="blockquote">
  Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum
  nibh, ut fermentum massa justo sit amet risus.
  <cite>Jeffery Martin</cite>
</blockquote>
```

*/
.blockquote {
  position: relative;
  width: 80%;
  margin: 1rem auto 10rem;
  font-size: $h4;

  @media #{$tablet-up} {
    margin: 0 auto 4rem;
  }

  &.\--large {
    font-size: $h4 * 0.9;

    cite {
      top: 120%;
      font-size: $h5;
    }
  }

  &.\--xl-large {
    font-size: $h4 * 0.6;
    @media #{$tablet-up} {
      font-size: $h4 * 0.8;
    }

    cite {
      top: 108%;
      font-size: .8rem;
      max-width: 100%;
      text-align: left;
      @media #{$tablet-up} {
        top: 120%;
        font-size: $h5;
        max-width: 60%;
      }
    }
  }

  cite {
    top: 110%;
    left: unset;
    bottom: unset;
    right: 0;
    max-width: 60%;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    height: 2rem;
    width: 2rem;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  &:before {
    top: -0.5rem;
    left: -3rem;
    background-image: url('icons/quote.png');
  }

  &:after {
    bottom: -0.5rem;
    right: -2.5rem;
    background-image: url('icons/quote-reverse.png');
  }

  &__image{
    display: none;

    @media #{$tablet-up} {
      margin: 0 1.5rem 0 0;
      display: inline;
    }
  }
}

@media #{$phablet-up} {
  .blockquote {
    font-size: $h4;
    max-width: 600px;
  }
}

@media #{$tablet-up} {
  .blockquote {
    font-size: $h4;

    &.\--wide {
      max-width: 70rem;
    }
  }
}

@media #{$laptop-up} {
  .blockquote {
    font-size: $h4 * 1.6;

    cite {
      font-size: $h4;
    }
  }
}
