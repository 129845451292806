/*---
title: Properties Section
section: Layout
---



```example:html

```

*/
.sites-section {
  padding-bottom: 0;
  
  .property-lockup{
    padding: 2rem 0 0 0;
    background: $background__lightgrey;
    @media #{$tablet-up}{
      flex-direction: row-reverse;
      display: flex;
      justify-content: center;
    }

  }

  .map-properties {
    width: 100%;
    @media #{$tablet-up}{
      width: 60%;
      margin: auto;
    }

    img {
      display: block;
      margin: 0 auto;
    }
  }
  .property-list{
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 1rem 0;
    flex-wrap: wrap;

    /*@media #{$tablet-up}{
      width: 40%;
    }*/
    
    .property {
     /* width: 50%;*/
      margin: .5rem auto;
    }

    img,
    span{
      display: block;
      margin: 0 auto;
    }

    span{
      color: $purple;
      text-align: center;
      margin-top: 2.5rem;
      margin-bottom: 2rem;
    }

    img{
      border-radius: 50%;
      width: 300px;
      height: 300px;
      transition: all .8s ease-in-out;

    }

    a:hover img {
      transform: scale(1.1);
    }
  }
}