/*---
title: Connectivity Section
section: Layout
---



```example:html

```

*/
.connectivity-section {
  position: relative;
  padding: 6rem 0 0;

  &:after {
    content: '';
    display: none;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 50%;
    height: 100%;
    background-image: url('images/dots-connectivity-top-1000.png');
    background-position: center right;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
  }

  .title {
    margin-bottom: 2rem;
  }

  .suptitle {
    margin-bottom: 0.3rem;
  }
  .suptitle:after {
    width: 100%;
    left: 25%;
    height: 1rem;
  }

  .figure-row {
    border-top: 1px solid $background__black;
  }

  .figure-row__item {
    border-bottom: 1px solid $background__black;
    padding: 1.5rem 0;

    span {
      font-size: 200%;
      display: block;
    }
  }

  .col:first-of-type {
    margin-bottom: 4rem;
  }

  .image-figure {
    position: absolute;
    left: 0;
    bottom: -4.5rem;
    display: none;
    width: 50%;
    max-width: 700px;
    overflow: hidden;

    img {
      transform: scale(1.2);
      transition: all 300ms ease;
      opacity: 0;
      width: 100%;
    }
  }
}

@media #{$tablet-only} {
  .connectivity-section {
    .figure-row {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: stretch;

      &__item {
        flex-direction: column;
        align-items: center;
        width: 33%;
        border: none;

        &:not(:last-of-type) {
          border-right: 1px solid $black;
        }
      }
    }
  }
}

@media #{$laptop-up} {
  .connectivity-section {
    height: 90vh;
    min-height: 800px;
    max-height: 900px;

    &:after {
      display: block;
    }

    .container {
      max-width: 1440px;
    }

    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .col {
      flex: 1;

      &:last-of-type {
        position: absolute;
        right: 0;
        width: 40%;
        margin-top: 8rem;
      }
    }

    .image-figure {
      display: block;

      &.in-view img {
        transform: scale(1);
        opacity: 1;
      }
    }
  }
}

@media #{$desktop-up} {
  .connectivity-section {
    &:after {
      width: 100%;
    }

    .col:first-of-type {
      max-width: 700px;
    }

    .image-figure {
      bottom: -2rem;
    }
  }
}
