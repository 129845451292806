/*---
title: Talent Future Section
section: Layout
---

*/

.talent__body {
  .description {
    font-size: 1.15rem;
    font-weight: 100;
  }
}