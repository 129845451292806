/*---
title: Quote
section: Module
---

*/

.quote {
  display: flex;

  .quote__image {
    margin: 0 50px;
    width: 290px;
  }

  .quote__body {
    margin: 10px 10px 0 10px;
  }

  blockquote {
    display: inline;
    width: auto;
    margin: 0;
    color: $purple;

    &:before,
    &:after {
      position: absolute;
      font-size: 2em;
      color: $dkgrey;
    }

    &:before {
      top: -0.35em;
      left: -0.45em;
    }

    &:after {
      bottom: -0.35em;
      right: -0.45em;

      -ms-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }

  .attribution {
    font-family: $font__main;

    &:before {
      content: '';
      background-color: $grey;
      margin: .75em 12px 0 0;
      float: left;
      width: 25px;
      height: 1px;
    }
  }
}

@media #{$tablet-up} {
  .quote {
    .quote__body {
      margin: 3% 36px 0 36px;
    }

    .attribution {
      &:before {
        content: '';
        width: 116px;
      }
    }
  }
}