/*
 * National Landing
 *
 * These styles are specific to the National Landing site detail page. Most styling
 * for this page should be in _site-detail.scss, not in here.
 */
.national-landing {
  .site-detail__hero {
    &-image {
      background-image: url('images/map-hero-nationallanding-1400.jpg');

      &:first-of-type {
        background-image: url('images/Mets-and-Pen-1400.jpg');
      }
    }

    &-header:after {
      background-color: $yellow;
    }
  }

  .site-detail__intro-image {
    &:first-of-type {
      background-image: url('images/1550-Crystal-Drive-1400.jpg');
    }

    &:last-of-type {
      background-image: url('images/1770-Crystal-Drive-1400.jpg');
    }
  }

  .site-detail__connectivity {
    .fact-list__fact {
      background-color: $yellow;
    }
  }

  .image {
    &:first-of-type {
      background-image: url('images/atthemovies_1400x1058-1400.jpg');
    }


    &:nth-of-type(2n) {
      background-image: url('images/Pen-Place-Courtyard-Night-1400.jpg');
    }

    &:nth-of-type(3n) {
      background-image: url('images/NatLanding_Grid3-1400.jpg');
    }
  }
}


/****
* Image caption
****/

.national-landing{
  .image-figure{
    display: block;
  }

  .image-with-caption{
    position: relative;

    .image-figure{
      display: block;
      position: absolute;
      top: 0;
      right: 0;
    }
}
}
