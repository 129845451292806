/*---
title: Logo Group
section: Modules
---

List of logos with a heading figure and caption, as found in the business climate section of the homepage.

```example:html

<div class="logo-group">
  <header data-figure="32" data-caption="Fortune 500 companies call NOVA
    home">
    <h2 class="title purple">32</h2>
    <h3 class="title turquoise">Fortune 500 companies call NOVA home</h3>
  </header>
  <div class="logo-group__logos">
    <div class="logo-group__logo">
      <img src="assets/images/logos/aes-1000.png" alt="">
    </div>
    <div class="logo-group__logo">
      <img src="assets/images/logos/airbusgroup-1000.png" alt="">
    </div>
    <div class="logo-group__logo">
      <img src="assets/images/logos/airbusgroup-1000.png" alt="">
    </div>
    <div class="logo-group__logo">
      <img src="assets/images/logos/airbusgroup-1000.png" alt="">
    </div>
  </div>
</div>
```

*/
.logo-group {
 
  header {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    position: relative;
    margin-bottom: 2rem;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0.5rem;
      background-color: $turquoise;
    }
  }

  h2 {
    font-size: 500%;
    line-height: 1;
    margin-bottom: 0.3rem;
  }

  h3 {
    margin-left: 1rem;
    margin-bottom: 0.5rem;
    max-width: 300px;
    font-size: $h4 * 1.1;
  }

  &__logos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    @media #{$up-to-tablet} {
      justify-content: space-around;
    }

  }

  &__logo {
    position: relative;
    /* display: flex; */
    width: 50%;
    max-width: 200px;
    height: 150px;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% - 2rem);
    height: auto;
    transform: translate(-50%, -50%);
  }
}

@media #{$up-to-tablet} {
  .logo-group {

    h3 {
      font-size: 1rem;
    }
  }
}

@media #{$tablet-up} {
  .logo-group {
    padding-bottom: 4rem;

    h2 {
      font-size: 750%;
    }
    &__logo {
      width: 33%;
    }

    /*
 *     header {
 *       display: none;
 *     }
 *
 *     &:first-of-type {
 *       display: block;
 *
 *       header {
 *         display: flex;
 *
 *         &.is-fixed {
 *           position: fixed;
 *           top: 4rem;
 *         }
 *       }
 *     }
 */
  }
}
