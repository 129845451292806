/*---
title: Presented Section
section: Layout
---



```example:html

```

*/
.presented-section {
  position: relative;
  /*padding: 0rem 0 6rem;*/
  /*background-color: $background__black;*/
  background-color: $white;
  @media #{$tablet-up}{
    /*padding: 0rem 0 3rem;*/
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50%;
    background-size: cover;
    z-index: -1;
    display: none;
  }

  /*&:before {
    left: 0;
    background-image: url('images/dots-footer-left-1000.png');
    background-position: left center;
  }

  &:after {
    right: 0;
    background-image: url('images/dots-footer-right-1000.png');
    background-position: right center;
  }*/
  
  .disclaimer {
    background-color: $background__lightgrey;
    .description {
      padding: 2rem 1rem;
      text-align: left;
      margin: auto;
      @media #{$tablet-up} {
        width: 75%;
        padding: 2rem 2rem;
      }
    }
  }
  .disclaimer.bid {
    background: $background__darkgrey;
    color: white;
  }
  .ribbon {
    border-bottom: 1px solid $background__lightgrey;
  }
}

@media #{$tablet-up} {
  .presented-section {
    background-color: transparent;
    &:before,
    &:after {
      display: block;
    }
  }
}

