/*---
title: Fact List
section: Modules
---

Row of facts, as on the site detail page. Can be large (default) or small (as in
the connectivity section of the site detail page).

```example:html
<div class="fact-list --turquoise">
  <div class="fact-list__fact --third">
    <h2 class="title white">16,000</h2>
    <h3 class="title white">Residents</h3>
  </div>
  <div class="fact-list__fact --third">
    <h4 class="title white">Estimated average household income</h4>
    <h2 class="title white">$98,777</h2>
  </div>
  <div class="fact-list__fact --third">
    <h2 class="title white">11,000,000</h2>
    <h4 class="title white">square feed of office space</h4>
  </div>
</div>
```

*/
.fact-list {
  &__fact {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;
    margin: 1rem 0;
    background-color: $background__lightgrey;

    &:not(.\--small) {
      height: 200px;
      text-align: center;
    }

    &.\--small {
      height: 100px;

      h4 {
        font-size: $h4 * 1.6;
        margin: 0;
        line-height: 0.8;
      }

      small {
        font-size: $small * 1.4;
        font-weight: 100;
      }
    }

    .title {
      max-width: 100%;
    }

    h2 {
      font-size: $h3 * 1.2;
    }

    h4 {
      font-size: $h4 * 1.2;
    }
  }
}

@media #{$phablet-up} {
  .fact-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    &__fact {
      padding: 1rem;
      margin: 1rem;
      z-index: 10;

      &.\--third {
        flex-basis: 45%;

        &:last-of-type {
          flex-basis: 100%;
        }
      }

      &.\--half {
        flex-basis: 100%;

        &.\--small {
          flex-basis: 40%;
        }
      }
      &.\--layout-row {
        flex-direction: row;
        text-align: left;

        h1 {
          font-size: $h1 * 1.2;
          line-height: 0.8;
          vertical-align: middle;
          margin-right: 1rem;
          margin-bottom: 0.5rem;
        }

        h4 {
          max-width: 400px;
        }
      }
    }
  }
}

@media #{$tablet-up} {
  .fact-list {
    justify-content: flex-start;
    flex-wrap: nowrap;

    &__fact {
      margin: 0.25rem;

      &.\--third {
        flex-basis: 30%;
        min-width: 30%;
      }

      &.\--small {
        align-items: flex-start;
        max-width: 300px;
      }
    }
  }
}

@media #{$laptop-up} {
  .fact-list {
    &__fact {
      padding: 1rem 2rem;
      margin: 0.5rem;

      &.\--layout-row {
        h1 {
          font-size: $h1 * 1.5;
        }
      }
    }
  }
}
