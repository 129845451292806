/*---
title: Talent Labor Image Grid Section
section: Layout
---

*/

.talent-labor-imagegrid-section {

  .image {
    &:first-of-type {
      background-image: url('images/overhead-tables-1400.jpg');
    }

    &:nth-of-type(2n) {
      background-image: url('images/mason-400.png');
    }

    &:nth-of-type(3n) {
      background-image: url('images/vt-400.png');
    }
  }
  
}




