/*---
title: Sites Map
section: Layout
---



```example:html

```

*/

.sites-map {
  .container {
    padding: 4rem 0;
  }

  .title {
    margin-bottom: 1rem;
  }
}

@media #{$laptop-up} {
  .sites-map {
    position: relative;

    .container {
      position: absolute;
      bottom: 0;
      z-index: 10;
      background-color: $white;
      left: 4rem;
      max-width: 550px;
      padding: 2rem 1.5rem 3rem;
    }

    .title {
      max-width: 450px;
    }
  }
}

@media #{$desktop-up} {
  .sites-map {
    .container {
      left: 6rem;
      max-width: 525px;
      padding: 2rem 2rem 5rem;
    }
  }
}
