/*---
title: Subtitle
section: Typographpy
---



```example:html

```

*/
.subtitle {
  text-transform: uppercase;
  font-family: $font__main;
  letter-spacing: 2px;
  font-weight: 200;
  color: $copy__white;
  transform: scale(1, 0.8);
  margin: 0;
}
