a {
  color: inherit;
  text-decoration: none;

  &:visited {
  }

  &:hover,
  &:focus,
  &:active {
  }
}
