/*---
title: Lifestyle Section
section: Layout
---



```example:html

```

*/
.lifestyle-section {
  position: relative;
  padding-top: 4rem;
  padding-bottom: 4rem;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100% + 8rem);
    width: 35%;
    background-image: url('images/dots-lifestyle-left-1000.png');
    background-position: left center;
    background-size: cover;
    background-repeat: no-repeat;
    display: none;
    z-index: -1;
  }

  & > .image-figure {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    padding-left: 10%;
  }
}

@media #{$up-to-laptop} {
  .lifestyle-section {
    .col:last-of-type {
      display: none;
    }
  }
}

@media #{$tablet-only} {
  .lifestyle-section {
    padding-top: 16rem;

    .title {
      width: 65%;
    }

    p {
      width: 80%;
    }
  }
}

@media #{$laptop-up} {
  .lifestyle-section {
    padding: 12rem 0 8rem;

    .container {
      max-width: 1400px;
    }

    &:before {
      display: block;
    }

    .suptitle {
      margin-left: -2rem;
    }

    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .col {
      &:first-of-type {
        width: 50%;
      }

      &:last-of-type {
        width: 45%;
      }

      .image-figure {
        display: block;
        overflow: hidden;
      }
    }

    > .image-figure {
      padding-left: 20%;
    }
  }
}

@media #{$desktop-up} {
  .lifestyle-section {
    padding: 14rem 0;
  }
}

.shift-livability-image{
  @media #{$laptop-up}{
      position: relative;
      top: 15rem;
  }
}
