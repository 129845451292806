/*---
title: Talent Labor Section
section: Layout
---

*/

.talent-labor-section {
  .row__graphs {
    display: flex;
    padding-bottom: 100px;

    .col {
      width: 50%;
    }

    .circle-graph {
      margin: auto;
    }
  }

  .row__hilight {
    margin: 100px 0 40px 0;
  }

  .hilight {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.talent-labor-section__header {
  .suptitle {
    &:after {
      left: 1.1rem;
      width: 130%;
    }
  }
}

@media #{$tablet-up} {
  .talent-labor-section {
    position: relative;

    .row__graphs {
      width: 55%;

      .circle-graph {
        margin: 0;
      }
    }

    .col {
      width: 60%;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 50%;
      height: 100%;

      background-image: url('./images/talent-labor-1000.png');
      background-position: center left;
      background-size: cover;
      background-repeat: no-repeat;
      z-index: -1;
    }
  }
}