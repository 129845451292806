/*---
title: Hamburger
section: Navigation
---

The hamburger nav, for mobile/tablet viewports.

```example:html
<a class="hamburger js-hamburger" href="#">
  <div class="hamburger__bar"> </div>
  <div class="hamburger__bar"> </div>
  <div class="hamburger__bar"> </div>
</a>
```

*/
.hamburger {
  z-index: 200;

  &__bar {
    display: block;
    height: 3px;
    width: 34px;
    transition: all 300ms ease;
    background-color: $copy__turquoise;

    &:not(:last-of-type) {
      margin-bottom: 6px;
    }
  }
}

.site-header.is-active .hamburger {
  &__bar {
    opacity: 0;
  }

  &__bar:first-of-type {
    opacity: 1;
    transform-origin: left center;
    transform: rotate(45deg) translateY(-4px);
  }
  &__bar:last-of-type {
    opacity: 1;
    transform-origin: left center;
    transform: rotate(-45deg) translateY(4px);
  }
}

@media #{$tablet-up} {
  .hamburger {
    display: none;
  }
}
