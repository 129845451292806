body,
button,
input,
select,
optgroup,
textarea {
  color: $copy__black;
  font-family: $font__main;
  line-height: $font__line-height-body;
}

@import 'headings';
@import 'hilight';
@import 'copy';
@import 'title';
@import 'subtitle';
@import 'suptitle';
@import 'site-title';
@import 'description';
