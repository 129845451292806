/*---
title: Image Grid
section: Modules
---

Grid of 3 images, as found on the site detail page.

```example:html
<div class="image-grid">
  <div class="image"></div>
  <div class="image"></div>
  <div class="image"></div>
</div>
```

*/
.image-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .image {
    width: 100%;
    padding-top: 100%;
    background-position: center center;
    background-size: cover;
  }
}

@media #{$phablet-up} {
  .image-grid .image {
    &:first-of-type {
      width: 100%;
    }

    &:nth-of-type(2n),
    &:nth-of-type(3n) {
      padding-top: 50%;
      width: 50%;
      background-position: bottom; 
    }
    &:nth-of-type(3n) {
      background-position: top;
    }
  }
}

@media #{$tablet-up} {
  .image-grid {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100vh;
    max-height: 100vh;
    min-height: 800px;

    .image {
      &:first-of-type {
        max-width: 50%;
        padding-top: 50%;
        height: 100%;
        max-height: 100%;
      }

      &:not(:first-of-type) {
        max-width: 50%;
        padding-top: 25%;
        height: 50%;
        max-height: 50%;
      }
    }
  }
}

@media #{$laptop-up} {
  .image-grid .image {
    &:first-of-type {
      max-width: 65%;
      padding-top: 40%;
      max-height: 100%;
    }
    &:not(:first-of-type) {
      max-width: 35%;
      padding-top: 20%;
      max-height: 50%;
    }
  }
}

