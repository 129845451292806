/*---
title: Figure Row
section: Modules
---

List of figures accompanied with a relavent icon. Found through out the site, include the hompage talent section.

```example:html

<ul class="figure-row">
  <li class="figure-row__item">
    <header>
      <img class="icon" src="assets/icons/icon_001.svg" alt="">
    </header>
    <footer>
      <h3 class="title black">Fertum Sem</h3>
      <p><small>Nulla vitae elit libero, a pharetra augue. Maecenas sed diam eget risus varius blandit sit amet .</small></p>
    </footer>
  </li>
  <li class="figure-row__item">
    <header>
      <img class="icon" src="assets/icons/icon_002.svg" alt="">
    </header>
    <footer>
      <h3 class="title black">Fertum Sem</h3>
      <p><small>Nulla vitae elit libero, a pharetra augue. Maecenas sed diam eget risus varius blandit sit amet .</small></p>
    </footer>
  </li>
  <li class="figure-row__item">
    <header>
      <img class="icon" src="assets/icons/icon_003.svg" alt="">
    </header>
    <footer>
      <h3 class="title black">Fertum Sem</h3>
      <p><small>Nulla vitae elit libero, a pharetra augue. Maecenas sed diam eget risus varius blandit sit amet .</small></p>
    </footer>
  </li>
</ul>
```

*/
.figure-row {
  margin: 0;
  padding: 0;
  list-style-type: none;
  margin-bottom: 4rem;

  &__item {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid $background__grey;

    header,
    footer {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    header {
      align-items: center;
      width: 20%;
      min-width: 6rem;
      margin-right: 1rem;
    }

    footer {
      width: 80%;
    }

    .title,
    p {
      margin: 0;
    }

    p {
      font-family: $font__heading;
    }

    small {
      line-height: 1;
      font-size: $small;
    }
  }
}
