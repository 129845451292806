/*---
title: Subsection Header
section: Modules
---

*/

.subsection-header {
  height: 260px;
  position: relative;

  .suptitle {
    margin-top: 75px;
    color: white;

    &:after {
      background-color: $dkpurple;
      width: 75%;
      left: 4em;
    }
  }

  .header-primary-bg {
    width: 428px;
    height: 240px;

    position: absolute;
    top: 20px;
    left: 0;

    fill: $purple;

    z-index: -1;
  }

  .header-secondary-bg {
    background-size: cover;

    width: 966.4px;
    height: 240px;

    position: absolute;
    top: 0;
    left: 200px;

    fill: white;

    z-index: -2;
  }
}

@media #{$tablet-up} {
  .subsection-header {
    height: 320px;

    .header-primary-bg {
      width: 535px;
      height: 300px;

      position: absolute;
      top: 20px;
      left: 0;

      fill: $purple;

      z-index: -1;
    }

    .header-secondary-bg {
      width: 1208px;
      height: 300px;

      position: absolute;
      top: 0;
      left: 230px;

      fill: white;

      z-index: -2;
    }
  }
}