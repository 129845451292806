/*---
title: Developer List
section: Modules
---

The list for showcasing developers, as on the site detail page.

```example:html
<ul class="developer-list">
  <li class="developer-list__item">
    <header>
      <img src=
      "/assets/images/clark-construction-800.png" alt="">
    </header>
    <footer>
      <h4 class="title black">Clark Construction Group</h4>
      <p class="description"> Cras mattis consectetur purus sit amet fermentum.
        Maecenas faucibus mollis interdum. Donec sed odio
        dui. Aenean lacinia bibendum nulla sed
        consectetur.</p>
    </footer>
  </li>
  <li class="developer-list__item">
    <header>
      <img src=
      "/assets/images/clark-construction-800.png" alt="">
    </header>
    <footer>
      <h4 class="title black">Clark Construction Group</h4>
      <p class="description"> Cras mattis consectetur purus sit amet fermentum.
        Maecenas faucibus mollis interdum. Donec sed odio
        dui. Aenean lacinia bibendum nulla sed
        consectetur.</p>
    </footer>
  </li>
</ul>
```

*/
.developer-list {
  margin: 0;
  padding: 0;
  margin-top: 2rem;

  &__item {
    list-style-type: none;
    img {
      margin-bottom: 20px;
      &.small {
        width: 75%;
      }
    }
  }

  .title {
    margin-bottom: 0;
    font-family: $font__body;
  }

  .description {
    margin-top: 0;
  }
}

@media #{$tablet-up} {
  .developer-list {
    margin-top: 4rem;
  }
}

@media #{$up-to-desktop} {
  .developer-list {
    img {
      margin-bottom: 1rem;
      &.small {
        width: auto;
      }
    }
  }
}

@media #{$desktop-up} {
  .developer-list {
    margin-top: 6rem;

    &__item {
      display: flex;
      flex-direction: row;
      
      header {
        flex: 1;
      }

      footer {
        flex: 4;
      }
    }
  }
}
