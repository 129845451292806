/*---
title: Home Hero
section: Modules
---

The homepage hero. Includes a main content area and a secondary content area.
Could also hold lightbox content.

```example:html

<section class="hero">
  <div class="hero__content">
    <div class="container">
      <h5 class="subtitle">Welcome to Northern Virginia</h5>
      <h1 class="title">In<span class="turquoise">nova</span>tion lives here</h1>
      <a class="play-button js-play-button" href="#"></a>
    </div>
  </div>
</section>
```

*/
.home__hero {
  display: flex;
  position: relative;
  flex-direction: column;
  overflow: hidden;

  

  @media #{$up-to-tablet} {
      background: url(images/Mets-and-Pen-1400.jpg) bottom center;
      background-size: cover;
  }

  &-content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: auto;
    width: 100%;
    height: 90vh;

    video {
      position: absolute;
      left: 50%;
      top: 50%;
      min-width: 100%;
      min-height: 100%;
      transform: translate(-50%, -50%);
      z-index: -5;

      @media #{$up-to-tablet} {
        display: none;
      }
    }

    svg {
      display: none;

      position: absolute;
      left: -5%;
      bottom: 0;
      height: 5rem;
      width: 110%;
      fill: $turquoise;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $black;
      opacity: 0.4;
      z-index: -2;
    }

    &:after {
      content: '';
      position: absolute;
      left: -5%;
      right: -5%;
      bottom: -5rem;
      z-index: -1;
      height: 10rem;
      background-color: $turquoise;
      transform: rotate(-5deg);
    }

    .subtitle {
      font-size: 1.5rem;
    }
  }

  &-secondary {
    background-color: $copy__turquoise;
    padding: 2rem 0;

    &-bg-shape {
      display: none;
    }
  }

  .container {
    max-width: 1400px;
  }

  .suptitle {
    margin: 0;

    &:after {
      content: none;
    }
  }
}

@media #{$phablet-up} {
  .home__hero {
    &-content {
      svg {
        height: 10rem;
      }
    }
  }
}

@media #{$tablet-up} {
  .home__hero {
    &-content {
      min-height: 840px;
      max-height: 1000px;
    }

    &-secondary {
    }
  }
}

@media #{$laptop-up} {
  .home__hero {
    &-content {
      /* z-index: 5; */
    }

    &-secondary {
      padding: 4rem 0;
      .title {
        font-size: $h3 * 0.7;
        line-height: 1.6;
      }
    }
  }
}

@media #{$desktop-up} {
  .home__hero {
    &-content {
      &:after {
        bottom: -10rem;
        height: 15rem;
      }
    }
  }
}

@media (min-width: 1800px) {
  .home__hero-content:after {
    bottom: -12.5rem;
    height: 20rem;
  }
}
