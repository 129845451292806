/*---
title: Hilight
section: Typography
---

*/

.hilight {
  .suptitle {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    text-transform: none;
    letter-spacing: inherit;
    font-weight: normal;

    &:after {
      left: 0;
      width: 105%;
    }
  }
}