/**
 *
 * Styles
 *
 * This is the root file for all styles, so every scss partial should be
 * imported here. Import statements should be grouped functionally.
 */

// Variables
@import 'variables/_breakpoints.scss';
@import 'variables/_colors.scss';
@import 'variables/_typography.scss';
@import 'variables/test.scss';

// Elements
@import 'elements/_elements.scss';

// Typography
@import 'typography/_typography.scss';

// Utilities
@import 'utilities/_utilities.scss';
@import 'utilities/_animations.scss';

// Layout
@import 'layout/_layout.scss';
@import 'layout/_container.scss';
@import 'layout/_connectivity-section.scss';
@import 'layout/_lifestyle-section.scss';
@import 'layout/_lifestyle-support-section.scss';
@import 'layout/_page-section.scss';
@import 'layout/_page-sites.scss';
@import 'layout/_sites-section.scss';
@import 'layout/_talent-section.scss';
@import 'layout/_business-climate.scss';
@import 'layout/_presented-section.scss';
@import 'layout/_properties-section.scss';
@import 'layout/_sites-map.scss';
@import 'layout/_site-detail.scss';
@import 'layout/_national-landing.scss';
@import 'layout/_alexandria.scss';
@import 'layout/_innovation-station.scss';
@import 'layout/_capital-view.scss';
@import 'layout/_talent.scss';
@import 'layout/_talent-education-section.scss';
@import 'layout/_talent-future-section.scss';
@import 'layout/_talent-labor-education-section.scss';
@import 'layout/_talent-labor-imagegrid-section.scss';
@import 'layout/_talent-labor-section.scss';
@import 'layout/_credit-section.scss';

// Navigation
@import 'navigation/_navigation.scss';

// Site
@import 'site/_site-header.scss';
@import 'site/_site-footer.scss';
@import 'site/_site-nav.scss';

// Modules
@import 'modules/_circle-graph.scss';
@import 'modules/_hero.scss';
@import 'modules/_figure-row.scss';
@import 'modules/_presenters.scss';
@import 'modules/_image-figure.scss';
@import 'modules/_js-image-figure.scss';
@import 'modules/_image-title-callout.scss';
@import 'modules/_logo-group.scss';
@import 'modules/_lightbox.scss';
@import 'modules/_icon.scss';
@import 'modules/_site.scss';
@import 'modules/_sites-lightbox.scss';
@import 'modules/_map-nav.scss';
@import 'modules/_fact-list.scss';
@import 'modules/_developer-list.scss';
@import 'modules/_ribbon.scss';
@import 'modules/_image-grid.scss';
@import 'modules/_quote.scss';
@import 'modules/_subsection-header.scss';
@import 'modules/_talent-hero.scss';
@import 'modules/_blockquote.scss';

img.placeholder {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  background-color: $background__grey;
}
