.turquoise {
  color: $copy__turquoise;
}

.purple {
  color: $copy__purple;
}

.black {
  color: $copy__black;
}

.white {
  color: $white;
}

.black {
  color: $black;
}

.grey {
  color: $grey;
}

.lightgrey {
  color: $lightgrey;
}
