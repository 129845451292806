/*---
title: Site Detail
section: Layout
---

Layout for Site Detail pages

```example:html

```

ToC:
siteDetail: .site-detail
hero: .site-detail__hero
intro: .site-detail__intro
facts: .site-detail__facts
connectivity: .site-detail__connectivity
developers: .site-detail__developers

*/
/* siteDetail */
.site-detail {
}

/* hero */
.site-detail__hero {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 75vh;
  min-height: 700px;
  overflow: hidden;

  &-image {
    min-width: 100%;
    min-height: 100%;
    background-position: center center;
    background-size: cover;

    &:not(:first-of-type) {
      display: none;
    }
  }

  &-header {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;

    .container {
      max-width: 1296px; /* 90% of 1440px */
    }

    &:after {
      content: '';
      position: absolute;
      left: -5%;
      right: -5%;
      bottom: -5rem;
      width: 110%;
      height: 20rem;
      transform: rotate(5deg);
      z-index: -1;
    }
  }

  .title {
    margin: 6rem 0 2rem;
  }
}
    
@media #{$phone-only} {
  .site-detail__hero {
    &-header {
      bottom: 4rem;
    }
  }
}

@media #{$phablet-up} {
  .site-detail__hero {
    &-header:after {
      bottom: -8rem;
    }
  }
}

@media #{$tablet-up} {
  .site-detail__hero {
    &-image {
      min-width: 40%;

      &:first-of-type {
        min-width: 60%;
      }

      &:not(:first-of-type) {
        display: block;
      }
    }

    &-header:after {
      bottom: -10rem;
    }
  }
}

@media #{$laptop-up} {
  .site-detail__hero {
    &-header:after {
      /* bottom: -12rem; */
    }
  }
}

/* intro */
.site-detail__intro {
  .row {
    display: flex;
    flex-direction: column;
  }

  .col {
    overflow: hidden;

    &:first-of-type {
      position: relative;
      width: 90%;
      margin: 0 auto;
      padding: 4rem 0;
    }

    &:last-of-type {
      position: relative;
      display: flex;
      flex-direction: column;
      min-height: 75vh;
    }
  }

  &-image {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-position: center center;
    background-size: cover;

    &:first-of-type {
      min-height: 40%;
    }

    &:last-of-type {
      min-height: 60%;
    }
  }
}

@media #{$tablet-up} {
  .site-detail__intro {
    .row {
      flex-direction: row;
    }

    .col {
      position: relative;
      width: 55%;

      &:first-of-type {
        width: 45%;
        max-width: 568px;
        margin: 0;
        margin-left: 5%;
        padding: 6rem 0 10rem;
      }

      &:last-of-type:before {
        content: '';
        position: absolute;
        top: -4rem;
        left: -12rem;
        height: 125%;
        width: 15rem;
        background-color: $white;
        transform: rotate(5deg);
      }
    }

    .play-button {
      margin-left: 6rem;
    }

    &-image {
      &:first-of-type,
      &:last-of-type {
        min-height: 50%;
      }
    }

    .description {
      margin-bottom: 0;
    }
  }
}

@media #{$desktop-up} {
  .site-detail__intro {
    .col:first-of-type {
      margin-left: auto;
    }
  }
}

/* facts */
.site-detail__facts {
}

@media #{$tablet-up} {
  .site-detail__facts {
    padding-bottom: 3rem;

    .fact-list {
      width: 90%;
      max-width: 1440px;
      margin: -1rem auto 0;

      &:first-of-type {
        margin-bottom: 1rem;
      }

      &__fact:first-of-type {
        margin-left: 0;
      }

      &__fact:last-of-type {
        margin-right: 0;
      }
    }
  }
}

@media #{$laptop-up} {
  .site-detail__facts {
    /* z-index: 20; */

    .fact-list {
      margin: -2rem auto 0;

      &:first-of-type {
        margin-bottom: 2rem;
      }
    }
  }
}

/* connectivity */
.site-detail__connectivity {
  .fact-list {
    width: 100%;
  }

  &-content {
    padding: 4rem 0;
  }

  .row.reverse {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;

    .col {
      padding: 2rem 0;
      &:last-of-type {
        text-align: center;
      }
    }
  }
}

@media #{$phablet-up} {
  .site-detail__connectivity {
    .image {
      &:not(:first-of-type) {
        max-width: 50%;
        padding-top: 50%;
      }
    }

    .fact-list {
      flex-direction: row;
      flex-wrap: wrap;

      &__fact {
      }
    }
  }
}

@media #{$tablet-up} {
  .site-detail__connectivity {
    padding-bottom: 2rem;

    .fact-list {
      max-width: 650px;
      margin-top: -150px;

      &__fact:nth-of-type(2n + 1) {
        margin-left: 0;
      }

      &__fact:nth-of-type(2n) {
        margin-right: 0;
      }
    }
  }
}

@media #{$laptop-up} {
  .site-detail__connectivity {
    &-content {
      .col:first-of-type {
        width: 50%;
      }

      .col:last-of-type {
        width: 40%;
      }
    }
    .row {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;

      &.reverse {
        align-items: center;
        flex-direction: row-reverse;
      }
    }
    .row.reverse {
      .col {
        max-width: 800px;
      }
      .col:first-of-type {
        width: 70%;
      }
      .col:last-of-type {
        width: 30%;
      }
    }
  }
}

@media #{$desktop-up} {
  .site-detail__connectivity {
    &-content {
      &:nth-of-type(2n) {
        flex-direction: row-reverse;
      }
    }
  }
}

/* developers */
.site-detail__developers {
  padding: 3rem 0;
}

/* map interstitial*/
.map-interstitial{

  img{
    width: 100%;
  }

  @media #{$tablet-up}{
    position: relative;
    z-index: 1;
    // top: -6rem;
  }
  
}

/* New Property Section*/
.national-landing .property-details{
  @media #{$up-to-tablet}{
    width: 90%;
    margin: auto;
    padding: 0;
  }
  padding: 1rem 0;
  
  .row {
    @media #{$tablet-up} {
      display: flex;
    }
    .col-50{
      @media #{$tablet-up} {
        width: 48%;
        margin: 0 auto;
      }
    }
    &.\--center-col{
    justify-content: center;
    }
  }
  .full-width-left{

    & > div:last-child{
      @media #{$tablet-up} {
        margin-right: 0;
        padding-right: 0;
      }
    }
  }
  .full-width-right{

    & > div:first-child{
      @media #{$tablet-up} {
        margin-left: 0;
        padding-left: 0;
      }
    }
  }
  .center-content{
      display: flex;
      flex-direction: column;
      justify-content: center;

    @media #{$tablet-up} {
      width: 80%;
      margin: 0 auto;

    }
  }
}
