/*---
title: Talent Labor Education Section
section: Layout
---

*/

.talent-labor-education-section {

  .row__primary {
    .graph {
      margin: auto;
      display: block;

      img{
        padding-top: 10%;
      }

      figcaption {
        background: transparent;
        padding: 0 0 0 12px;
        border-left: 6px solid $turquoise;
        width: 65%;
        left: 0;
        letter-spacing: normal;
        line-height: 2em;
        text-transform: none;
      }
    }
  }

  .row__secondary {
    margin: 8% 0;

    .image-title-callout {
      @media #{$tablet-only}{ 
          padding-bottom: 165px;
        } 

      h3.title{
        max-width: 70rem;
        @media #{$up-to-tablet}{
          font-size: 1.3rem;
        }
        @media #{$up-to-laptop}{
          font-size: 1.6rem;
        }   
      }
    }
  }
}

@media #{$tablet-up} {
  .talent-labor-education-section {

    .row__primary {
      display: flex;

      .col {
        width: 50%;

        &:first-child {
          margin-right: 10%;
        }
      }
    }

    .row__secondary {
      margin: 5% 0 0 0;

      .image-title-callout {
        &:before {
          background: no-repeat url(./images/graduation-cap-1000.png);
          background-size: cover;
          width: 409px;
          height: 320px;
        }
      }
    }
  }
}