/*---
title: Suptitle
section: Typography
---



```example:html

```

*/
.suptitle {
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  font-family: $font__main;
  font-weight: 900;
  z-index: 0;
  letter-spacing: 4px;

  &:after {
    content: '';
    position: absolute;
    bottom: -0.3rem;
    left: 0.8rem;
    height: 0.8rem;
    width: 60%;
    background-color: $background__grey;
    z-index: -1;
  }
}

.js-section {
  .suptitle {
    opacity: 0;
    letter-spacing: 4px;
    transition: all 500ms ease;

    &:after {
      opacity: 1;
      transform-origin: left;
      transition: all 300ms ease;
      transition-delay: 200ms;
      transform: scaleX(0);
    }
  }

  &.in-view .suptitle {
    opacity: 1;

    &:after {
      transform: scaleX(1);
    }
  }
}
