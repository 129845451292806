/*---
title: Image Title Callout
section: Modules
---

*/

.image-title-callout {
  .title {
    padding: 0 0 20px 0;
    border-bottom: 7px solid $copy__gold;
    @media #{$tablet-up} {
      font-size: 2.6rem;
    }
  }

  .title__emphasize {
    font-size: 1.6em;
  }
}

@media #{$tablet-up} {
  .image-title-callout {
    padding: 55px 0 100px 250px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
    }

    .title {
      padding-left: 200px;
    }
  }
}