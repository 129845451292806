/*
 * Innovation Station
 *
 * These styles are specific to the Innovation Station site detail page. Most styling
 * for this page should be in _site-detail.scss, not in here.
 */
.innovation-station {
  .site-detail__hero {
    svg {
      fill: $blue;
    }

    &-image {
      background-image: url('images/map-hero-innovationstation-1400.jpg');

      &:first-of-type {
        background-image: url('images/Innovation_Hero-1400.jpg');
      }
    }

    &-header:after {
      background-color: $blue;
    }
  }

  .site-detail__intro-image {
    &:first-of-type {
      background-image: url('images/Innovation_VideoStill-1400.jpg');
    }

    &:last-of-type {
      background-image: url('images/innovation-station-under-video-1000.jpg');
    }
  }

  .site-detail__connectivity {
    .fact-list__fact {
      background-color: $blue;
    }
  }

  .image {
    &:first-of-type {
      background-image: url('images/Innovation_Grid1-1400.jpg');
    }

    &:nth-of-type(2n) {
      background-image: url('images/innovation-station-connectivity-02-1000.jpg');
    }

    &:nth-of-type(3n) {
      background-image: url('images/innovation-station-connectivity-03-1000.png');
    }
  }

  .map-interstitial {
    position: relative;
    top: -6rem;
  }
}
