h1,
h2,
h3,
h4 {
  font-weight: 700;
}

h1 {
  font-size: $h1 * 0.9;
}

h2 {
  font-size: $h2 * 0.7;
}

h3 {
  font-size: $h3 * 0.8;
}

h4 {
  font-size: $h4;
  /* font-size: $h4 * 0.8; */
}

h5 {
  font-size: $h5;
}

h6 {
  font-size: $h6;
}

@media #{$tablet-up} {
h1 {
  font-size: $h1;
}

h2 {
  font-size: $h2;
}

h3 {
  font-size: $h3;
}

h4 {
  font-size: $h4;
}

h5 {
  font-size: $h5;
}

h6 {
  font-size: $h6;
}
}
