/*
 * Talent Section
 */
 
.talent-section, .faqs-section, .downloads-section, .page-not-found-section {
  position: relative;
  padding-top: 5rem;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    background-image: url('./images/dots-talent-top-1000.png');
    background-position: center center;
    background-size: cover;
    height: 6rem;
  }
}

@media #{$tablet-up} {
  .talent-section:before,
  .faqs-section:before,
  .downloads-section:before,
  .page-not-found-section:before {
    height: 12rem;
    width: 65%;
  }
}

/*
 * Talent Section Header
 */
.talent-section__header {
  .title {
    max-width: 950px;
  }
}

@media #{$up-to-laptop} {
  .talent-section__header {
    margin-bottom: 4rem;
  }
}

@media #{$laptop-up} {
  .talent-section__header {
    min-height: 500px;
    padding-bottom: 20px;

    .description {
      margin-top: 3rem;
      width: 40%;
    }
  }
}

@media #{$desktop-up} {
  .talent-section__header {
    width: 80%;

    .description {
      width: 50%;
    }
  }
}

/*
 * Talent Section Body
 */
.talent-section__body {
  .col:last-of-type > p {
    margin-top: 2rem;
    margin-bottom: 3rem;
  }
}

@media #{$up-to-laptop} {
  .talent-section__body {
    padding: 2rem 0 4rem;
    .col:first-of-type {
      display: none;
    }
  }
}

@media #{$tablet-only} {
  .talent-section__body {
    .figure-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .figure-row__item {
      display: block;
      text-align: center;
      padding: 0 1rem;
      width: 33%;
      border: none;

      header,
      footer {
        width: 100%;
      }

      h3 {
        font-size: $h3 * 0.8;
        margin-bottom: 0.5rem;
      }
    }
  }
}

@media #{$laptop-up} {
  .talent-section__body {
    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .col {
      flex: 1;
      width: 45%;
    }

    .col:first-of-type {
      position: relative;
      min-height: 700px;

      > img {
        position: absolute;
        bottom: 0;
        max-width: 450px;
        max-height: 100%;
      }
    }
    .col:last-of-type {
      margin-top: -220px;
      padding-bottom: 4rem;
    }
  }
}

.top-section {
  background: $background__lightgrey;
  padding-bottom: 2rem;
  @media #{$tablet-up} {
    padding: 2rem;
  }

  &__header {
    padding-top: 3rem;
    h2 {
      @media #{$tablet-up} {
        width: 80%;
      }
      @media #{$laptop-up} {
        width: 100%;
      }
    }
  }

  &__wrapper {
    display: flex; 
    flex-wrap: wrap;
    margin-top: 2rem;

    .top-item {
      display: flex;
      flex-direction: column;
      padding: 0;

      @media #{$tablet-up} {
        padding: 2rem 0rem 0rem;
      }
      @media (min-width: 1024px) {
        width: 50%;
        flex-direction: row;
      }

      >div {  
        width: 100%;
        @media #{$tablet-up} {
          width: 65%;
        }
        @media #{$laptop-up} {
          width: 70%;
        }

        .title {
          color: $background__darkgrey;
          font-size: 2rem;
        }

        .icon {
          @media #{$phone-only} {}
        }
      }
    }
  }
}
