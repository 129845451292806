/*---
title: Map Nav
section: Modules
---

The interactive map navigation element on the home page and sites page

```example:html

```

TOC:
mapNav - .map-nav
mapNav__Map - .map-nav__map
mapNav__Indicator - .map-nav__indicator

*/

/* mapNav */
.map-nav {
  padding: 2rem 0;
}

@media #{$phablet-only}, #{$tablet-only} {
  .map-nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
}

@media #{$laptop-up} {
  .map-nav {
    position: relative;
    padding: 0;
  }
}

/* mapNav__Map */
.map-nav__map {
  display: none;
  min-width: 100%;
}

@media #{$laptop-up} {
  .map-nav__map {
    display: block;
  }
}

/* mapNav__Indicator */
.map-nav__indicator {
  display: flex;
  padding: 2rem 1rem;

  img,
  span {
    pointer-events: none;
  }

  img {
    display: inline-block;
    width: 125px;
    height: 125px;
    z-index: 1;
  }

  span {
    display: inline-block;
    color: $white;
    padding: 0.5rem 1rem;
    letter-spacing: 2px;
    font-size: $small;
    white-space: nowrap;
    z-index: 0;
    min-width: 150px;
    text-align: center;
  }

  &.capital-view span {
    background-color: $purple;
  }

  &.alexandria span {
    background-color: $turquoise;
  }

  &.national-landing span {
    background-color: $yellow;
  }

  &.innovation-station span {
    background-color: $blue;
  }
}

@media #{$up-to-phablet} {
  .map-nav__indicator {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;

    span {
      margin-left: -0.5rem;
    }

    &:nth-of-type(2n + 2) {
      flex-direction: row-reverse;

      span {
        margin-left: 0;
        margin-right: -0.5rem;
      }
    }
  }
}

@media #{$phablet-only}, #{$tablet-only} {
  .map-nav__indicator {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 33%;

    span {
      min-width: 200px;
      text-align: center;
      margin-top: 1rem;
    }
  }
}

@media #{$laptop-up} {
  .map-nav__indicator {
    position: absolute;
    justify-content: flex-start;
    align-items: center;
    transform: scale(0.75);

    &,
    img,
    span {
      transition: all 300ms ease;
    }

    img {
      border-radius: 50%;
    }

    span {
      transform-origin: left center;
      transform: scale(0, 1);
    }

    &:hover {
      transform: scale(0.77);
      img {
        box-shadow: -2px 5px 8px 2px rgba(0, 0, 0, 0.4);
      }

      span {
        transform: scale(1, 1);
      }
    }
    span {
      margin-left: -0.5rem;
    }
  }
}

@media #{$laptop-up} {
  .map-nav__indicator {
    transform: scale(0.9);

    &:hover {
      transform: scale(1);
    }

    &.innovation-station {
      top: 5%;
      left: 5%;
    }

    &.capital-view {
      top: 43%;
      left: 68%;
    }

    &.national-landing {
      top: 59%;
      left: 65%;
    }

    &.alexandria {
      top: 75%;
      left: 62.5%;
    }
  }
}

@media #{$desktop-up} {
  .map-nav__indicator {
    &.innovation-station {
      top: 10%;
      left: 5%;
    }

    &.capital-view {
      top: 48%;
      left: 70%;
    }

    &.national-landing {
      top: 61%;
      left: 67%;
    }

    &.alexandria {
      top: 75%;
      left: 65%;
    }
  }
}
