/*
 *Site Header
 */
.site-header {
  background-color: $white;

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

@media #{$up-to-tablet} {
  .site-header {
    padding: 0.5rem 0;

    .site-nav {
      opacity: 0;
      pointer-events: none;
    }

    &.is-active {
      .site-nav {
        opacity: 1;
        pointer-events: all;
        overflow: scroll;
      }
    }
  }
}

@media #{$tablet-up} {
  .site-header {
    padding: 0.5rem 0;
    box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.2);
  }
}
