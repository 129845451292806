/*---
title: Circle Graph
section: Modules
---

*/

.circle-graph {
  width: 150px;
  height: 150px;
  background: no-repeat url('./images/circle-graph-400.png');
  background-size: cover;
  text-align: center;
  @media #{$phablet-up} {
    width: 200px;
    height: 200px;
  }

  .title {
    padding-top: 60px;
    font-size: 2.7rem;
    line-height: 1em;
    color: black;
  }

  .description {
    margin: 10px 40px 0 40px;
    font-family: $font__heading;
    font-size: $small;
    line-height: 1.3em;
  }
}