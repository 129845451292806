/*
 * Site
 * The container for a single site (location).
 */
.site {
  .row {
    display: flex;
    flex-direction: column;
  }

  .col {
    position: relative;

    img {
      width: 100%;
    }

    &:last-of-type {
      padding: 2rem 1rem;
    }
  }

  .figure-row {
    margin-top: 4rem;

    &__item {
      flex-direction: column;
      text-align: center;
      border: none;
    }
  }
}

@media #{$tablet-up} {
  .site {
    height: 100%;
    min-height: 750px;

    background: $white;

    &:not(.is-open) {
      display: none;
    }

    .row {
      flex-direction: row;
      height: 100%;
    }

    .col {
      width: 50%;
      background-color: $white;

      &:first-of-type {
        overflow: hidden;
        img {
          min-height: 100%;
          width: auto;
          max-width: unset;
        }
      }
      &:last-of-type {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }
    }

    .figure-row {
      display: none;
    }
  }
}

@media #{$desktop-up} {
  .site {
    .col:last-of-type {
      display: block;
      padding: 2rem;
    }

    .figure-row {
      display: block;

      &__item {
        flex-direction: row;
        text-align: left;

        footer {
          margin-left: 1rem;
        }
      }
    }
  }
}
