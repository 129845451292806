/*---
title: Site Title
section: Typography
---



```example:html

```

*/
.site-title {
  color: $copy__turquoise;
  letter-spacing: 4px;
  font-weight: 400;
  transform-origin: center center;
  transform: scale(1, 0.8);
  margin: 0;
  z-index: 200;
}
