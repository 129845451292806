input[type='text'],
input[type='email'],
input[type='url'],
input[type='password'],
input[type='search'],
input[type='number'],
input[type='tel'],
input[type='range'],
input[type='date'],
input[type='month'],
input[type='week'],
input[type='time'],
input[type='datetime'],
input[type='datetime-local'],
input[type='color'],
textarea {
  color: #666;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 3px;

  &:focus {
    color: #111;
  }
}

select {
  border: 1px solid #ccc;
}

textarea {
  width: 100%;
}
