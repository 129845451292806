/*---
title: Business Climate Section
section: Layout
---



```example:html

```

*/
.business-climate-section {
  padding: 0;

  .col {
    margin-bottom: 2rem;
  }

  .blockquote {
    margin-top: 4rem;
  }
}

@media #{$tablet-up} {
  .business-climate-section {
    padding: 4rem 0;
    position: relative;

    .row {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }

    .col {
      width: 50%;

      &.is-fixed {
        position: fixed;
        top: 0;
      }

      &.is-stuck {
        position: absolute;
        bottom: 6rem;
      }

      &:first-of-type {
        margin-bottom: 0;
        padding-right: 2rem;
      }

      &:last-of-type {
      }
    }
  }
}

@media #{$laptop-up} {
  .business-climate-section {
    .blockquote {
      font-size: $h4;
    }
  }
}

.delta {
  font-family: univia-pro,sans-serif;
}