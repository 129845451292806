/*---
title: Talent Page Hero
section: Modules
---

```example:html
```

*/

.talent__hero {
  display: flex;
  flex-direction: column;

  &-content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: auto;
    width: 100%;
    min-height: 80vh;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url('./images/talent-hero-1000.png');
      background-position: bottom right;
      background-size: cover;
      background-repeat: no-repeat;
      z-index: -1;
    }
  }

  .title,
  .subtitle {
    width: 50%;
    max-width: 450px;
  }

  .title {
    font-size: 2rem;
  }

  .subtitle {
    font-size: 1.1rem;
    font-family: $font__body;
    font-weight: 100;
    text-transform: none;
    line-height: 1.8em;
    -webkit-transform: none;
    transform: none;
  }
}

@media #{$tablet-up} {
  .talent__hero {
    &-content {
      z-index: 5;
      &:after {
        height: 125%;
        background-color: transparent;
      }
    }
  }
}