/*---
title: title
section: Typography
---



```example:html
  <h2 class="title">This is a heading</h1>
```

*/
.title {
  font-family: $font__heading;
  font-weight: 200;
  margin: 0;
  color: white;
  line-height: 1.2;

  &__emphasize {
    font-family: $font__main;
    font-size: 145%;
  }
}

@media #{$tablet-up} {
  .title {
    &__emphasize {
      font-size: 150%;
    }
  }
}
