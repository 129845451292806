/*---
title: Javascript Fade Image Figure
section: Modules
---

*/

.js-image-figure {
  overflow: hidden;

  img {
    width: 100%;
    transform: scale(1.2);
    transition: all 300ms ease;
    opacity: 0;
  }

  &.in-view img {
    transform: scale(1);
    opacity: 1;
  }
}