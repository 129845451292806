/*---
title: Icon
section: Modules
---

Icons found throughout the site, primarily in the figure list.

```example:html
<img class="icon" src="assets/icons/icon_001.svg" alt="">
```

*/
.icon {
  width: 4.5rem;
  height: 4rem;
  margin: 1rem;
}
