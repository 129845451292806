/*
 * Alexandria
 *
 * These styles are specific to the Alexandria site detail page. Most styling
 * for this page should be in _site-detail.scss, not in here.
 */
.alexandria {
  .site-detail__hero {
    &-image {
      background-image: url('images/map-hero-alexandria-1400.jpg');

      &:first-of-type {
        background-image: url('images/Alex_Hero-1400.jpg');
      }
    }
    &-header:after {
      background-color: $turquoise;
    }
  }

  .site-detail__intro-image {
    &:first-of-type {
      background-image: url('images/Alex_VideoStill-1400.jpg');
    }

    &:last-of-type {
      background-image: url('images/Alex_UnderV-1400.jpg');
    }
  }

  .site-detail__connectivity {
    .fact-list__fact {
      background-color: $turquoise;
    }
  }

  .image {
    &:first-of-type {
      background-image: url('images/Alex_Grid1-1400.jpg');
    }

    &:nth-of-type(2n) {
      background-image: url('images/Alex_Grid2-1400.jpg');
    }

    &:nth-of-type(3n) {
      background-image: url('images/Alex_Grid3-1400.jpg');
    }
  }
}
