/*---
title: Lifestyle Support Section
section: Layout
---



```example:html

```

*/
.lifestyle-support-section {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -6rem;
    width: 35%;
    height: 350px;
    background-image: url('images/dots-lifestyle-support-bottom-1000.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left bottom;
    z-index: -1;
    display: none;
  }

  .image-figure {
    display: block;
    pointer-events: none;

    img {
      width: 100%;
    }

    figcaption {
      top: 35%;
      right: unset;
      left: 0;
    }
  }

  .row {
    display: flex;
    flex-direction: column;
  }

  .col {
    &:not(:first-of-type) {
      padding: 2rem;
      text-align: center;
    }
  }

  p {
    font-family: $font__heading;
    font-weight: 200;
  }
}

@media #{$phablet-up} {
  .lifestyle-support-section {
    .row {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 1rem;
    }

    .col {
      &:not(:first-of-type) {
        padding: 0;
        width: 30%;
      }

      &:first-of-type {
        display: none;
      }
    }
  }
}

@media #{$tablet-up} {
  .lifestyle-support-section {
    .row {
      flex-direction: row;
      padding: 0;
    }

    .col {
      flex: 1;

      &:first-of-type {
        flex: 1.2;
        margin-top: -12rem;
      }

      &:not(:first-of-type) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem 2.5rem;
      }

      &:nth-of-type(2n),
      &:nth-of-type(4n) {
        background-color: $background__grey;
      }

      &:nth-of-type(3n) {
        background-color: $background__lightgrey;
      }

      p {
        font-size: $small;
      }
    }
  }
}

@media #{$laptop-up} {
  .lifestyle-support-section {
    margin-top: 8rem;

    &:before {
      display: block;
    }

    .row {
      align-items: flex-end;
    }

    .col {
      &:first-of-type {
        position: relative;
        display: block;
        height: 430px;
      }

      &:not(:first-of-type) {
        height: 430px;
      }
    }

    .image-figure {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;

      img {
        height: 588px;
      }
    }

    .icon {
      width: 6rem;
      height: 6rem;
    }
  }
}

@media #{$desktop-up} {
  .lifestyle-support-section {
    .col:first-of-type {
      max-width: 380px;
    }
  }
}
