/*---
title: Sites Section
section: Layout
---

*/

.sites-section {
  padding-top: 4rem;
  background: $background__lightgrey;

  &__map {
    display: none;
  }

  .title {
    text-align: left;
  }

  .suptitle {
    &:after {
      height: 1rem;
      left: 1rem;
      width: 100%;
      /*background-color: $white;*/
    }
  }

  .description {
    margin-left: auto;
    margin-right: auto;
  }

  &__content {
    display: flex;
    flex-direction: column;
  }
  &::after {
    display: none;
  }
}


@media #{$up-to-tablet} {
  .sites-section {
    /*padding-bottom: 4rem;*/
    padding-bottom: 1rem;
  }
}

@media #{$tablet-up} {
  .sites-section {
    &__content {
      flex-direction: row;
      .col {
        width: 50%;
      }
      /*padding-bottom: 4rem;*/
    }

    &__map {
      display: block;
      width: 100%;
    }
  }
}

@media #{$laptop-up} {
  .sites-section {
    position: relative;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 15rem;
      height: 15rem;
      border-color: transparent;
      border-width: 15rem;
      border-bottom-color: $white;
      border-style: solid;
      transform: rotate(45deg) translate(0, -21.5rem);
    }
  }
}

/* Downloads Page */
/* FAQs Page */
.faqs{
  .sites-section{
    background: $white;
  }
}

.sites-section.downloads-section,
.sites-section.faqs-section,
.sites-section.page-not-found-section {

  .sites-section__content {
    max-width: 850px !important;
    margin: 0 auto;
    display: block;
  }
  .description {
    margin: 0;

    @media #{$tablet-up} {
      padding: 2rem 0rem;
    }

    @media #{$laptop-up} {
      padding: 2rem 2rem;
    }
  }

  &::after {
    content: none;
  }
}

/* Download Content */
.downloads {

  @media #{$tablet-up} {
    padding: 2rem 0rem;
  }

  @media #{$laptop-up} {
    padding: 2rem 2rem;
  }

  .download-item {
    padding: 3rem 0rem;
    display: flex;

    img {
      display: none;

      /* Only if client wants to add images/icon, turn flex on ^above */
      /*@media #{$phablet-up} {
        display: block;
        padding-left: 2rem;
      }*/
    }

    div {
      width: 100%;

      h4.title.turquoise.download__title{
        text-align: left;
      }
    }

    .call-to-action.download-link {
      margin-bottom: 1rem;
    }
  }
} 


/* FAQs Content */
.faqs-section {
  .faqs-nav {
    list-style: none;
    padding: 0;

    @media #{$tablet-up} {
      padding: 1rem 0 0 2rem;
    }

    li {
      display: block;
      margin-top: .5rem;
      @media #{$tablet-up} {
        display: inline-block;
      }
      a {
        font-family: univia-pro,sans-serif;
      }
      &:not(:first-child):not(.not):before {
        content: none;
        @media #{$tablet-up} {
          content: "|";
          color: $background__darkgrey;
        }
      }
    }
  }
}

.accordion {

  @media #{$tablet-up} {
    padding: 2rem 0rem;
  }

  @media #{$laptop-up} {
    padding: 2rem 2rem;
  }

  h3.title.purple {
    text-align: left;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  h4.title.turquoise {
    text-align: left;
    padding-top: 1.5rem;
    /*cursor: pointer;*/
  }

  &__entry {
    position: relative;
    margin: .5rem 0;
  }

  &__description {
    overflow: hidden;
    /* change to 0 for accordion function, turn on cursor ^above */
    max-height: 100%;
    transition: max-height .5s ease-in-out;

    &.open {
      max-height: 100%;
    }

    .description-content {
      padding: 0 0rem 2rem 0rem;
      @media #{$tablet-up} {
        padding: 0 2rem 2rem 2rem;
      }
      
      .list {
        margin: 0;
        @extend p;

        li {
          margin-bottom: 1rem;
        }
      }
    }
  }
}

.accordion::after,
.downloads::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  background-image: url('./images/dots-talent-top-1000.png');
  background-position: center center;
  background-size: cover;
  height: 6rem;
  
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);

  @media #{$tablet-up} {
    height: 12rem;
    width: 65%;
    left: 0;
  }
}

/* 404 Page */
.page-not-found {
  .description {
    padding-bottom: 2rem
  }
}