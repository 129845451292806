/*---
title: Talent Education Section
section: Layout
---

*/

.talent-education-section {
  margin-top: 90px;

  .header-secondary-bg {
    background: no-repeat url(./images/gmu-students-campus-1000.jpg);
  }

  .row {
    margin-top: 70px;
    margin-bottom: 70px;
  }

  .quote {
    .quote-image {
      width: 290px;
      height: 365px;
    }
  }

  .image-title-callout {
    .title {
      font-size: 1.4rem;
    }
  }
}

@media #{$tablet-up} {
  .talent-education-section {
    .header-secondary-bg {
      background: no-repeat url(./images/gmu-students-campus-1400.jpg);
    }

    .row {
      display: flex;
    }

    .col {
      width: 50%;

      &:first-child {
        margin-right: 10%;
      }
    }

    .row:nth-child(even) {
      display: block;

      .col {
        width: 100%;
        margin-right: 0;
      }
    }

    .image-title-callout {
      .title {
        font-size: 1.4rem;
      }

      &:before {
        background: no-repeat url(./images/macbook-1000.png);
        background-size: cover;

        width: 469px;
        height: 272px;
      }
    }
  }
}