/*
 *Site Nav
 */

body.nav-active {
  overflow: hidden;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;

  @media #{$tablet-up} {
    overflow: scroll;
    position: relative;
  }
}

.site-nav {
  background-color: #fff;
  z-index: 100;

  ul {
    padding: 0;
  }

  li {
    display: inline-block;
  }

  a {
    display: block;
    position: relative;
    font-family: $font__heading;
    color: $copy__turquoise;
    padding: 1rem 1.25rem;
    font-size: $h4;

    @media #{$phone-only} {
      padding: .75rem 1.25rem;
    }

    @media #{$phablet-only} {
      padding: .75rem 1.25rem;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0.5rem;
      left: 1.25rem;
      height: 0.5rem;
      width: 80%;
      background-color: $copy__turquoise;
      opacity: 0.5;
      transform-origin: left center;
      transform: translateX(-20px) scaleX(0);
      transition: all 250ms ease;
    }

    &:hover:after,
    &.active:after {
      @media #{$tablet-up} {
        transform: translateX(0) scaleX(1);
      }
    }
  }

  .download{
    color: $white;
    background: $turquoise;
    padding: .6rem 1.5rem;

    position: absolute;
    width: 220px;
    right: 0;
    top: 73px;
  }

  .download:after{
    background-color: transparent;
  }
}

@media #{$phablet-up} {
  .site-nav a {
    font-size: $h4 * 1.2;
  }
}

@media #{$up-to-tablet} {
  .site-nav {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    transition: all 300ms ease;

    ul {
      display: flex;
      flex-direction: column;
      list-style-type: none;
      margin: auto;
      width: 90%;
      max-width: 300px;
    }

    a {
      margin: 0;
    }
  }
}

@media #{$tablet-up} {
  .site-nav {
    ul {
      margin: 0;
    }

    a {
      font-size: $p;
    }
  }
}

@media #{$tablet-only} {
  .site-nav a {
    font-size: $h4 * .5;
  }
}
