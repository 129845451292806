p {
  font-family: $font__body;
  font-size: $p;
  margin-bottom: 1.5em;
  max-width: 100%;
}

small {
  font-size: $small;
}

dfn,
cite,
em,
i {
  font-style: italic;
}

blockquote {
  margin: 0 1.5em;
}

address {
  margin: 0 0 1.5em;
}

big {
  font-size: 125%;
}

strong {
  font-weight: 600;
}
