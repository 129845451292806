.container {
  width: 90%;
  /* max-width: 1400px; */
  margin: 0 auto;

  &--wide {
    width: 95%;
    max-width: 1350px;
    margin: 0 auto;
  }
}

/*
 * .site-header,
 * .hero,
 */
.page-section {
  max-width: 1440px;
  margin: 0 auto;
}
