/*---
title: Description
section: Typography
---

Used for paragraphs of text

```example:html

```

*/
.description {
  font-family: $font__body;
  font-size: 1.2rem;
  font-weight: 200;
  line-height: 1.8;
  margin: 1rem 0 2rem;
}
