/*---
title: Image Figure
section: Modules
---

Figure image with figcaption text explaining image.

```example:html
<figure class="image-figure">
  <img src="assets/images/oldtown-1000.png" alt="">
  <figcaption>Old Town Alexandria</figcaption>
</figure>
```

*/
.image-figure {
  position: relative;
  display: none;
  margin: 0;

  img {
    display: inherit;
  }

  figcaption {
    position: absolute;
    top: 0;
    right: 0;
    background-color: $white;
    padding: 0.25rem 2rem;
    font-size: $small;
    text-transform: uppercase;
    letter-spacing: 4px;
    color: $purple;
  }
}

@media #{$tablet-up} {
  .image-figure {
    display: block;
  }
}
